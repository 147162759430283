import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    assetsDataSummary:null,
    cphEmbeddedUrl:""

};

 export const AssetsReducer = createSlice({
  name: "Assets",
  initialState,
  reducers: {
    setAssetDataSummary: (state, action) => {
      console.log("Assets Data");
      console.log(action);
      state.assetsDataSummary = action.payload
    },
      setCPhEmbeddedUrl: (state, action) => {
          console.log("Assets Data");
          console.log(action);
          state.cphEmbeddedUrl = action.payload
      },
  },
});

export const {
    setAssetDataSummary,
    setCPhEmbeddedUrl
} = AssetsReducer.actions;

export default AssetsReducer.reducer;
