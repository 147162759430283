import React, { Children, useEffect, useState } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import CustomPaper from "./Common/CustomPaper";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#58595B",
    boxShadow:
      "0px 0px 4px 0px rgba(0, 7, 22, 0.14), 0px 1px 14px 0px rgba(65, 77, 92, 0.20)",
    fontSize: 11,
  },
}));

export default function AssetCard({
  //avatarColor,
  cardType,
  kpiPercentage,
  orderNumberText,
  orderText,...rest
}) {


  return (
    <CustomPaper height="auto" className="border">
      <Box
        sx={{
        //   display: "flex",
        padding: "20px",
          justifyContent:"center", 
          minHeight:"107px", 
      //    border:"1px solid gray"        
        //   height:"100%",
        //   flexDirection:"column"
        }}
      >
       {rest?.children}
      </Box>
    </CustomPaper>
  );
}
