import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, CircularProgress, ListItem } from "@mui/material";
import LiftDataTable from "../components/DataTable/LiftDataTable";
import LiftCard from "../components/LiftCard";
import { trolley, production } from "../assets";
import { getLiftsSummary, getCustomerAndLocations, getLiftsList } from "../redux/Thunk/RoutesThunk";
import TopHeader from "../components/TopHeader";

function LiftDashboard({ customerAndLocationsDataLoad, getCustomerAndLocations, customerAndLocationsData, getLiftSummary, selectedCustomerId, selectedLocationId, loadingLiftSummary, liftsSummaryData, getLiftList, liftListDataLoad, liftListData }) {
  const [filteredLiftList, setFilteredLiftList] = useState([]);

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [routeData, setRouteData] = useState([]);


  useEffect(() => {
    if (liftListData.length > 0) {
      let updatedItemList = liftListData.map((item) => {
        return { ...item, routeName: item.inDcGeofence ? item.dc : (!item.inDcGeofenceitem && item.routeName) ? item.routeName : "On Route" };
      });
      setFilteredLiftList(updatedItemList);
    } else {
      setFilteredLiftList(liftListData)
    }
  }, [liftListData]);

  return (
    <Box sx={{
      marginTop: "60px",
    }}>
      {customerAndLocationsDataLoad ?
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "40px",
            marginBottom: "10px",
          }}
        >
          <CircularProgress />
        </Box> :
        <Box>
          <TopHeader setSelectedCheckboxes = {setSelectedCheckboxes} routeData= {routeData} setRouteData={setRouteData}/>
          <Box
            sx={{
              marginTop: "30px",
            }}
          >
            <Grid container spacing={2}>
              <Grid container spacing={2} xs={12} lg={12} xl={12}>
                <Grid xs={12} lg={6} xl={6}>
                  <LiftCard>
                    <Box sx={{ mb: 1 }}>
                      <Box className="d-flex justify-content-between align-items-center kpi-title w-100" >
                        <Box className="k-title text-secondary">Lift Status</Box>
                        <Box className="k-icon"><img src={trolley} alt="truck" /></Box>
                      </Box>
                    </Box>
                    {loadingLiftSummary ?
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "20px",
                          marginBottom: "10px",
                        }}
                      >
                        <CircularProgress />
                      </Box> :
                      <Box>
                        <Grid container spacing={1}>
                          <Grid xs={3} lg={3} xl={3}>
                            <ListItem className="grid-title text-primary">{liftsSummaryData?.liftsInService}</ListItem>
                            <ListItem className="grid-title-sub">Lifts in Service</ListItem>
                          </Grid>
                          <Grid xs={4.5} lg={4.5} xl={4.5}>
                            <ListItem className="grid-title text-primary">{liftsSummaryData?.maintenanceOverDue}</ListItem>
                            <ListItem className="grid-title-sub">Lifts Overdue Maintenance </ListItem>
                          </Grid>
                          <Grid xs={4.5} lg={4.5} xl={4.5}>
                            <ListItem className="grid-title text-primary">{liftsSummaryData?.maintenanceDues}</ListItem>
                            <ListItem className="grid-title-sub">Lifts Due For Maintenance</ListItem>
                          </Grid>
                        </Grid>
                      </Box>
                    }
                  </LiftCard>
                </Grid>
                <Grid xs={12} lg={6} xl={6}>
                  <LiftCard >
                    <Box sx={{ mb: 1 }}>
                      <Box className="d-flex justify-content-between align-items-center kpi-title w-100" >
                        <Box className="k-title text-secondary">Inventory</Box>
                        <Box className="k-icon"><img src={production} alt="production" /></Box>
                      </Box>
                    </Box>
                    {loadingLiftSummary ?
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "20px",
                          marginBottom: "10px",
                        }}
                      >
                        <CircularProgress />
                      </Box> :
                      <Box>
                        <Grid container spacing={1}>
                          <Grid xs={4} lg={4} xl={4}>
                            <ListItem className="grid-title text-primary">{liftsSummaryData?.liftsAtDC}</ListItem>
                            <ListItem className="grid-title-sub">Lifts in DC</ListItem>
                          </Grid>
                          <Grid xs={4} lg={4} xl={4}>
                            <ListItem className="grid-title text-primary">{liftsSummaryData?.liftsOutOnDelivery}</ListItem>
                            <ListItem className="grid-title-sub">Lifts Out On Delivery</ListItem>
                          </Grid>
                        </Grid>
                      </Box>
                    }
                  </LiftCard>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              marginTop: "30px",
            }}
          >
            <Grid container>
              <Grid xs={12} lg={12} xl={12}>
                <LiftDataTable liftList={filteredLiftList} liftDataLoad={liftListDataLoad} selectedCustomerId={selectedCustomerId} selectedLocationId={selectedLocationId} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      }
    </Box>
  );
}

const mapStateToProps = (state) => {
  return {
    selectedCustomerId: state?.Routes?.selectedCustomer,
    selectedLocationId: state?.Routes?.selectedLocation,
    customerAndLocationsData: state?.Routes?.customerAndLocationsData,
    loadingLiftSummary: state?.Routes?.liftSummaryDataLoad,
    liftsSummaryData: state?.Routes?.liftSummaryData,
    liftListDataLoad: state?.Routes?.liftListDataLoad,
    liftListData: state?.Routes?.liftListData,
    customerAndLocationsDataLoad: state?.Routes?.customerAndLocationsDataLoad
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerAndLocations: () => dispatch(getCustomerAndLocations()),
    getLiftSummary: (customerLocationdata) => dispatch(getLiftsSummary(customerLocationdata)),
    getLiftList: (customerLocationdata) => dispatch(getLiftsList(customerLocationdata)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LiftDashboard);