import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  FormControlLabel,
  Button,
  Grid,
  Link,
  Radio,
  InputAdornment,
  IconButton,
  Alert,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Auth } from "aws-amplify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { AppLogo, RehrigLogo } from "../../assets";
import { validatePasswordRequirement } from "./auth.config";

export default function SignIn() {
  const [signInError, setSignInError] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [newPasswordRequired, setNewPasswordRequired] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [newPwd, setNewPwd] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [pwChar, setPwChar] = useState("");
  const [pwCap, setPwCap] = useState("");
  const [pwNum, setPwNum] = useState("");
  const [pwsc, setPwsc] = useState("");
  const [pwll, setPwll] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [signingIn, setSigningIn] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    if (pwChar && pwCap && pwNum && email && pwsc && pwll) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [pwChar, pwCap, pwNum, email, pwsc, pwll]);

  const handleOnBlur = (e) => {
    if (!isValidEmail(e.target.value)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };
  function handleFormInputChange(value, setState, id) {
    setState(value);
    setSignInError("");

    if (id === "pwd")
      validatePasswordRequirement(
        value,
        setPwChar,
        setPwNum,
        setPwCap,
        setPwsc,
        setPwll
      );
  }

  function isValidEmail(email) {
    return email.trim().length !== 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setSigningIn(true);
    const data = new FormData(event.currentTarget);
    try {
      const user = await Auth.signIn(data.get("email"), data.get("password"));
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        setNewPasswordRequired(true);
        return;
      }
    } catch (error) {
      setSignInError(error.message);
    } finally {
      setSigningIn(false);
    }
  }

  async function signInWithNewPassword(event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      setSigningIn(true);
      const user = await Auth.signIn(data.get("email"), password);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        await Auth.completeNewPassword(
          user,
          newPwd
        );
      }
    } catch (error) {
      setSignInError(error.message);
    } finally {
      setSigningIn(false);
    }
  }

  return (
    <Box
      sx={{
        marginY: 5,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        component="form"
        onSubmit={newPasswordRequired ? signInWithNewPassword : handleSubmit}
        noValidate
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "90%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          <img src={AppLogo} alt="logo" width="200" />
        </Box>
        {signInError && (
          <Alert variant="filled" severity="error">
            {signInError}
          </Alert>
        )}
        <TextField
          onBlur={handleOnBlur}
          margin="normal"
          required
          fullWidth
          id="email"
          label="Username"
          name="email"
          autoComplete="email"
          autoFocus
          onChange={(event) => {
            handleFormInputChange(event.target.value, setEmail, "email");
          }}
          error={emailError}
          helperText={
            emailError ? "Please enter your username" : " "
          }
        />
        {newPasswordRequired ? (
          <>
            <TextField
              value={newPwd}
              margin="normal"
              required
              fullWidth
              name="newpassword"
              label="Enter your new password"
              type={showPassword ? "text" : "password"}
              id="newpwd"
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(event) => {
                handleFormInputChange(
                  event.target.value,
                  setNewPwd,
                  "pwd"
                );
              }}
            />
            <FormControlLabel
              control={<Radio checked={pwChar !== ""} />}
              label="At least 12 characters"
            />
            <FormControlLabel
              control={<Radio checked={pwCap !== ""} />}
              label="At least 1 capital letter"
            />
            <FormControlLabel
              control={<Radio checked={pwNum !== ""} />}
              label="At least 1 number"
            />
            <FormControlLabel
              control={<Radio checked={pwsc !== ""} />}
              label="At least 1 special character"
            />
            <FormControlLabel
              control={<Radio checked={pwll !== ""} />}
              label="At least 1 lowercase letter"
            />
          </>
        ) : (
          <>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="pwd"
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(event) => {
                handleFormInputChange(
                  event.target.value,
                  setPassword,
                  "password"
                );
              }}
            />
          </>
        )}
        {signingIn ? (
          <LoadingButton loading variant="contained">
            Submit
          </LoadingButton>
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, fontSize: "15px" }}
            disabled={
              newPasswordRequired ? buttonDisabled : !email || !password
            }
          >
            Sign In
          </Button>
        )}

        <Grid
          container
          sx={{
            marginBottom: "50px",
          }}
        >
          <Grid item xs>
          </Grid>
          <Grid item>
            <Link
              href="#"
              variant="body2"
              onClick={() =>
                Auth.federatedSignIn({ provider: "rpcidentityprovider" })
              }
            >
              {"Rehrig employee? Click here"}
            </Link>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={RehrigLogo} alt="rehrig-logo" />
        </Box>
      </Box>
    </Box>
  );
}