import React, { useEffect, useState } from "react";
import { Button, Typography, Box, Checkbox, Grid, CircularProgress, Stack } from "@mui/material";
import CustomPaper from "../Common/CustomPaper";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useNavigate } from "react-router-dom";
import { checkboxSelectedIcon, noRoutesIcon, smallBellIcon, emptyCheckbox } from "../../assets";
import { Constants } from "../utils/Constants";
import moment from 'moment';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { DataGrid } from '@mui/x-data-grid';
import TimelineComponent from "./TimelineComponent";
import { connect } from "react-redux";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import { getAlertsForRoute } from "../../redux/Thunk/RoutesThunk";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { styled } from '@mui/system';

const CustomDateHeader = styled("CustomDateHeader")({
    '.rct-calendar-header': {
        width: '5220px !important'
    },
});

const CustomDateHeaderHour = styled("CustomDateHeaderHour")({
    '.rct-calendar-header': {
        width: '2610px !important'
    },
});

function RouteDataTable({ routesList, loadingCustomersDataFromProps, getAlertsForRoute, routesAlertsDataLoad, routesAlertsData, selectedRoutesDate, routeStartTime, 
    routeDCTime, selectedCheckboxes, setSelectedCheckboxes, routeData, setRouteData }) {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    //const [routeData, setRouteData] = useState([]);
    const [active, setActive] = useState(60);
    const [isAssending, setIsAssending] = useState(true);
    const [sortedRoutesList, setSortedRoutesList] = useState([]);

    useEffect(() => {
        setSortedRoutesList(routesList)
        setIsAssending(true)
    }, [routesList])

    useEffect(() => {
        if(typeof(routeData)!=="undefined" && routeData.length > 0 )
        for(let i=0; i<routeData.length; i++){
            console.log("routeData: " + routeData[i].routeName);
        }
    }, [routeData]);

    const CheckHandler = (routeId, routeName, routeStatus, event) => {
        const checkboxValue = event.target.value;
        if (event.target.checked) {
            if (selectedCheckboxes.length < 5) {
                /*if(checkboxStatus === "true"){
                    setSelectedCheckboxes([ checkboxValue]);
                    setRouteData([]);
                    setCheckboxStatus("false");
                }
                else{*/
                    setSelectedCheckboxes([...selectedCheckboxes, checkboxValue]);
                /*}*/
            }
        } else {
            setSelectedCheckboxes(selectedCheckboxes.filter((item) => item !== checkboxValue));
        }
        setRouteData((prev) =>
            routeData.some(item => item.routeId === routeId)
                ? prev.filter((cur) => cur.routeId !== routeId)
                : [...prev, { routeId: routeId, routeName: routeName, routeStatus: routeStatus }]
        );
    };

    const handleCancel = () => {
        setRouteData([]);
        setSelectedCheckboxes([]);
    }

    const handleView = () => {
        const event = new CustomEvent(Constants.ROUTE_SELECTION_EVENT, { detail: {} });
        document.dispatchEvent(event);
        navigate('/routedetails', { state: { routeData: routeData } });
    }

    const routeDetailsPage = (routeId, routeName) => {
        const event = new CustomEvent(Constants.ROUTE_SELECTION_EVENT, { detail: {} });
        document.dispatchEvent(event);
        navigate('/routedetails', { state: { routeData: [{ routeId: routeId, routeName: routeName }] } })
    }

    const handleAlertClick = (item) => {
        getAlertsForRoute(item);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const columns = [
        {
            field: 'time',
            headerName: 'Date & Time',
            flex: 1.2,
            renderCell: (params) => {
                const dateobj = moment(params.row.time).format("MMM DD, YYYY hh:mm a");
                return <>{dateobj}</>;
            },
        },
        {
            field: 'liftId',
            headerName: 'Lift ID',
            flex: 1,
        },
        {
            field: 'description',
            headerName: 'Alert Description',
            flex: 1,
        },
        {
            field: 'address',
            headerName: ' Alert Address',
            flex: 1,
        },
        {
            field: 'driverName',
            headerName: 'Driver Name',
            flex: 1,
        },
    ];

    const handleAssending = () => {
        setIsAssending(false);
        if (routesList.length > 0) {
            const desendinData = routesList.slice().sort((a, b) => b.routeName.localeCompare(a.routeName))
            setSortedRoutesList(desendinData)
        }
    }

    const handleDesending = () => {
        setIsAssending(true);
        if (routesList.length > 0) {
            const assendingData = routesList.slice().sort((a, b) => a.routeName.localeCompare(b.routeName))
            setSortedRoutesList(assendingData)
        }
    }

    return (
        <CustomPaper height="100%" padding="20px">
            {open &&
                <React.Fragment>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        sx={{
                            "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                    width: "100%",
                                    maxWidth: "800px",  // Set your width here
                                },
                            },
                        }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title" sx={{ fontSize: "24px" }}>
                            Alerts
                            <IconButton style={{ float: "right" }}>
                                <CloseIcon onClick={() => handleClose()} />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Box sx={{ width: "100%" }}>
                                    <DataGrid autoHeight
                                        sx={{
                                            display: "grid", borderColor: "#FFF !important",
                                            '& .MuiDataGrid-sortIcon': {
                                                opacity: 0,
                                            },
                                        }}
                                        rows={routesAlertsDataLoad ? [] : routesAlertsData}
                                        getRowId={(row) => row.seqNumber}
                                        columns={columns}
                                        loading={routesAlertsDataLoad}
                                        pageSizeOptions={[5, 10, 25, 50, 100]}
                                        initialState={{
                                            pagination: { paginationModel: { pageSize: 25 } },
                                        }}
                                        disableRowSelectionOnClick
                                        disableColumnMenu
                                    />
                                </Box>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                </React.Fragment>
            }
            <Grid container style={{ marginBottom: "30px" }}>
                <Grid item xs={2}>
                    <Typography variant="h3" sx={{ padding: "0rem 1rem 1rem 0rem" }}>Route Status</Typography>
                </Grid>
                <Grid item xs={3}>
                    {routeData.length > 0 &&
                        <Box style={{ display: "flex", justifyContent: 'center' }}>
                            <Button variant="outlined" style={{ textTransform: 'none', width: "100px", marginRight: "50px" }} onClick={handleView}>
                                View
                            </Button>
                            <Button style={{ textTransform: 'none', color: 'gray' }} onClick={handleCancel}>
                                Cancel
                            </Button>
                        </Box>
                    }
                </Grid>
                <Grid item xs={5}>
                {sortedRoutesList.length > 0 &&
                    <Stack direction="row" spacing={2} style={{marginTop:"10px", display: "flex", justifyContent: 'end'}}>
                        <div style={{fontSize:"14px"}}><div class='box blue'></div>Route Start</div>
                        <div style={{fontSize:"14px"}}><div class='box green'></div>On Time</div>
                        <div style={{fontSize:"14px"}}><div class='box gold'></div>Ahead</div>
                        <div style={{fontSize:"14px"}}><div class='box red'></div>Delayed</div>
                        <div style={{fontSize:"14px"}}><div class='box gray'></div>In Progress</div>
                    </Stack>
                }
                </Grid>
                <Grid item xs={2}>
                    {sortedRoutesList.length > 0 &&
                        <Box style={{ display: "flex", justifyContent: 'end' }}>
                            <Box>
                                {/* <a className="me-4 suff"><ImportExportIcon /></a> */}
                                <ToggleButtonGroup
                                    color="primary"
                                    value={'web'}
                                    exclusive
                                    aria-label="Platform"
                                >
                                    <ToggleButton value="web" style={{ textTransform: "lowercase" }} className={`switch-btn ${active === 30 ? "active" : ""}`} onClick={() => {
                                        setActive(30)
                                    }}>30mins</ToggleButton>
                                    <ToggleButton value="android" style={{ textTransform: "lowercase" }} className={`switch-btn ${active === 60 ? "active" : ""}`} onClick={() => {
                                        setActive(60)
                                    }}>60mins</ToggleButton>
                                </ToggleButtonGroup>
                            </Box>
                        </Box>
                    }
                </Grid>
            </Grid>
            {loadingCustomersDataFromProps ? (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "40px",
                        marginBottom: "10px",
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : (
                sortedRoutesList.length > 0 ?
                    <Grid container>
                        <Grid item xs={1} lg={1} xl={1} md={1}>
                            <Box className="route-name" style={{ whiteSpace: "nowrap" }}>Route Name
                                {isAssending ? <ArrowDownwardIcon style={{ height: "15px", cursor: "pointer" }} onClick={() => handleAssending()} /> : <ArrowUpwardIcon style={{ height: "15px", cursor: "pointer" }} onClick={() => handleDesending()} />}</Box>
                            <Box>
                                {sortedRoutesList.length > 0 && sortedRoutesList?.map((item, index) => (
                                    <Box className="main-route-row" key={index}>
                                        <Box className="main-route-row-name" style={{ marginRight: "55px" }}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox
                                                    onClick={(event) => CheckHandler(item.routeId, item.routeName, item.routeStatus, event)}
                                                    value={item.routeName}
                                                    checked={selectedCheckboxes.includes(item.routeName)}
                                                    disabled={selectedCheckboxes.length >= 5 && !selectedCheckboxes.includes(item.routeName)}
                                                    icon={<img src={emptyCheckbox} alt="emptyCheckbox" />}
                                                    checkedIcon={<img src={checkboxSelectedIcon} alt="checkboxSelectedIcon" />} />}
                                                    label={(
                                                        <label>
                                                            <span onClick={() => routeDetailsPage(item.routeId, item.routeName)} style={{ cursor: "pointer" }}>{item.routeName}</span>
                                                        </label>
                                                    )}
                                                    sx={{ fontSize: "12px", textDecoration: "underline", color: "#0C4480", size: "small" }} />
                                                <a href="#/" onClick={() => routeDetailsPage(item.routeId, item.routeName)}> </a>
                                            </FormGroup>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        </Grid>
                        <Grid item xs={9} lg={9} xl={9} md={9}>
                            <Box style={{ marginTop: "-5px" }}>
                                {active === 30 ?
                                    <CustomDateHeader>
                                        <TimelineComponent timeInterval={active} routesList={sortedRoutesList} selectedRoutesDate={selectedRoutesDate} routeStartTime={routeStartTime} routeDCTime={routeDCTime} />
                                    </CustomDateHeader>
                                    :
                                    <CustomDateHeaderHour>
                                        <TimelineComponent timeInterval={active} routesList={sortedRoutesList} selectedRoutesDate={selectedRoutesDate} routeStartTime={routeStartTime} routeDCTime={routeDCTime} />
                                    </CustomDateHeaderHour>
                                }
                            </Box>
                        </Grid>
                        <Grid item xs={2} lg={2} xl={2} md={2}>
                            <Box style={{ color: "white" }}>
                                Total CPH
                            </Box>
                            <Box>
                                {sortedRoutesList.length > 0 && sortedRoutesList?.map((item, index) => (
                                    <Box key={index}>
                                        <Stack direction="row" spacing={4}>
                                            <Box style={{ fontSize: "10px", fontWeight: "700", whiteSpace: "nowrap", padding: "15.5px 0px 30px 0px", marginLeft: "60px", width: "70px" }}>
                                                Total CPH
                                                <span> &nbsp; {item.cph}</span>
                                            </Box>
                                            <Box style={{ padding: "10px 0px" }}>
                                                <img src={smallBellIcon} alt="smallBellIcon" onClick={() => handleAlertClick(item.routeId)} />
                                            </Box>
                                        </Stack>
                                    </Box>
                                ))}
                            </Box>
                        </Grid>
                    </Grid>
                    :
                    <Box>
                        <Grid container>
                            <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                                <Typography>
                                    No Routes to Show
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                                <img src={noRoutesIcon} alt="noRoutesIcon" />
                            </Grid>
                        </Grid>
                    </Box>
            )}
        </CustomPaper>
    );
}

const mapStateToProps = (state) => {
    return {
        routesAlertsDataLoad: state?.Routes?.routesAlertsDataLoad,
        routesAlertsData: state?.Routes?.routesAlertsData
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAlertsForRoute: (routeId) => dispatch(getAlertsForRoute(routeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RouteDataTable)