import React, {useEffect, useState} from "react";
import {
  DataGrid,
  GridToolbar,
  getGridStringOperators,
} from "@mui/x-data-grid";
import {Button, Typography, Box, CircularProgress, Stack} from "@mui/material";

import CustomPaper from "../Common/CustomPaper";

export default function CircleProgressBar() {


  return (
      <div>
          <Stack direction="row" alignItems="center" justifyContent="center"
                 sx={{ px: 0, py: 1, bgcolor: 'background.default' }} >

             <CircularProgress />

          </Stack>
      </div>
  );
}
