export function validatePasswordRequirement(pw, setPwChar, setPwNum, setPwCap, setPwsc, setPwll) {
  const has1NumberRegex = /\d/;
  const hasCapRegex = /[A-Z]/;
  const hasSpecialCRegex = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
  const haslowerRegex = /[a-z]/;

  // If pw length >= 8
  if (pw.length >= 12) {
    setPwChar('pwChar');
  } else {
    setPwChar('');
  }

  // If pw has min 1 number
  if (has1NumberRegex.test(pw)) {
    setPwNum('pwNum');
  } else {
    setPwNum('');
  }

  // If pw has min 1 uppercase
  if (hasCapRegex.test(pw)) {
    setPwCap('pwCap');
  } else {
    setPwCap('');
  }

  // If pw has min 1 special character
  if (hasSpecialCRegex.test(pw)) {
    setPwsc('pwsc');
  } else {
    setPwsc('');
  }

  // If pw has min 1 lowercase
  if (haslowerRegex.test(pw)) {
    setPwll('pwll');
  } else {
    setPwll('');
  }
}