import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Grid from "@mui/material/Unstable_Grid2";
import FormControl from "@mui/material/FormControl";
import { Box, Button, CircularProgress, Input, InputAdornment, InputLabel, FormHelperText } from "@mui/material";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CommonCard from "../components/CommonCard";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { getMaintenanceType, getReplaceableItems, postMaintenanceData, updateAddMaintenanceStatus } from "../redux/Thunk/RoutesThunk";
import { InfoIcon } from "../assets/index";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate, useLocation } from "react-router-dom";
import dayjs from "dayjs";

function AddMaintenance({ getMaintenanceType, getReplaceableItems, loadingLiftTasksList, liftTasksList, loadingLiftPartsList, liftPartsList, postMaintenanceData, addMaintenanceStatus, updateStatus }) {
  const location = useLocation();
  const navigate = useNavigate();
  const liftId = location?.state?.liftId;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [maintenanceData, setMaintenanceData] = useState([]);
  const [replaceableData, setReplaceableData] = useState([]);
  const [maintenanceDoneBy, setMaintenanceDoneBy] = useState("");
  const [maintenanceDoneOn, setMaintenanceDoneOn] = useState("");
  const [searchTerm, setSearchTerm] = useState('');
  const [errorDoneOn, setErrorDoneOn] = useState('');
  const [errorDoneBy, setErrorDoneBy] = useState('');
  const [errorTasksAndParts, setErrorTasksAndParts] = useState('');
  const [selectAll, setSelectAll] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getMaintenanceType()
    getReplaceableItems()
  }, [])

  const CheckMaintenanceHandler = (id) => {
    setErrorTasksAndParts("");
    const isSelected = maintenanceData.some((selectedItem) => selectedItem === id);
    if (isSelected) {
      setMaintenanceData(maintenanceData.filter((selectedItem) => selectedItem !== id));
    } else {
      setMaintenanceData((prev) =>
        maintenanceData.includes(id)
          ? prev.filter((cur) => cur.id !== id)
          : [...prev, id]
      );
    }
    setSelectAll(maintenanceData.length + 1 === liftTasksList.length);
  };

  const CheckReplaceableHandler = (id, selectedTasks, event) => {
    let isChecked = event.target.checked
    setErrorTasksAndParts("");
    if (isChecked) {
      selectedTasks.map(item => {
        setMaintenanceData((prev) =>
          maintenanceData.includes(item)
            ? prev.filter((cur) => cur.id !== item)
            : [...prev, item]
        );
      })
      setReplaceableData((prev) =>
        replaceableData.includes(id)
          ? prev.filter((cur) => cur.id !== id)
          : [...prev, id]
      );
    } else {
      setMaintenanceData(maintenanceData.filter((cur) => !selectedTasks.includes(cur)))
      setReplaceableData((prev) =>
        replaceableData.includes(id)
          ? prev.filter((cur) => cur !== id)
          : [...prev, id]
      );
    }
  };

  const handleMaintenance = (value) => {
    setErrorDoneBy("");
    setMaintenanceDoneBy(value)
  }

  const handleMaintenanceDate = (newValue) => {
    setErrorDoneOn("");
    setMaintenanceDoneOn(newValue.format("YYYY-MM-DD HH:mm:ss"))
  }

  const filteredItems = liftPartsList && liftPartsList.filter(item =>
    item.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const submitMaintenance = async () => {
    const data = {
      "date": maintenanceDoneOn,
      "tasks": maintenanceData,
      "parts": replaceableData,
      "doneBy": maintenanceDoneBy,
      "liftId": liftId
    }
    if (maintenanceDoneOn && maintenanceDoneBy && (maintenanceData.length > 0 || replaceableData.length > 0)) {
      let successData = await postMaintenanceData(data);
      navigate('/liftMaintenance', { state: { liftId: liftId } })
    } else {
      if (!maintenanceDoneOn) {
        setErrorDoneOn("Please Select the Date")
      }
      if (!maintenanceDoneBy) {
        setErrorDoneBy("Please Enter the Name")
      }
      if (maintenanceData.length === 0 || replaceableData.length === 0) {
        setErrorTasksAndParts("Please Select Tasks or Parts")
      }
    }
  }

  const cancelMaintenance = () => {
    navigate('/liftMaintenance', { state: { liftId: liftId } })
  }

  const clearReplacementItems = () =>{
    setReplaceableData([]);
  }

  const handleSelectAll = () => {
    if (!selectAll) {
      const list = liftTasksList && liftTasksList.map((item) => item.id)
      setMaintenanceData(list)
    } else {
      setMaintenanceData([]);
    }
    setSelectAll(!selectAll);
  };

  const isItemChecked = (item) => {
    return maintenanceData.some((selectedItem) => selectedItem === item.id);
  };

  const isReplaceableItemChecked = (item) => {
    return replaceableData.some((selectedItem) => selectedItem === item.id);
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <div className="col-8">
      <Box sx={{ marginTop: "50px" }} >
        <Box>
          <Grid xs={12} lg={12} xl={12}>
            <CommonCard>
              <Box className="mb-4">
                <Grid container spacing={2}>
                  <Grid xs={3} lg={3} xl={3}>
                    <FormControl size="medium" variant="standard" fullWidth>
                      <DateTimePicker
                        defaultValue={""}
                        views={['year', 'month', 'day', 'hours', 'minutes']}
                        label="Maintenance Done On"
                        format="MMMM D, YYYY hh:mm A"
                        onChange={handleMaintenanceDate}
                        maxDate={dayjs(new Date())}
                        slotProps={{ textField: { size: "small", error: false } }}
                        sx={{
                          marginTop: "9px",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                              borderBottom: "1px solid",
                              borderRadius: "0px",
                            },
                          }
                        }}
                      />
                      <FormHelperText sx={{ color: "red" }}>{errorDoneOn}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid xs={3} lg={3} xl={3}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel id="select-label">Maintenance Done By</InputLabel>
                      <Input
                        id="standard-adornment-amount"
                        value={maintenanceDoneBy}
                        sx={{ marginTop: "9px" }}
                        onChange={(e) => {
                          handleMaintenance(e.target.value);
                        }}
                      />
                      <FormHelperText sx={{ color: "red" }}>{errorDoneBy}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Box className="d-flex justify-content-between align-items-center kpi-title w-100" >
                  <Box className="k-title text-secondary">Select Maintenance Type
                    <span style={{ marginLeft: "20px", color: "red", fontWeight: 400, fontSize: "12px" }}>
                      {errorTasksAndParts}
                    </span>
                  </Box>
                  <Box className="fs-14" style={{ cursor: "pointer", color: "#000000" }} onClick={handleSelectAll}>{selectAll ? 'Unselect All' : 'Select All'}</Box>
                </Box>
                {loadingLiftTasksList ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "40px",
                      marginBottom: "10px",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) :
                  <Box className="maintenace-type" sx={{ pt: 3, pb: 2 }}>
                    {liftTasksList && liftTasksList.map((item, index) => (
                      <FormGroup>
                        <Box className="d-flex align-items-center" key={index}>
                          <FormControlLabel control={<Checkbox
                            checked={isItemChecked(item)}
                            value={item.description}
                            onClick={() => CheckMaintenanceHandler(item.id)}
                          />}
                            label={`${item.id} - ${item.description}`}
                          />
                          <img src={InfoIcon} aria-describedby={id} variant="contained" onClick={handleClick} />
                          <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                          >
                            <Typography sx={{ p: 2 }}>Maintenance due  - <b>Quarterly</b></Typography>
                          </Popover>
                        </Box>
                      </FormGroup>
                    ))}
                  </Box>
                }
                <hr />
                <Grid container className={"d-flex justify-content-between align-items-center kpi-title w-100 MuiBox-root css-0"}>
                  <Grid item xs={8}>
                    <Box className="k-title text-secondary">Replaceable Items</Box>
                  </Grid>
                  <Grid item xs={2} style={{ display: "flex", justifyContent: "end" }}>
                    <Box className="fs-14">
                      <Button variant="outlined" onClick={() => clearReplacementItems()} sx={{ marginRight: "10px", width: "140px", textTransform: "capitalize" }}>
                        Clear All
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box className="fs-14">
                      <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                        <Input
                          id="standard-adornment-amount"
                          placeholder="Search"
                          onChange={(e) => setSearchTerm(e.target.value)}
                          startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
                {/* <Box className="d-flex justify-content-between align-items-center kpi-title w-100" >
                  <Box className="k-title text-secondary">Replaceable Items</Box>
                  <Box className="fs-14">
                    <Button>Clear All</Button>
                  </Box>
                  <Box className="fs-14">
                    <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                      <Input
                        id="standard-adornment-amount"
                        placeholder="Search"
                        onChange={(e) => setSearchTerm(e.target.value)}
                        startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                      />
                    </FormControl>
                  </Box>
                </Box> */}
                {loadingLiftPartsList ? <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "40px",
                    marginBottom: "10px",
                  }}
                >
                  <CircularProgress />
                </Box> :
                  <Box sx={{ marginTop: "15px" }}>
                    <Grid container>
                      {filteredItems && filteredItems.map((item) => (
                        <Grid key={item.id} item xs={4}>
                          <FormGroup>
                            <FormControlLabel control={<Checkbox
                              checked={isReplaceableItemChecked(item)}
                              value={item.description}
                              onClick={(event) => CheckReplaceableHandler(item.id, item.tasks, event)}
                            />}
                              label={item.description}
                            />
                          </FormGroup>
                        </Grid>
                      ))}
                      {filteredItems.length === 0 && (
                        <>No Results Found!</>
                      )}
                    </Grid>
                    <hr />
                    <Box sx={{ textAlign: "right" }}>
                      <Button variant="outlined" onClick={() => cancelMaintenance()} sx={{ marginRight: "10px", width: "140px", textTransform: "capitalize" }}>
                        No
                      </Button>
                      <Button className="btn-primary" onClick={() => submitMaintenance()} variant="outlined" style={{ width: "140px", textTransform: "capitalize" }}>
                        Yes
                      </Button>
                    </Box>
                  </Box>
                }
              </Box>
            </CommonCard>
          </Grid>
        </Box>
      </Box>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    loadingLiftTasksList: state?.Routes?.loadingLiftTasksList,
    liftTasksList: state?.Routes?.liftTasksList,
    loadingLiftPartsList: state?.Routes?.loadingLiftPartsList,
    liftPartsList: state?.Routes?.liftPartsList,
    addMaintenanceStatus: state?.Routes?.addMaintenanceStatus
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMaintenanceType: () => dispatch(getMaintenanceType()),
    getReplaceableItems: () => dispatch(getReplaceableItems()),
    postMaintenanceData: (data) => dispatch(postMaintenanceData(data)),
    updateAddMaintenanceStatus: () => dispatch(updateAddMaintenanceStatus())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddMaintenance);