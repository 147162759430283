import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Auth } from "aws-amplify";
import Cookies from 'js-cookie';
import {setAssetDataSummary, setCPhEmbeddedUrl} from "../reducers/Assets/AssetsReducer";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const getToken = async () => {
  try {
    const authTokenValue = Cookies.get('authToken');
    if (authTokenValue) {
        return authTokenValue
    } else {
      const session = await Auth.currentSession();
      return session.accessToken.jwtToken;
    }
  } catch {
    return null;
  }
};

const restapi = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT, // our API base URL
});

const cphRestAPI = axios.create({
    baseURL: process.env.REACT_APP_QUICKDB_URL, // our API base URL
});
restapi.interceptors.request.use(
    async(config) => {
      // const token = localStorage.getItem('token'); // Assuming you store the token in localStorage
      // const session = await Auth.currentSession();
      // const loggedInUserIDToken = session.accessToken.jwtToken;
      // if (loggedInUserIDToken) {
        config.headers.Authorization = "Bearer "+await getToken();//`Bearer ${loggedInUserIDToken}`;
        config.headers['x-api-key'] = 'jPJoEqjzqC9NjMpWroPPH8G0vWQTV4Kcazb5LXMp';
        //config.headers.Authorization = `Bearer ${loggedInUserIDToken}`;
      // }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
);

cphRestAPI.interceptors.request.use(
    async(config) => {
        // const token = localStorage.getItem('token'); // Assuming you store the token in localStorage
        // const session = await Auth.currentSession();
        // const loggedInUserIDToken = session.accessToken.jwtToken;
        // if (loggedInUserIDToken) {
            config.headers.Authorization = await getToken();//`Bearer ${loggedInUserIDToken}`;
            //config.headers['x-api-key'] = 'jPJoEqjzqC9NjMpWroPPH8G0vWQTV4Kcazb5LXMp';
            //config.headers.Authorization = `Bearer ${loggedInUserIDToken}`;
       // }
        return config;
    },
    (error) => {
        console.log(error);
        return Promise.reject(error);
    }
);

//api call to fetch customers & locations
export const getAssetDataSummary = createAsyncThunk(
  "AssetsThunk/getAssetDataSummary",
  async (data, { dispatch }) => {

      console.log(data);
      console.log("Asset Trucnk");
    try {
       // await dispatch(setAssetDataSummary(null));
        let routeUrl = `v2/assets/summary?locationId=${data.locationId}&customerId=${data.customerId}`;
        let response = await restapi.get(routeUrl);
        if (response && response.data) {
            dispatch(setAssetDataSummary(response.data));
        }else{
           dispatch(setAssetDataSummary(null));
        }
        //dispatch(setLoadingLiftListData(false));
        //return api.get(routeUrl);
    } catch (error) {
      console.error("API error:", error);
        throw new Error(error.message);
    }
  }
);

export const getEmbeddedCphDBUrl = createAsyncThunk(
    "AssetsThunk/getEmbeddedCphDBUrl",
    async (data, { dispatch }) => {

        console.log(data);
        console.log("Embedded Url");
        try {
            let embeddedUrl = `qs/v1/embed-url?app_name=${data.appName}&id=${data.dbId}&type=${data.db}`;
            let response = await cphRestAPI.get(embeddedUrl)
            if (response && response.data && response.data.embedUrl && response.data.embedUrl.length>0) {
                console.log(response.data.embedUrl);
                let cphDBUrl = response.data.embedUrl[0]['embed-url'];
                if(cphDBUrl){
                    dispatch(setCPhEmbeddedUrl(cphDBUrl));
                }else{
                    dispatch(setCPhEmbeddedUrl(""));
                }

            }
            //dispatch(setLoadingLiftListData(false));
            //return api.get(routeUrl);
        } catch (error) {
            console.error("API error:", error);
            if(error.response && error.response.data && error.response.data.message){
                throw new Error(error.response.data.message);
            }else{
                throw new Error(error.message);
            }

        }
    }
);


//api call to fetch List of Routes





