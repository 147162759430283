import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Auth } from "aws-amplify";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // boxShadow: 24,
  outline: 'none',
  p: 4,
};

export default function ShowModal({ type }) {
  const [open, setOpen] = useState(true);
  const handleClose = async () => {
    setOpen(false)
    localStorage.clear();
    await Auth.signOut();
    window.location.replace('/');
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose} sx={{ border: 'none' }}>
        <Box sx={style}>
          {/* <Typography variant="h6" component="h2">
            Text in a modal
          </Typography> */}
          <Typography sx={{ mt: 2 }}>
            {type === "userWithNoAccess" ? "Your user doesn't have permission to this application. Please contact support." : "Your user is not associated with any location. Please contact support."}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}