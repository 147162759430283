import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    mode: "light",
    common: {
      black: "#000",
      white: "#fff",
    },
    primary: {
      main: "#2027A5",
      dark: "#111540",
      light: "#2776cc",
      selected: "#2dbbdd",
    },
    secondary: {
      main: "#ffbc00",
      dark: "#fe8903",
      light: "#ffeab2",
    },
    background: {
      default: "#fbfbfb",
    },
    text: {
      primary: "#58595b",
      secondary: "#939598",
      disabled: "#d1d3d4",
      hint: "#d1d3d4",
    },
    divider: "#d4dfe8",
  },
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    h1: {
      fontSize: "2rem",
      fontWeight: 800,
      lineHeight: 1.25,
    },
    h2: {
      fontSize: "1.5rem",
      fontWeight: "800",
      lineHeight: 1.25,
    },
    h3: {
      fontSize: "1.25rem",
      fontWeight: 800,
      lineHeight: 1.25,
    },
    h4: {
      fontSize: "1rem",
      fontWeight: 800,
      lineHeight: 1.25,
    },
    h5: {
      fontSize: "0.875rem",
      fontWeight: 800,
      lineHeight: 1.25,
    },
    h6: {
      fontSize: "0.75rem",
      fontWeight: 800,
      lineHeight: 1.25,
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.75,
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "1.75",
    },
    label: {
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: "1.25",
    },
    button: {
      fontSize: "1rem",
      fontWeight: 800,
      lineHeight: "1.75",
    },
  },
  shape: {
    borderRadius: 12,
  },
  shadow: {
    elevation_1:
      "0px 0px 4px 0px rgba(65, 77, 92, 0.20), 0px 1px 14px 0px rgba(0, 7, 22, 0.14)",
    elevation_2: "0px 4px 20px 0px rgba(0, 7, 22, 0.12)",
  },
});

export default theme;
