import React, { useEffect } from "react";
import Cookies from 'js-cookie';
import { useAuthenticator } from "@aws-amplify/ui-react";
import { connect } from "react-redux";
import AuthFlow from "./AuthFlow";
import {
  setAuthStatus,
} from "../../redux/reducers/authentication/AuthReducer";
import Dashboard from "../../pages/Dashboard";

function AppAuth({ children, setAuthStatusFromProps }) {
  const authTokenValue = Cookies.get('authToken');
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  useEffect(() => {
    setAuthStatusFromProps(authStatus);
  }, [authStatus]);

  if(authTokenValue)
    return <>{children}</>;
  else 
  if (authStatus === "authenticated") {
    return <>{children}</>;
  } else if (authStatus === "configuring") {
    return <></>;
  } else {
    return <AuthFlow />;
  }
}

const authStateToProps = (state) => {
  return {
    authState: state.auth.authStatus,
  };
};

const authStatusDispatchToProps = (dispatch) => {
  return {
    setAuthStatusFromProps: (authStatus) => dispatch(setAuthStatus(authStatus)),
  };
};

export default connect(authStateToProps, authStatusDispatchToProps)(AppAuth);