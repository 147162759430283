import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { Store } from "./redux/Store";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import config from "./aws-export";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AppAuth from "./components/authentication/AppAuth";
import theme from "./theme";

Amplify.configure(config);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={Store}>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CssBaseline />
        {/* <React.StrictMode> */}
          <Authenticator.Provider>
            <BrowserRouter>
              <AppAuth>
                <App />
              </AppAuth>
            </BrowserRouter>
          </Authenticator.Provider>
        {/* </React.StrictMode> */}
      </LocalizationProvider>
    </ThemeProvider>
  </Provider>
);

reportWebVitals();