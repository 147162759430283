import React, {useEffect, useState} from "react";
import {
    DataGrid,
    GridToolbar,
    getGridStringOperators,
} from "@mui/x-data-grid";
import {Button, Typography, Box} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {Constants} from "../utils/Constants";
//import {getCustomerAndLocations } from "../../redux/Thunk/RoutesThunk";
import CustomPaper from "../Common/CustomPaper";
import CustomCellRender from "./CustomCellRender";
import CustomCellNumRender from "./CustomCellNumRender";
import moment from "moment";

export default function DataTable({dataRows, loading, selectedCustomerListData, selectedLocationListData}) {
    const filterOperators = getGridStringOperators().filter(({value}) =>
        ["contains" /* add more over time */].includes(value)
    );
    // const [pageSize, setPageSize] = useState(10);
    const [filterModel, setFilterModel] = useState({
        items: [
            {
                field: "rating",
                operator: ">",
                value: "2.5",
            },
        ],
    });

    const NumberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const onClickLiftId = () => {
        console.log("CustID: " + Object.keys(selectedCustomerListData) + " " + Object.values(selectedCustomerListData) + " " + Object.keys(selectedLocationListData) + " " + Object.values(selectedLocationListData) );
        const event = new CustomEvent(Constants.LIFT_MAINTENANCE_EVENT, { detail: {} });
        document.dispatchEvent(event);
        navigate('/liftdashboard');
        
    }


    const columns = [

        {
            field: "stopNumber", headerName: "Stops", width: 120,   
            renderHeader: (params) => (
                <span className={"grid-column-header"} style={{width: "120px"}}>
                Stops<br/>(total {dataRows.length < 9 ? ("0" + dataRows.length) : dataRows.length})
            </span>

            ), renderCell: (params) => {
                return (
                    <>
                        <span style={{width: "120px",textAlign: "right", paddingRight:"33px"}}>{params.row.stopNumber}</span>
                    </>
                )
            }, filterOperators
        },
        {
            field: "storeAddCopy", headerName: "Store Name / Address", width: 300, renderHeader: (params) => (
                <span className={"grid-column-header"} style={{width: "300px"}}>
                Store Name / Address
            </span>

            ), renderCell: (params) => {
                return (
                    <div style={{width: "250px", overflowWrap: "break-word"}}>
                        <span className={"cellTextBold"}>{params.row.storeName}</span><br/>
                        <span>{params.row.storeAddress}</span>
                    </div>
                )
            }, filterOperators
        },
        {
            field: "liftId", headerName: "Lift ID", width: 100,renderHeader: (params) => (
                <span className={"ltPaddedDigits"} >
                    Lift ID
                </span>
            ),
            renderCell: (params) => {
                const liftId = params.row.liftId;
                if (liftId) {
                    return (<span className={"centered"} onClick={() => { onClickLiftId() }} style={{ cursor: "pointer", color: "#2027A5", textDecoration: "underline" }}>
                        <CustomCellRender  field={params.row.liftId}/>
                        </span>
                    );
                } else { 
                    return (<CustomCellRender field = " - " />);
                }
            }, 
            valueGetter: (params) => (params.row.liftId ? params.row.liftId: " - "),
            filterOperators},
        {
            field: "stopStartTime", headerName: "Arrival Time", width: "120", renderHeader: (params) => (
                <span className={"grid-column-header"} style={{width: "120px"}}>
                Arrival Time
            </span>

            ), renderCell: (params) => {
                return (
                    <>
                        <CustomCellRender field={params.row.stopStartTime?moment(params.row.stopStartTime).format('hh:mm A'): ""}/>
                    </>
                )
            }, valueFormatter: (params) => {
                const valueFormatted = (params.value?moment(params.value).format('hh:mm A'):"");
                return `${valueFormatted}`;
            },filterOperators
        },
        {
            field: "stopEndTime", headerName: "Departure Time", minWidth: 100, renderHeader: (params) => (
                <span className={"grid-column-header"} style={{width: "100px",textAlign:"center"}}>
                Departure <br/>Time
            </span>

            ), renderCell: (params) => {
                if(params.row.serviceTimeStatus=='In progress'){
                    return (
                        <><span className="centered"> - </span></>
                    )
                }
                return (
                    <>
                        <CustomCellRender field={params.row.stopEndTime?moment(params.row.stopEndTime).format('hh:mm A'):""}/>
                    </>
                )
            },    valueGetter: (params) => {
                if (params.row.serviceTimeStatus === 'In progress') {
                    return "-";
                }
                return params.row.stopEndTime ? moment(params.row.stopEndTime).format('hh:mm A') : "";
            },
            valueFormatter: (params) => {
                const valueFormatted = (params.value?moment(params.value).format('hh:mm A'):"");
                return `${valueFormatted}`;
            }, filterOperators
        },
        {
            field: "caseCount", headerName: "Cases",   width: 70, renderHeader: (params) => (
                <span className={"grid-column-header"} >
                Cases
            </span>

            ), renderCell: (params) => {
                return (
                    <>
                        <span class={"rtPaddedDigits-sm"}>{params.row.caseCount} </span>
                    </>
                )
            }, filterOperators
        },
        {
            field: "skuCount", headerName: "SKUs",  width: 70,  renderHeader: (params) => (
                <span className={"grid-column-header"} >
                SKUs
            </span>

            ), renderCell: (params) => {
                return (
                    <>
                        <span class={"rtPaddedDigits-sm"}>{params.row.skuCount} </span>
                    </>
                )
            }, filterOperators
        },
        {
            field: "serviceTime", headerName: "Service Time",  renderHeader: (params) => (
                <span className={"grid-column-header"} style={{width: "125px",textAlign:"center"}}>
                Service <br/>Time (mins)
            </span>

            ), renderCell: (params) => {
                return (
                    <>
                    <span class={"rtPaddedDigits-sm"}> {params.row.serviceTime}</span>
                    </>
                )
            }, filterOperators
        },
        {
            field: "alertCount", headerName: "# of Alerts", width: 100, renderHeader: (params) => (
                <span className={"grid-column-header"} style={{width: "80px",textAlign:"center"}}>
                # of Alerts
            </span>

            ), renderCell: (params) => {
                return (
                    <>
                        <span class={"rtPaddedDigits"}> {params.row.alertCount}</span>
                    </>
                )
            }, filterOperators
        },
        {
            field: "driveDistanceFromPrevStop", headerName: "Drive Distance", width: 130, renderHeader: (params) => (
                <span className={"grid-column-header"} style={{textAlign:"center"}}>
                Drive Dist.<br/>(from prev miles)
            </span>

            ), renderCell: (params) => {
                const formattedValue = typeof params.row.driveDistanceFromPrevStop === 'number'   ? NumberWithCommas(params.row.driveDistanceFromPrevStop.toFixed(1)) : ' - ';
                return (
                    <>
                     <span class={"rtPaddedDigits"}>
                        {formattedValue}</span>
                    </>
                )
            }, filterOperators
        },
        {
            field: "driveTimeFromPrevStop", headerName: "Drive Time", width: 150, renderHeader: (params) => (
                <span className={"grid-column-header"} style={{textAlign:"center"}}>
                Drive Time<br/>(from prev mins)
            </span>

            ),  renderCell: (params) => {
               
                return (
                  <>
                  <span className="rtPaddedDigits-xl">{params.row.driveTimeFromPrevStop}</span>
                  </>
                )
              }, filterOperators
        },
        {
            field: "assetsDropped", headerName: "Pallets Left", width: 85, renderHeader: (params) => (
                <span className={"grid-column-header"} style={{textAlign:"center",paddingLeft:"10px"}} >
                    Pallets <br/>
                    Left
                </span>

            ), renderCell: (params) => {
                if(params.row.serviceTimeStatus=='In progress'){
                    return (
                        <><span className="centered"> - </span></>
                    )
                }
                return (
                    <>
                         <span class={"rtPaddedDigits"}> {params.row.assetsDropped}</span>
                    </>
                )
            },
            valueGetter: (params) => {
                if (params.row.serviceTimeStatus === 'In progress') {
                    return "-";
                }
                return params.row.assetsDropped ? params.row.assetsDropped : "";
            }, 
            filterOperators
        },

        {
            field: "currentPalletCountAtStop",
            headerName: "Pallets Accumulated",
            width: 110,
            renderHeader: (params) => (
                <span className={"grid-column-header"}>
            Pallets<br/>Accumulated
        </span>

            ),
            renderCell: (params) => {
                return (
                    <>
                    <span class={"rtPaddedDigits"}> {params.row.currentPalletCountAtStop}</span>
                    </>
                )
            },
            filterOperators
        },
        {
            field: "serviceTimeStatus", headerName: "Service Time Status",   align: 'center', width: 125, renderHeader: (params) => (
                <span className={"grid-column-header"}>
            Service Time<br/>Status
        </span>

            ), renderCell: (params) => {
                return (
                    <>
                        <span class={"centeredPaddedRtDigits"}>
                            <span style={{
                                color: "green",
                                display: ((params.row.serviceTimeStatus && params.row.serviceTimeStatus.toLowerCase() == "on time") ? "" : "none")
                            }}>{params.row.serviceTimeStatus}</span>
                            <span style={{
                                color: "red",
                                display: ((params.row.serviceTimeStatus && params.row.serviceTimeStatus.toLowerCase() == "delay") ? "" : "none")
                            }}>{params.row.serviceTimeStatus}</span>
                            <span style={{
                                color: "black",
                                display: ((params.row.serviceTimeStatus && ((params.row.serviceTimeStatus.toLowerCase() != "delay") && (params.row.serviceTimeStatus.toLowerCase() != "on time"))) ? "" : "none")
                            }}>{params.row.serviceTimeStatus}</span>
                        </span>

                    </>
                )
            }, filterOperators
        },


    ];
    const navigate = useNavigate();

   let onGridResize = () => {
        console.log("Grid Resize");
    }

    return (
        <div>

            <CustomPaper height="100%" padding="10px">
                {/*<Typography variant="h3" sx={{padding:"0rem 1rem 1rem 0.5rem"}}></Typography>*/}
                <Box
                    sx={{
                        "width": "100%",
                        '& .hot': {
                            "text-wrap": "wrap !important",
                            "text-align": "left"
                        },
                        '& .textcenter': {
                            "text-align": "center"
                        },
                        '& .delay': {
                            color: "red"
                        },
                        '& .ontime': {
                            color: "green"
                        }
                    }}
                >

                    <div style={{height: 500, width: "100%"}}>

                        <DataGrid loading={loading} sx={{display:"grid",borderColor: "#FFF !important"}}
                                  getRowHeight={() => 'auto'} getRowId={(row) => row.id} headerHeight={30}
                                  rows={loading ? [] : dataRows} columns={columns}
                                  getCellClassName={(params) => {
                                      if (params.field === 'stopNumber' || params.field === 'caseCount') {
                                          // console.log(params.value.length)
                                          return 'textcenter';
                                      } else if (params.field === 'storeAddress' && params.value) {
                                          // console.log(params.value.length)
                                          return params.value.length >= 15 ? 'hot' : 'cold'
                                      } else if (params.field === 'stopStartTime' || params.field === 'stopEndTime') {
                                          // console.log(params.value.length)
                                          return 'hot';
                                      } else if (params.field === 'serviceTimeStatus') {
                                          // console.log(params.value.length)
                                          if (params.value === 'Delay') {
                                              return 'delay';
                                          } else if (params.value && params.value.toLocaleString() === 'on time') {
                                              return 'ontime';
                                          }
                                          return '';

                                      }

                                  }}
                                  slots={{
                                      toolbar: GridToolbar,
                                      noResultsOverlay: () => (
                                          <Box
                                              sx={{
                                                  display: "flex", height: "100%", justifyContent: "center",
                                                  alignItems: "center", flexDirection: "column",
                                              }}>
                                              <Typography style={{fontWeight:"400", fontSize:"16px"}}>
                                                  No Results Found
                                              </Typography>

                                          </Box>
                                      ),
                                      noRowsOverlay:() => (
                                          <Box
                                              sx={{
                                                  display: "flex", height: "100%", justifyContent: "center",
                                                  alignItems: "center", flexDirection: "column",
                                              }}>
                                              <Typography style={{fontWeight:"400", fontSize:"16px"}}>
                                                  No Rows
                                              </Typography>
                                          </Box>
                                      ),
                                  }}

                                  hideFooterSelectedRowCount={true}
                                  slotProps={{toolbar: {showQuickFilter: true,},}}
                                  filterModel={filterModel}
                                  onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                                  pageSizeOptions={[5, 10, 25, 50, 100]}
                                  initialState={{
                                      pagination: {paginationModel: {pageSize: 25}},
                                  }}
                        />
                    </div>


                </Box>
            </CustomPaper>
        </div>
    );
}

/*const mapStateToProps = (state) => {
    return {
        selectedCustomerListData: state?.Routes?.selectedCustomerListData,
        selectedLocationListData: state?.Routes?.selectedLocationListData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCustomerAndLocations: () => dispatch(getCustomerAndLocations()),
    };
};*/