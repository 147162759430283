import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./reducers/authentication/AuthReducer";
import RoutesReducer from "./reducers/Routes/RoutesReducer";
import AssetsReducer from "./reducers/Assets/AssetsReducer";

export const Store = configureStore({
  reducer: {
    auth: AuthReducer,
    Routes: RoutesReducer,
    Assets:AssetsReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
