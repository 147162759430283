import React, {useEffect, useState} from "react";
import {
    DataGrid,
    GridToolbar,
    getGridStringOperators,
} from "@mui/x-data-grid";
import {Button, Typography, Box, CircularProgress, Stack} from "@mui/material";

import CustomPaper from "../Common/CustomPaper";

export default function CustomLinkRender({field,data,rowClick}) {


    let onClickLink = ()=>{
        rowClick(data);
    }
    return (
        <>
            <a style={{color:"#0000FF",cursor:"pointer",textDecoration:"underline"}} onClick={onClickLink}>
                <span className={"cellTextAlign"}>{field}</span>
            </a>

        </>
    );
}
