import React from "react";
import { connect } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import SideNavDrawer from "./components/navigation/SideNavDrawer";
import AssetDashboard from "./pages/AssetDashboard";
import CPHDashboard from "./pages/CPHDashboard";
import LiftDashboard from "./pages/LiftDashboard";
import RouteDetails from "./pages/RouteDetails";
import LiftMaintenance from "./pages/LiftMaintenance";
import AddMaintenance from "./pages/AddMaintenance";
const Dashboard = React.lazy(() => import("./pages/Dashboard"));
function AuthRoutes({ applicationIdsLoad, applicationIds, userRoleName }) {

  const Redirect = () => {
    if(userRoleName === "RpcAdmin" || userRoleName === "RpcReadOnly")
      return <Navigate replace to="/dashboard" />
    else if (applicationIdsLoad && applicationIds.includes(4) && applicationIds.includes(6))
      return <Navigate replace to="/dashboard" />
    else if (applicationIdsLoad && applicationIds.includes(6))
      return <Navigate replace to="/assetdashboard" />
    else if (applicationIdsLoad && applicationIds.includes(4))
      return <Navigate replace to="/dashboard" />
  };

  return (
    <React.Fragment>
      <SideNavDrawer>
        <React.Suspense fallback={<CircularProgress />}>
          <Routes>
            {/* <Route path="/" exact element={<Dashboard />} /> */}
            <Route path="/" element={<Redirect />} />
            <Route path="/dashboard" exact element={<Dashboard />} />
            <Route path="/routedetails" exact element={<RouteDetails />} />
            <Route path="/assetdashboard" exact element={<AssetDashboard />} />
            <Route path="/liftdashboard" exact element={<LiftDashboard />} />
            <Route path="/liftmaintenance" exact element={<LiftMaintenance />} />
            <Route path="/addMaintenance" exact element={<AddMaintenance />} />
            <Route path="/cphdashboard" exact element={<CPHDashboard />} />
          </Routes>
        </React.Suspense>
      </SideNavDrawer>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    userRoleName: state?.Routes?.userRoleName,
    applicationIds: state?.Routes?.applicationIds,
    applicationIdsLoad: state?.Routes?.applicationIdsLoad,
  };
};

export default connect(mapStateToProps, "")(AuthRoutes);