import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, CircularProgress, ListItem } from "@mui/material";
import RouteCard from "../components/RouteCard";
import RouteDataTable from "../components/DataTable/RouteDataTable";
import { Route, trolley, warehouse, stopWatch } from "../assets";
import { useNavigate } from "react-router-dom";
import { getRoutesList, getRoutesSummary, getAssetsSummary, getLiftsSummary, getCustomerAndLocations, customerUpdate, locationUpdate } from "../redux/Thunk/RoutesThunk";
import ShowModal from "../components/Modal";
import TopHeader from "../components/TopHeader";

function Dashboard({
  customerAndLocationsDataLoad,
  customerAndLocationsData,
  customersDataFromProps,
  loadingCustomersDataFromProps,
  loadingRoutesSummary,
  routesSummaryData,
  loadingAssetSummary,
  assetsSummaryData,
  loadingLiftSummary,
  liftsSummaryData,
  applicationIds,
  applicationIdsLoad,
  userRoleName,
  routeStartTime,
  routeDCTime
}) {
  const navigate = useNavigate()

  const [isUserHaveAccess, setIsUserHaveAccess] = useState(false);

  const handleLiftSummary = () => {
    navigate('/liftdashboard')
  }

  const handleAssetManagement = () => {
    navigate('/assetdashboard')
  }

  const handleCPH = () => {
    navigate('/cphdashboard')
  }

  const validateUser = () => {
    if (userRoleName)
      (userRoleName === "RpcAdmin" || userRoleName === "RpcReadOnly") ? setIsUserHaveAccess(false) :
        (applicationIds.includes(6) || applicationIds.includes(4)) ? setIsUserHaveAccess(false) : setIsUserHaveAccess(true)
  }

  //const [checkboxStatus, setCheckboxStatus] = useState("false");
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [routeData, setRouteData] = useState([]);

  useEffect(() => {
    validateUser()
  }, [userRoleName]);

  return (
    <Box sx={{ marginTop: "50px" }}>
      {isUserHaveAccess ?
        <Box>
          <ShowModal type="userWithNoAccess" />
        </Box> :
        (!customerAndLocationsDataLoad && customerAndLocationsData.length === 0) ?
          <ShowModal type="userWithNoCustomers" /> :
          customerAndLocationsDataLoad ?
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "40px",
                marginBottom: "10px",
              }}
            >
              <CircularProgress />
            </Box> :
            <Box>
              <TopHeader setSelectedCheckboxes = {setSelectedCheckboxes} routeData= {routeData} setRouteData={setRouteData} />
              <Box
                sx={{
                  marginTop: "20px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid container spacing={2} xs={12} md={12} lg={12} xl={12}>
                    <Grid xs={12} md={6} lg={applicationIdsLoad && ((userRoleName === "RpcAdmin" || userRoleName === "RpcReadOnly") || (applicationIds.includes(6))) ? 3 : 4} xl={applicationIdsLoad && ((userRoleName === "RpcAdmin" || userRoleName === "RpcReadOnly") || (applicationIds.includes(6))) ? 3 : 4}>
                      <RouteCard >
                        <Box sx={{ mb: 1, minHeight:"55px" }}>
                          <Grid container spacing={1}>
                            <Grid xs={10} lg={10} xl={10}>
                              <Box className="d-flex kpi-title w-100" >
                                <Box className="k-title text-secondary">Route Summary</Box>
                              </Box>
                            </Grid>
                            <Grid xs={2} lg={2} xl={2}>
                              <Box>
                                <img src={Route} alt="route" />
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                        {loadingRoutesSummary ?
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "40px",
                              marginBottom: "10px",
                            }}
                          >
                            <CircularProgress />
                          </Box> :
                          <Box>
                            <Grid container spacing={1}>
                              <Grid xs={6} lg={6} xl={6} style={{minHeight:"95px"}}>
                                <ListItem className="grid-title text-primary">{routesSummaryData?.routeCount}</ListItem>
                                <ListItem className="grid-title-sub">Total Routes</ListItem>
                              </Grid>
                              <Grid xs={6} lg={6} xl={6} style={{minHeight:"95px"}}>
                                <ListItem className="grid-title text-primary">{routesSummaryData?.incompleteRouteCount}</ListItem>
                                <ListItem className="grid-title-sub">Incomplete Routes</ListItem>
                              </Grid>
                              <Grid xs={6} lg={6} xl={6} style={{minHeight:"95px"}}>
                                <ListItem className="grid-title text-primary">{routesSummaryData?.plannedStopCount}</ListItem>
                                <ListItem className="grid-title-sub">Total Stops</ListItem>
                              </Grid>
                              <Grid xs={6} lg={6} xl={6} style={{minHeight:"95px"}}>
                                <ListItem className="grid-title text-primary">{routesSummaryData?.delayedStopCount}</ListItem>
                                <ListItem className="grid-title-sub">Delayed Stops</ListItem>
                              </Grid>
                            </Grid>
                          </Box>
                        }
                      </RouteCard>
                    </Grid>
                    <Grid xs={12} md={6} lg={applicationIdsLoad && ((userRoleName === "RpcAdmin" || userRoleName === "RpcReadOnly") || (applicationIds.includes(6))) ? 3 : 4} xl={applicationIdsLoad && ((userRoleName === "RpcAdmin" || userRoleName === "RpcReadOnly") || (applicationIds.includes(6))) ? 3 : 4} onClick={handleLiftSummary} style={{ cursor: "pointer" }}>
                      <RouteCard >
                        <Box sx={{ mb: 1, minHeight:"55px"  }}>
                          <Grid container spacing={1}>
                            <Grid xs={10} lg={10} xl={10}>
                              <Box className="d-flex kpi-title w-100" >
                                <Box className="k-title text-secondary">Lift Summary</Box>
                              </Box>
                            </Grid>
                            <Grid xs={2} lg={2} xl={2}>
                              <Box>
                                <img src={trolley} alt="trolley" />
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                        {loadingLiftSummary ?
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "40px",
                              marginBottom: "10px",
                            }}
                          >
                            <CircularProgress />
                          </Box> :
                          <Box>
                            <Grid container spacing={1}>
                              <Grid xs={6} lg={6} xl={6} style={{minHeight:"95px"}}>
                                <ListItem className="grid-title text-primary">{liftsSummaryData?.liftsInService}</ListItem>
                                <ListItem className="grid-title-sub">Lifts in Service</ListItem>
                              </Grid>
                              <Grid xs={6} lg={6} xl={6} style={{minHeight:"95px"}}>
                                <ListItem className="grid-title text-primary">{liftsSummaryData?.maintenanceOverDue}</ListItem>
                                <ListItem className="grid-title-sub">Lifts Overdue Maintenance</ListItem>
                              </Grid>
                              <Grid xs={6} lg={6} xl={6} style={{minHeight:"95px"}}>
                                <ListItem className="grid-title text-primary">{liftsSummaryData?.maintenanceDues}</ListItem>
                                <ListItem className="grid-title-sub">Lifts Due For Maintenance</ListItem>
                              </Grid>
                            </Grid>
                          </Box>
                        }
                      </RouteCard>
                    </Grid>
                    <Grid xs={12} md={6} lg={3} xl={3} onClick={handleAssetManagement} style={{ cursor: "pointer", display: applicationIdsLoad && ((userRoleName === "RpcAdmin" || userRoleName === "RpcReadOnly") || (applicationIds.includes(6)) ? "show" : "none" )}}>
                      <RouteCard >
                        <Box sx={{ mb: 1, minHeight:"55px"}}>
                          <Grid container spacing={1}>
                            <Grid xs={10} lg={10} xl={10}>
                              <Box className="d-flex kpi-title w-100" >
                                <Box className="k-title text-secondary">Asset Management Summary</Box>
                              </Box>
                            </Grid>
                            <Grid xs={2} lg={2} xl={2}>
                              <Box>
                                <img src={warehouse} alt="warehouse" />
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                        {loadingAssetSummary ?
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "40px",
                              marginBottom: "10px",
                            }}
                          >
                            <CircularProgress />
                          </Box> :
                          <Box>
                            <Grid container spacing={1}>
                              <Grid xs={6} lg={6} xl={6} style={{minHeight:"95px"}}>
                                <ListItem className="grid-title text-primary">{assetsSummaryData?.atDc}</ListItem>
                                <ListItem className="grid-title-sub">Pallets at DC</ListItem>
                              </Grid>
                              <Grid xs={6} lg={6} xl={6} style={{minHeight:"95px"}}>
                                <ListItem className="grid-title text-primary">{assetsSummaryData?.atStores}</ListItem>
                                <ListItem className="grid-title-sub">Pallets on Routes</ListItem>
                              </Grid>
                              <Grid xs={6} lg={6} xl={6} style={{minHeight:"95px"}}>
                                <ListItem className="grid-title text-primary">{assetsSummaryData?.lost}</ListItem>
                                <ListItem className="grid-title-sub">Pallets Lost</ListItem>
                              </Grid>
                            </Grid>
                          </Box>
                        }
                      </RouteCard>
                    </Grid>
                    <Grid xs={12} md={6} lg={applicationIdsLoad && ((userRoleName === "RpcAdmin" || userRoleName === "RpcReadOnly") || (applicationIds.includes(6))) ? 3 : 4} xl={applicationIdsLoad && ((userRoleName === "RpcAdmin" || userRoleName === "RpcReadOnly") || (applicationIds.includes(6))) ? 3 : 4} sx={{ position: "relative" }} onClick={handleCPH} style={{ cursor: "pointer" }}>
                      <RouteCard >
                        <Box sx={{ mb: 1, minHeight:"55px"}}>
                          <Grid container spacing={1}>
                            <Grid xs={10} lg={10} xl={10}>
                              <Box className="d-flex kpi-title w-100" >
                                <Box className="k-title text-secondary">Cases Summary</Box>
                              </Box>
                            </Grid>
                            <Grid xs={2} lg={2} xl={2}>
                              <Box>
                                <img src={stopWatch} alt="stopWatch" />
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                        {loadingRoutesSummary ?
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "40px",
                              marginBottom: "10px",
                            }}
                          >
                            <CircularProgress />
                          </Box> :
                          <Box>
                            <Grid container spacing={1}>
                              <Grid xs={12} lg={12} xl={12} style={{minHeight:"95px"}}>
                                <ListItem className="grid-title text-primary">{routesSummaryData?.plannedCaseCount}</ListItem>
                                <ListItem className="grid-title-sub">Total Cases Planned</ListItem>
                              </Grid>
                              <Grid xs={12} lg={12} xl={12} style={{minHeight:"95px"}}>
                                <ListItem className="grid-title text-primary">{routesSummaryData?.actualCPH}</ListItem>
                                <ListItem className="grid-title-sub">Cases Per Hour</ListItem>
                              </Grid>
                            </Grid>
                          </Box>
                        }
                      </RouteCard>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  marginTop: "20px",
                }}
              >
                <Grid container>
                  <Grid xs={12} lg={12} xl={12}>
                    <RouteDataTable 
                    selectedRoutesDate={localStorage?.getItem('routeDate')} loadingCustomersDataFromProps={loadingCustomersDataFromProps} routesList={customersDataFromProps} 
                    routeStartTime={routeStartTime} routeDCTime={routeDCTime} selectedCheckboxes = {selectedCheckboxes} setSelectedCheckboxes = {setSelectedCheckboxes} routeData= {routeData} setRouteData={setRouteData}/>
                  </Grid>
                </Grid>
              </Box>
            </Box>
      }
    </Box>
  );
}

const mapStateToProps = (state) => {
  return {
    selectedCustomerId: state?.Routes?.selectedCustomer,
    selectedLocationId: state?.Routes?.selectedLocation,
    customerAndLocationsDataLoad: state?.Routes?.customerAndLocationsDataLoad,
    customerAndLocationsData: state?.Routes?.customerAndLocationsData,
    loadingCustomersDataFromProps: state?.Routes?.listofRoutesDataLoad,
    customersDataFromProps: state?.Routes?.listofRoutesData,
    loadingRoutesSummary: state?.Routes?.routesSummaryDataLoad,
    routesSummaryData: state?.Routes?.routesSummaryData,
    loadingAssetSummary: state?.Routes?.assetsSummaryDataLoad,
    assetsSummaryData: state?.Routes?.assetsSummaryData,
    loadingLiftSummary: state?.Routes?.liftSummaryDataLoad,
    liftsSummaryData: state?.Routes?.liftSummaryData,
    selectedLocationsList: state?.Routes?.selectedLocationsList,
    selectedCustomerListData: state?.Routes?.selectedCustomerListData,
    selectedLocationListData: state?.Routes?.selectedLocationListData,
    applicationIds: state?.Routes?.applicationIds,
    applicationIdsLoad: state?.Routes?.applicationIdsLoad,
    userRoleName: state?.Routes?.userRoleName,
    routeStartTime: state?.Routes?.routeStartTime,
    routeDCTime: state?.Routes?.routeDCTime
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerAndLocations: () => dispatch(getCustomerAndLocations()),
    getRouteList: (customerLocationAndDate) => dispatch(getRoutesList(customerLocationAndDate)),
    getRouteSummary: (customerLocationAndDate) => dispatch(getRoutesSummary(customerLocationAndDate)),
    getAssetSummary: (customerLocationdata) => dispatch(getAssetsSummary(customerLocationdata)),
    getLiftSummary: (customerLocationdata) => dispatch(getLiftsSummary(customerLocationdata)),
    updateSelectedCustomer: (data) => dispatch(customerUpdate(data)),
    updateSelectedLocation: (data) => dispatch(locationUpdate(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
