import React, { Children, useEffect, useState } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";

import CustomPaperActive from "./Common/CustomPaperActive";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#58595B",
    boxShadow:
      "0px 0px 4px 0px rgba(0, 7, 22, 0.14), 0px 1px 14px 0px rgba(65, 77, 92, 0.20)",
    fontSize: 11,
  },
}));

export default function AssetCard({
  //avatarColor,
    selected,
  cardType,
  kpiPercentage,
  orderNumberText,
  orderText,...rest
}) {
  const [avatarIcon, setAvatarIcon] = useState();
  const [avatarColor, setAvatarColor] = useState();

  useEffect(() => {
    getAvatar(cardType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getAvatar(cardType) {
    if (cardType === "correctlyDelivered" || cardType === "orderAccuracy") {
      if (kpiPercentage > 0) {
        setAvatarIcon(<ArrowUpwardIcon />);
        setAvatarColor("#4CAF50");
      } else if (kpiPercentage < 0) {
        setAvatarIcon(<ArrowDownwardIcon />);
        setAvatarColor("#EF5350");
      } else if (kpiPercentage === 0) {
        setAvatarIcon(<HorizontalRuleIcon />);
        setAvatarColor("#4CAF50");
      }
    }
    if (cardType === "incorrectlyDelivered") {
      if (kpiPercentage < 0) {
        setAvatarIcon(<ArrowUpwardIcon />);
        setAvatarColor("#4CAF50");
      } else if (kpiPercentage > 0) {
        setAvatarIcon(<ArrowDownwardIcon />);
        setAvatarColor("#EF5350");
      } else if (kpiPercentage === 0) {
        setAvatarIcon(<HorizontalRuleIcon />);
        setAvatarColor("#4CAF50");
      }
    }
  }

  //console.log("avatarColor ", avatarColor);

  return (
    <CustomPaperActive height="107px" selected={selected}  >
      <Box
        sx={{
        //   display: "flex",
        padding: "15px",
          justifyContent:"center",
      //    border:"1px solid gray"
        //   height:"100%",
        //   flexDirection:"column"
        }}
      >
       {rest?.children}
      </Box>
    </CustomPaperActive>
  );
}
