import React, { useState, useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { styled } from "@mui/material/styles";
import { Box, ListItemIcon, Stack, Grid, CircularProgress } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import { AmiLogoMedium, RehrigLogo, AppLogoSmall, RehrigLogoSmall, bellIcon } from "../../assets";
import { navItemsForBothApps, navItemsForDeliveryIOT, navItemsForAMI } from "./consts/navListItems";
import { Link } from "react-router-dom";
import { Constants } from "../utils/Constants";
import { connect } from "react-redux";
import { getAlertsForLocation, getUserRole, getCustomerAndLocations } from "../../redux/Thunk/RoutesThunk";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(10)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
});

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${open ? (drawerWidth - 45) : -250}px`,
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
    },
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const columns = [
  {
    field: 'time',
    headerName: 'Date & Time',
    flex: 1.2,
    renderCell: (params) => {
      const dateobj = moment(params.row.time).format("MMM DD, YYYY hh:mm a");
      return <>{dateobj}</>;
    },
  },
  {
    field: 'liftId',
    headerName: 'Lift ID',
    flex: 1,
  },
  {
    field: 'description',
    headerName: 'Alert Description',
    flex: 1,
  },
  {
    field: 'address',
    headerName: ' Alert Address',
    flex: 1,
  },
  {
    field: 'driverName',
    headerName: 'Driver Name',
    flex: 1,
  },
];

let landPageTitle = "";
function SideNavDrawer({ children,
  selectedCustomerId,
  selectedLocationId,
  getAlertsForLocation,
  locationAlertsDataLoad,
  locationAlertsData,
  getUserRole,
  applicationIds,
  applicationIdsLoad,
  userRoleName,
  customerAndLocationsData,
  getCustomerAndLocations,
  selectedCustomerListData,
  selectedLocationListData }) {
  let navigate = useNavigate();
  let location = useLocation();
  const [open, setOpen] = useState(true);
  const [alertsOpen, setAlertsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [pageTitle, setPageTitle] = useState("Home");
  const [pageTitle2, setPageTitle2] = useState("");


  const [landingPageTitle, setLandingPageTitle] = useState("Home");
  const [pageBreadCrum, setPageBreadCrum] = useState("");

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(!open);
  };

  const handleListItemClick = (e, item) => {
    stopAutoRefresh();
    setPageTitle2("");
    Constants.SELECT_DATE = null;
    setPageTitle(item.name);
    setLandingPageTitle(item.name);
    landPageTitle = item.name;
    setSelectedIndex(item.id);
    navigate(item.path);
  };

  let onRouteSelection = () => {
    // console.log("Route Selection")
    setPageBreadCrum(`${landPageTitle === "Home" ? landPageTitle + " /" : landPageTitle + "/"}`);
    setPageTitle2("");
    setPageTitle("Route Details");
  }

  let onLiftMaintenanceSelection = () => {
    setPageBreadCrum(`${landPageTitle === "Lifts" ? landPageTitle + "/" : landPageTitle + "/"}`);
    setPageTitle2("");
    setPageTitle("Alerts & Maintenance History");
  }

  let onLiftAddMaintenanceSelection = () => {
    setPageBreadCrum(`${landPageTitle === "Lifts" ? landPageTitle + "/" : landPageTitle + "/"}`);
    setPageTitle2("Alerts & Maintenance History/");
    setPageTitle("Add Maintenance Record");
  }

  useEffect(() => {
    if (location.pathname === "/dashboard") {
     // Constants.SELECT_DATE = null;
      setPageTitle2("");
      setSelectedIndex(1);
      setPageBreadCrum("");
      setLandingPageTitle("Home")
      landPageTitle = "Home";
      setPageTitle("Home");
    } else if (location.pathname === "/liftdashboard") {
      setPageTitle2("");
      setSelectedIndex(2);
      setPageBreadCrum("");
      setLandingPageTitle("Lifts")
      setPageTitle("Lifts");
      landPageTitle = "Lifts";
    } else if (location.pathname === "/assetdashboard") {
      setPageTitle2("");
      setSelectedIndex(3);
      setPageBreadCrum("");
      setLandingPageTitle("Assets")
      setPageTitle("Assets");
      landPageTitle = "Assets";
    } else if (location.pathname === "/cphdashboard") {
      setPageTitle2("");
      setSelectedIndex(4);
      setPageBreadCrum("");
      setLandingPageTitle("CPH")
      setPageTitle("CPH");
      landPageTitle = "CPH";
    }else if (location.pathname === "/liftMaintenance") {
      setPageTitle2("");
      setSelectedIndex(2);
      setLandingPageTitle("Lifts")
      //setPageTitle("Lifts");
      landPageTitle = "Lifts";
      setPageBreadCrum("Lifts/");
      setPageTitle("Alerts & Maintenance History");
    }
    document.addEventListener(Constants.LIFT_MAINTENANCE_EVENT, onLiftMaintenanceSelection);
    document.addEventListener(Constants.LIFT_ADD_MAINTENANCE_EVENT, onLiftAddMaintenanceSelection);
    document.addEventListener(Constants.ROUTE_SELECTION_EVENT, onRouteSelection);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener(Constants.ROUTE_SELECTION_EVENT, onRouteSelection);
    }
  }, [location]);

  // useEffect(() => {
  //   if (applicationIdsLoad && applicationIds.includes(4) && applicationIds.includes(6)) {
  //     window.location.replace("/");
  //   }
  //   else if (applicationIdsLoad && applicationIds.includes(6))
  //     window.location.replace("/assetdashboard");
  // }, [])

  useEffect(() => {
    getUserRole()
  }, [])

  useEffect(() => {
    if (customerAndLocationsData.length === 0)
      getCustomerAndLocations();
  }, []);

  const onLogOut = () => {
    localStorage.clear();
    Auth.signOut()
      .then(() => {
        localStorage.clear();
        Constants.SELECT_DATE = null;
        window.location.replace('/');
      })
      .catch((err) => {
        console.log("Error on Logout:", err.message);
      });
  }


  useIdleTimer({
    timeout: 1000 * 60 * 60,
    onIdle: onLogOut,
    debounce: 1000,
  });

  let stopAutoRefresh = () => {
    const event = new CustomEvent(Constants.ROUTE_DETAILS_BACK_EVENT, { detail: {} });
    document.dispatchEvent(event);
  }

  const handleAlertClick = () => {
    const customerLocationdata = {
      customerId: selectedCustomerListData.customerId,
      locationId: selectedLocationListData.locationId,
      date: localStorage.getItem('routeDate'),
    }
    getAlertsForLocation(customerLocationdata)
    setAlertsOpen(true);
  }

  const handleClose = () => {
    setAlertsOpen(false);
  };

  return (
    <>
    {!applicationIdsLoad ?
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "40px",
          marginBottom: "10px",
        }}
      >
        <CircularProgress />
      </Box> :
      <Box sx={{ display: "flex" }}>
      {alertsOpen &&
        <React.Fragment>
          <Dialog
            open={alertsOpen}
            onClose={handleClose}
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "800px",  // Set your width here
                },
              },
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" sx={{ fontSize: "24px" }}>
              Notifications
              <IconButton style={{ float: "right" }}>
                <CloseIcon onClick={() => handleClose()} />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Box sx={{ width: "100%" }}>
                  <DataGrid autoHeight
                    sx={{
                      display: "grid", borderColor: "#FFF !important",
                      '& .MuiDataGrid-sortIcon': {
                        opacity: 0,
                      },
                    }}
                    rows={locationAlertsDataLoad ? [] : locationAlertsData}
                    getRowId={(row) => row.seqNumber}
                    columns={columns}
                    loading={locationAlertsDataLoad}
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 25 } },
                    }}
                    disableRowSelectionOnClick
                    disableColumnMenu
                  />
                </Box>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      }
      <AppBar
        position="fixed"
        open={open}
        sx={{

          backgroundColor: "#FBFBFB",
          boxShadow: "none",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...({ display: "none" }) }}
          >
            <MenuIcon
              sx={{
                color: "black",
              }}
            />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "100%", mt: "12px" }}>
              <Grid container>
                {/* <Grid item xs={2}>
                  <Box style={{fontSize:"16px", fontWeight:700, color:"#58595B" }}>
                    <a style={{ cursor: "pointer", textDecoration: "underline"}} onClick={() => {
                      stopAutoRefresh();
                      navigate(-1);//{ state: { routeData: ['date'] } })
                    }}>{pageBreadCrum} </a>
                    {pageTitle}
                  </Box>
                </Grid> */}
                <Grid item xs={10} >
                  <Box style={{ fontSize: "16px", fontWeight: 700 }}  sx={{ ml: open ? .125:9.99 }}>
                    <Stack direction="row" spacing={4}>
                      <Box style={{ color: "#58595B" }}>
                        <a style={{ cursor: "pointer", textDecoration: "underline" }} onClick={(e) => {
                         console.log(e.currentTarget.text);
                          stopAutoRefresh();
                          if(e && e.currentTarget && e.currentTarget.text.indexOf("Lifts/")>=0) {
                            navigate("/liftdashboard");
                          }else if(pageTitle2) {
                            navigate(-2);
                          }else{
                            navigate(-1);
                          }
                          //{ state: { routeData: ['date'] } })
                        }}>{pageBreadCrum} </a>  <a style={{ cursor: "pointer", textDecoration: "underline" }} onClick={()=>{
                        navigate(-1);
                        setPageTitle2("");
                        setPageTitle("Alerts & Maintenance History");
                      }}>{pageTitle2}</a> <a>{pageTitle}</a> </Box>
                      {location.pathname !== "/cphdashboard" && (
                        selectedCustomerListData.customerName && (
                          <Box style={{ color: "#000000" }}>
                            {selectedCustomerListData.customerName}   -
                           <span style={{ fontWeight: 600 }}> {selectedLocationListData.locationName} </span>
                          </Box>
                        )
                      )}
                    </Stack>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box sx={{ textAlign: "right" }}>
                    <img src={bellIcon} onClick={() => handleAlertClick()} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
              marginY: "20px",
              height: "50px"
            }}
          >
            <IconButton aria-label="logo" onClick={() => navigate("/")} disableRipple={true} disableFocusRipple={true}>
              {
                open ?
                  <img src={AmiLogoMedium} alt="logo" style={{ marginLeft: "10px" }} /> :
                  <img src={AppLogoSmall} alt="logo" style={{ marginLeft: "35px", position: "absolute", background: "none" }} />
              }
            </IconButton>
            <IconButton onClick={handleDrawerClose}>
              {open ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon sx={{ position: "absolute", right: "-10px", background: "none" }} />
              )}
            </IconButton>
          </Box>
        </DrawerHeader>
        <Divider />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Box>
            {(() => {
              if (applicationIdsLoad && ((userRoleName === "RpcAdmin" || userRoleName === "RpcReadOnly") || (applicationIds?.includes(4) && applicationIds?.includes(6)))) {
                return <List>
                  {navItemsForBothApps.map((item, index) => (
                    <ListItem
                      key={item.id}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <ListItemButton
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: "#2776CC",
                            color: "#FFFFFF",
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: "#2776CC",
                            color: "#FFFFFF",
                          },
                          "&.Mui-selected.active": {
                            color: "#FFFFFF",
                          }
                        }}
                        selected={selectedIndex === item.id}
                        onClick={(event) => handleListItemClick(event, item)}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            marginLeft: "8px"
                          }}
                        >
                          {item.icon ? <img src={selectedIndex === item.id ? item.iconActive : item.icon} alt="rehrig-logo" sx={{ marginBottom: "32px", marginTop: "32px" }} /> : <InboxIcon />}
                        </ListItemIcon>
                        <ListItemText sx={{ opacity: open ? 1 : 0 }} >
                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: selectedIndex === item.id && "600",
                              color: selectedIndex === item.id && "#FFFFFF",
                              textDecoration: selectedIndex === item.id && "none",
                            }}
                          >
                            {item.text}
                          </Typography>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              }
              else if (applicationIdsLoad && applicationIds?.includes(4)) {
                return <List>
                  {navItemsForDeliveryIOT.map((item, index) => (
                    <ListItem
                      key={item.id}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <ListItemButton
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: "#2776CC",
                            color: "#FFFFFF",
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: "#2776CC",
                            color: "#FFFFFF",
                          },
                          "&.Mui-selected.active": {
                            color: "#FFFFFF",
                          }
                        }}
                        selected={selectedIndex === item.id}
                        onClick={(event) => handleListItemClick(event, item)}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            marginLeft: "8px"
                          }}
                        >
                          {item.icon ? <img src={selectedIndex === item.id ? item.iconActive : item.icon} alt="rehrig-logo" sx={{ marginBottom: "32px", marginTop: "32px" }} /> : <InboxIcon />}
                        </ListItemIcon>
                        <ListItemText sx={{ opacity: open ? 1 : 0 }} >
                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: selectedIndex === item.id && "600",
                              color: selectedIndex === item.id && "#FFFFFF",
                              textDecoration: selectedIndex === item.id && "none",
                            }}
                          >
                            {item.text}
                          </Typography>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              } else if (applicationIdsLoad && applicationIds?.includes(6)) {
                return <List>
                  {navItemsForAMI.map((item, index) => (
                    <ListItem
                      key={item.id}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <ListItemButton
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: "#2776CC",
                            color: "#FFFFFF",
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: "#2776CC",
                            color: "#FFFFFF",
                          },
                          "&.Mui-selected.active": {
                            color: "#FFFFFF",
                          }
                        }}
                        selected={selectedIndex === item.id}
                        onClick={(event) => handleListItemClick(event, item)}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            marginLeft: "8px"
                          }}
                        >
                          {item.icon ? <img src={selectedIndex === item.id ? item.iconActive : item.icon} alt="rehrig-logo" sx={{ marginBottom: "32px", marginTop: "32px" }} /> : <InboxIcon />}
                        </ListItemIcon>
                        <ListItemText sx={{ opacity: open ? 1 : 0 }} >
                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: selectedIndex === item.id && "600",
                              color: selectedIndex === item.id && "#FFFFFF",
                              textDecoration: selectedIndex === item.id && "none",
                            }}
                          >
                            {item.text}
                          </Typography>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              }
            })()}
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "20px",
                marginBottom: "10px",
              }}
            >
              <IconButton
                onClick={() => {
                  Constants.SELECT_DATE = null;
                  localStorage.clear();
                  Auth.signOut();
                  window.location.replace("/");
                  // navigate("/");
                  // window.location.reload();
                }}
                aria-label="signOut"
              >
                <LogoutIcon
                  sx={{
                    color: "#111540",
                  }}
                />
              </IconButton>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginY: "20px",
              }}
            >
              {
                open ?
                  <img src={RehrigLogo} alt="rehrig-logo" /> :
                  <img src={RehrigLogoSmall} alt="rehrig-logo-small" />
              }
            </Box>
          </Box>
        </Box>
      </Drawer>
      <Main open={open}>
        {children}
      </Main>
    </Box>
    }
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    customerAndLocationsData: state?.Routes?.customerAndLocationsData,
    selectedCustomerId: state?.Routes?.selectedCustomer,
    selectedLocationId: state?.Routes?.selectedLocation,
    locationAlertsDataLoad: state?.Routes?.locationAlertsDataLoad,
    locationAlertsData: state?.Routes?.locationAlertsData,
    applicationIds: state?.Routes?.applicationIds,
    applicationIdsLoad: state?.Routes?.applicationIdsLoad,
    userRoleName: state?.Routes?.userRoleName,
    selectedCustomerListData: state?.Routes?.selectedCustomerListData,
    selectedLocationListData: state?.Routes?.selectedLocationListData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerAndLocations: () => dispatch(getCustomerAndLocations()),
    getAlertsForLocation: (customerLocationdata) => dispatch(getAlertsForLocation(customerLocationdata)),
    getUserRole: (centralUserId) => dispatch(getUserRole(centralUserId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideNavDrawer);
