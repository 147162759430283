import React from "react";
import Box from "@mui/material/Box";
import CustomPaper from "../Common/CustomPaper";
import SignIn from "./Signin";

export default function AuthFlow() {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CustomPaper height="auto" width="564px">
        <SignIn />
      </CustomPaper>
    </Box>
  );
}
