

export const loadMapApi = () => {
    var mapsURL = "https://maps.googleapis.com/maps/api/js?";
    mapsURL = mapsURL+"key="+process.env.REACT_APP_GOOGLE_MAP_KEY+"&libraries=geometry,places";


    const scripts = document.getElementsByTagName('script');
    // Go through existing script tags, and return google maps api tag when found.
    for (let i = 0; i < scripts.length; i++) {
        if (scripts[i].src.indexOf(mapsURL) === 0) {
            return scripts[i];
        }
    }

    const googleMapScript = document.createElement('script');
    googleMapScript.src = mapsURL;
    //googleMapScript.async = true;
    //googleMapScript.defer = true;


    window.document.head.appendChild(googleMapScript);

    return googleMapScript;
};
