import { createSlice } from "@reduxjs/toolkit";
// import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";

const initialState = {
  authStatus: "configuring",
};

export const AuthReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthStatus: (state, action) => {
      state.authStatus = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAuthStatus } = AuthReducer.actions;

// export const selectAuthStatus = (state) => state.auth.authStatus;

export default AuthReducer.reducer;
