import React, {useEffect, useState} from "react";
import {
    DataGrid,
    GridToolbar,
    getGridStringOperators, useGridApiRef,
} from "@mui/x-data-grid";
import { Button, Typography, Box, Grid } from "@mui/material";

import CustomPaper from "../Common/CustomPaper";
import CustomCellRender from "./CustomCellRender";
import CustomCellNumRender from "./CustomCellNumRender";
import CustomLinkRender from "./CustomLinkRender";
import {Constants} from "../utils/Constants";
import {useNavigate} from "react-router-dom";
import moment from "moment/moment";

let rowNumber = -1;
export default function DataTableOne({data,progress,amuser}) {
  const filterOperators = getGridStringOperators().filter(({ value }) =>
    ["contains" /* add more over time */].includes(value)
  );


    const navigate = useNavigate();
    const [pallets,setPallets] = useState([]);
    const [showGrid,setShowGrid] = useState(false);

    const apiRef = useGridApiRef();

  // const [pageSize, setPageSize] = useState(10);
  const [filterModel, setFilterModel] = useState({
    items: [
      {
        field: "rating",
        operator: ">",
        value: "2.5",
      },
    ],
  });

  useEffect(()=>{
      setShowGrid(false);
      rowNumber = -1;
  },[progress]);
let onRowStoreClick = (row)=>{
    console.log(row);
    let rows = [];
    if(row && row.pallets){
        rows = row.pallets;
    }
    rows.forEach((item,idx)=>{
        item.id = idx;
    })

    if(rowNumber == row.id){
        setShowGrid(false);
        rowNumber = -1;
    }else{
        setShowGrid(true);
        rowNumber = row.id;
    }
    setPallets(rows);

}
let onRowRouteClick = (row)=>{
    console.log(row);
    if(row && row.routeId && row.routeName){
        /*if(apiRef && apiRef.current){
            apiRef.current.selectRow(1);
        }*/

        var routeData= [{ routeId: row.routeId, routeName: row.routeName,routeStatus:Constants.ROUTE_IN_COMPLETED }];
        const event = new CustomEvent(Constants.ROUTE_SELECTION_EVENT, { detail: {} });
        document.dispatchEvent(event);
        navigate('/routedetails', { state: { routeData: routeData } });
    }

}
  const columns = [

    { field: "storeName", headerName: "Store Name",width:"350",renderHeader: (params) => (
            <span className={"ltAllignLtPaddedDigits-xl"} style={{width:"350px"}}>
                Store Name
            </span>

        ),renderCell: (params) => {
        return(
            <>
              <CustomLinkRender field={params.row.storeName} data={params.row} rowClick={onRowStoreClick}  />
            </>
        )}, filterOperators },
    { field: "storeAddress", headerName: "Store Address",renderHeader: (params) => (
          <span className={"ltAllignLtPaddedDigits-xl"} style={{width:"400px"}}>
              Store Address
          </span>

      ),width:"300",renderCell: (params) => {
      return(
          <>
              <span>{params.row.storeAddress}</span>
          </>
    )}, filterOperators },    

    /*{ field: "routeName", headerName: "Route Name", width: 130,renderHeader: (params) => (
            <span className={"grid-column-header"} style={{width:"150px"}}>
                Route Name
            </span>

        ), renderCell: (params) => {
            return(
                <>
                    {amuser?<span>{params.row.routeName}</span>:<CustomLinkRender field={params.row.routeName} data={params.row} rowClick={onRowRouteClick}  />}
                </>
            )},filterOperators },*/
        { field: "totalPallets", headerName: "Total Pallets", width: 130,   
          align: "right",  renderHeader: (params) => (
          <span className={"grid-column-header-no"}  style={{width:"150px"}}>
              
              Total Pallets
          </span>

      ),renderCell: (params) => {
          return(
              <>
                  <span class={"rtPaddedDigits"}> {params.row.totalPallets}</span>
              </>
          )},filterOperators },
          { field: "avgDwellTime", headerName: "Avg Dwell Time", minWidth: 150,  
              align: "right", renderHeader: (params) => (
            <span className={"grid-column-header-no"}  style={{width:"150px"}}>
                Avg Dwell Time
            </span>

        ),renderCell: (params) => {
            return(
                <>
                    <span className={"rtPaddedDigits"}> {params.row.avgDwellTime}</span>
                </>
            )},filterOperators },

  ];
  const subColumns = [
    {
      field: "deliveryDate",headerName: "Date",
      width: 150,
      renderHeader: (params) => (
        <span className="ltPaddedDigits">Date</span>
      ),
      renderCell: (params) => {
        return (
          <>
            <span className="centered">
              {params.row.deliveryDate ? moment(params.row.deliveryDate).format('MMM D, YYYY') : " - "}
            </span>
          </>
        );
      },
      filterOperators
    },
    {
      field: "totalPallets",
      headerName: "Total Pallets",
      width: 125,
      renderHeader: (params) => (
        <span className="centered-header">Total Pallets</span>
      ),
      renderCell: (params) => {
        return (
          <>
            <span className="centered">{params.row.totalPallets}</span>
          </>
        );
      },
      filterOperators
    },
  ];
  
  const [selectedRows,setSelectedRows] = useState([]);



  return (
    <CustomPaper height="100%" padding="20px">
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "0.5rem" }}>
          <Grid container>
          <Grid xs={6} lg={6} xl={6}>
          <Box> <Typography variant="h3" sx={{padding:"0rem 0rem 0rem 0.8rem", margin:"0.3rem 0"}}>Pallets on Routes</Typography></Box>
     </Grid>

     <Grid xs={6} lg={6} xl={6}>
    <Box style={{display:selectedRows?.length > 0 ? "none":"none"}}>
      <Button className="btn btn-outline-danger me-3">Delete</Button>
      <Button className="btn btn-link">Cancel</Button>
    </Box>
    </Grid>
    </Grid>

    </Box>

      <div style={{display:"flex",height:500,width:"100%"}}>
        <DataGrid sx={{borderColor:"#FFF !important"}}
         loading={progress} getRowId={(row) => row.id}
          rows={data}
          columns={columns}
                  slots={{
            toolbar: GridToolbar,

                      noResultsOverlay: () => (
                          <Box
                              sx={{
                                  display: "flex", height: "100%", justifyContent: "center",
                                  alignItems: "center", flexDirection: "column",
                              }}>
                              <Typography style={{fontWeight:"400", fontSize:"16px"}}>
                                  No Results Found
                              </Typography>

                          </Box>
                      ),
                      noRowsOverlay:() => (
                          <Box
                              sx={{
                                  display: "flex", height: "100%", justifyContent: "center",
                                  alignItems: "center", flexDirection: "column",
                              }}>
                              <Typography style={{fontWeight:"400", fontSize:"16px"}}>
                                  No Rows
                              </Typography>
                          </Box>
                      ),
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          disableRowSelectionOnClick
          hideFooterSelectedRowCount={true}
          filterModel={filterModel}
          onFilterModelChange={(newFilterModel) =>{ setFilterModel(newFilterModel)}}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          initialState={{
            pagination: { paginationModel: { pageSize: 25 } },
          }}
        />
        &nbsp;&nbsp;&nbsp;&nbsp;

                <div style={{width:"30%",paddingTop:"3.2%",display:(data.length>0 && showGrid?"":"none")}}>
                  <DataGrid sx={{display: "grid",borderColor:"#FFF !important"}}
                            autoHeight rows={pallets} rowSelection={true}
                            columns={subColumns} getRowId={(row) => row.id}
                            disableRowSelectionOnClick
                            onSelectionModelChange={itm => console.log(itm)}

                            slotProps={{
                              toolbar: {
                                showQuickFilter: false,
                              },
                            }}

                            hideFooterPagination={true} filterModel={filterModel}


                  />
                </div>

      </div>

    </CustomPaper>
  );
}
