import React from "react";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";

export default function CustomPaper({
  margin = 0,
  height = 0,
  padding = 0,
  children = null,
  onClick = null,
  ...prop
}) {
  const theme = useTheme();
  return (
    <Paper
      sx={{
        p: padding,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: height,
        margin: margin,
        ...prop,
        "&:hover": {
          cursor: prop.hover ? "pointer" : null,
        },
        boxShadow: theme?.shadow?.elevation_1,
      }}
      onClick={onClick}
    >
      {children}
    </Paper>
  );
}
