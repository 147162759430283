import React from "react";

import AuthRoutes from "./AuthRoutes";
function App() {
  return (
    <React.Fragment>
      <AuthRoutes />
    </React.Fragment>
  );
}

export default App;
