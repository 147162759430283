import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box} from "@mui/material";
import CardWithoutPadding from "../components/CardWithoutPadding";
import DataMain from "../components/DataTable/DataMain";
import {useLocation} from "react-router-dom";

export default function LiftMaintenance() {
  const location = useLocation();
  const liftId = location?.state?.liftId;
  return (
    <div className="col-8">
      <Box sx={{ marginTop: "50px" }} >
        <Box>
          <Grid xs={12} lg={12} xl={12}>
            <CardWithoutPadding>
              <DataMain liftId={liftId}/>
            </CardWithoutPadding>
          </Grid>
        </Box>
      </Box>
    </div>
  );
}
