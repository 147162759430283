const aws = {
    Auth: {
        region: process.env.REACT_APP_REGION, 
        identityPoolRegion: process.env.REACT_APP_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID, 
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID, 
        oauth: {
            domain: process.env.REACT_APP_AUTHORITY,
            scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
            redirectSignIn: process.env.REACT_APP_REDIRECT_URI,
            redirectSignOut: process.env.REACT_APP_REDIRECT_URI,
            responseType: "code"
          }
    },
};
export default aws;