import React, {useEffect, useRef, useState} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {DatePicker} from "@mui/x-date-pickers";
import FormControl from "@mui/material/FormControl";
import {
    Alert,
    Box, Button, InputLabel, ListItem, MenuItem, Select, Snackbar, TextField, Typography,
} from "@mui/material";
import dayjs from 'dayjs';
import KPICard from "../components/KPICard";
import DataTable from "../components/DataTable/DataTable";
import AssetCard from "../components/AssetCard";
import AssetCardActive from "../components/AssetCardActive"
import DataTableOne from "../components/DataTable/DataTableOne";
import {bellIcon, storeIcon, lostIcon, warehouseIcon, settings, selectIcon} from "../assets";
import DataTableLostPallets from "../components/DataTable/DataTableLostPallets";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {DataGrid} from "@mui/x-data-grid";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import {getAssetsSummary, getCustomerAndLocations, getLiftsList, getLiftsSummary} from "../redux/Thunk/RoutesThunk";
import {connect} from "react-redux";
import {
    getAssetAtLost,
    getAssetAtStore,
    getAssetSummaryData,
    getLostPalletConfig, getLostPalletLocConfig,
    getRouteDetails,
    getUserDetails, savePallsetLostCustomerConfig, savePallsetLostLocationConfig
} from "../components/API";
import CircleProgressBar from "../components/DataTable/CircleProgressBar";
import {Autocomplete} from "@mui/lab";
import {Constants} from "../components/utils/Constants";
import CustomSuccessMessage from "../components/DataTable/CustomSuccessMessage";
import TopHeader from "../components/TopHeader";
import {getAssetDataSummary, getEmbeddedCphDBUrl} from "../redux/Thunk/AssetsThunk";

var SELECTED_PANEL = "";
var PALLETS_AT_DC = "PALLETS_AT_DC";
var PALLETS_AT_STORE = "PALLETS_AT_STORE";
var PALLETS_AT_LOST = "PALLETS_AT_LOST";

const options = [{label: 'The Shawshank Redemption', year: 1994},
    {label: 'The Godfather', year: 1972},
    {label: 'The Godfather: Part II', year: 1974},
    {label: 'The Dark Knight', year: 2008},
    {label: '12 Angry Men', year: 1957},
    {label: "Schindler's List", year: 1993},
    {label: 'Pulp Fiction', year: 1994}];

var customers = [];
//var custLocations = [];
var custLocations1 = [];
var customerId = "";
var locationId = "";

var lCustomerId = "";
var lLocationId = "";

var customerName = "";
var locationName = "";
var defCName = "";
var defLName = "";

function AssetDashboard({getCustomerAndLocations, customerAndLocationsData, getAssetDataSummary,selectedCustomerId, selectedLocationId,assetsDataSummary, userRoleName, applicationIds}) {
    const [selectedRoutesDate, setSelectedRoutesDate] = useState(dayjs(new Date()).format("YYYY-MM-DD"));

    const [palletStore, setPalletStore] = useState(true);
    const [palletLost, setPalletLost] = useState(false);

    const [alertsOpen, setAletsOpen] = useState(false);
    const [alertsDelete, setAletsDelete] = useState(false);

    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [routeData, setRouteData] = useState([]);

    const [age, setAge] = useState("");
    const [custLastDays, setCustLastDays] = useState("hours");
    const [locLastDays, setLocLastDays] = useState("hours");
    const [custLastValue, setCustomerLastValue] = useState(30)
    const [locLastValue, setLocLastValue] = useState(30)
    const [assetSummary, setAssetSummary] = useState(null);
    const [progress, setProgress] = useState(false);
    const [tableProgress, setTableProgress] = useState(true);
    const [tableStoreData, setTableStoreData] = useState([]);

    const [isAMUser, setAMUser] = useState(false);

    const [customer, setCustomer] = useState(null);
    const [custLocation, setCustLocation] = useState(null);
    const [custLocations, setCustLocations] = useState('');


    const [lCustomer, setLCustomer] = useState(null);
    const [custlLocation, setLCustLocation] = useState(null);
    const [custlLocations, setCustlLocations] = useState('');

    const [locValue1, setLocValue1] = useState(null);
    const [locValue, setLocValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [inputValue1, setInputValue1] = useState('');

    const [isSettingsEnable, setSettingsEnable] = useState(false);

    const [custProgress, setCustProgress] = useState(false);
    const [locProgress, setLocProgress] = useState(false);

    const [getProgress, setGetProgress] = useState(false);

   // const mainLogoRef = useRef(CustomSuccessMessage)
    const [state, setState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });

    const {vertical, horizontal, open} = state;

    const [isShowMessage,setShowMessage] = useState(false);
    const [errorType,setErrorType] = useState("success");
    const [message,setMessage] = useState("");


    const [custErrors, setCustErrors] = useState({
        customer: false,
        custLastValue: false,
        custLastDays: false
    });

    const [locErrors, setLocErrors] = useState({
        location: false,
        locLastValue: false,
        locLastDays: false
    });

    const [palletErrors, setPalletErrors] = useState({
        location: false,
        customer: false,

    });

    const [isCustValueDisabled,setCustValueDisabled] = useState(true);
    const [isLocValueDisabled,setLocValueDisabled] = useState(true);

    useEffect(() => {
        SELECTED_PANEL = PALLETS_AT_STORE;
        //console.log(this.mainLogoRef);
        if (customerAndLocationsData.length === 0)
            getCustomerAndLocations();
    }, []);


    useEffect(() => {
        setProgress(false);
        console.log(customerAndLocationsData);
        if (customerAndLocationsData.length > 0) {
            getCustomerName();
            customers = customerAndLocationsData;
            const customerLocationdata = {
                customerId: selectedCustomerId,
                locationId: selectedLocationId
            }

            console.log(Constants.customerId);
            console.log(Constants.locationId);
            customerId = Constants.customerId;
            locationId = Constants.locationId;
            lCustomerId = Constants.customerId;
            lLocationId = Constants.locationId;
            getSummaryInit(customerId, locationId);
            setTimeout(() => {
                getSummaryData(customerId, locationId);
            }, 1800);
            document.addEventListener(Constants.ASSET_HANDLE_CLICK_EVENT, onClickHandleEvent);

            //console.log(customerLocationdata);
            //getLiftSummary(customerLocationdata);
            //getLiftList(customerLocationdata);
        }
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener(Constants.ASSET_HANDLE_CLICK_EVENT, onClickHandleEvent);
        }
    }, [customerAndLocationsData]);

    let getCustomerName = () => {
        var locationData = JSON.parse(JSON.stringify(customerAndLocationsData));
        let customerArray = locationData.filter((cust) => {
            return cust.customerId == selectedCustomerId;
        })

        console.log(customerArray);
        if (customerArray && customerArray.length > 0) {
            console.log(customerArray[0].customerName);
            setCustomer({customerName: customerArray[0].customerName});
            setLCustomer({customerName: customerArray[0].customerName});
            customerName = customerArray[0].customerName;
            defCName = customerName;
            let locations = customerArray[0].locations;
            if (locations.length > 0) {
                let locArray = locations.filter((loc) => {
                    return loc.locationId == selectedLocationId;
                })
                console.log(locArray);
                if (locArray && locArray.length > 0) {
                    //custLocations = locArray;
                    setCustLocations(locations);
                    setCustlLocations(locations);
                    setCustLocation({locationName: locArray[0].locationName});
                    setLCustLocation({locationName: locArray[0].locationName});
                    locationName = locArray[0].locationName;
                    defLName = locationName;
                }
            }
        }
    }
    let getSummary = async (custId, locId) => {
        try {
            let response = null;
            let totalPages = 0;
            setTableProgress(true);
            if (userRoleName == Constants.RPC_ADMIN || userRoleName == Constants.LOCATION_ADMIN || userRoleName == Constants.CUSTOMER_ADMIN) {
                setSettingsEnable(true);
            } else {
                setSettingsEnable(false);
            }
            if((userRoleName !== Constants.RPC_ADMIN || userRoleName !== Constants.RPC_READ_ONLY) && applicationIds && applicationIds.includes(6)){
                setAMUser(true);
            }else{
                setAMUser(false);
            }

            await getSummaryData(custId,locId);
            /*let data = {};
            data.customerId = custId;
            data.locationId = locId;
            let resp = await getAssetDataSummary(data);
            console.log(resp);
            if(resp && resp.error){
                showErrorMessage(resp.error.message);
            }*/
           /* response = await getAssetSummaryData(custId, locId);
            console.log(response);
            if (response && response.data) {
                setAssetSummary(response.data);
            }*/
            setProgress(true);
            onClickPalletsStore();

        } catch (ex) {
            showSuccessErrorMessage(Constants.ERROR,ex.message);
            setProgress(true);
        }
    }

    let getSummaryInit =async (custId, locId) => {
        try {
            let response = null;
            let totalPages = 0;
            setTableProgress(true);
            response = await getUserDetails();
            if (response && response.data && response.data.Data) {
                let roleName = response.data.Data.role_name;
                if (roleName == Constants.RPC_ADMIN || roleName == Constants.LOCATION_ADMIN || roleName == Constants.CUSTOMER_ADMIN) {
                    setSettingsEnable(true);
                } else {
                    setSettingsEnable(false);
                }

                let applicationIds = response.data.Data.applications;

                if((roleName !== Constants.RPC_ADMIN || roleName !== Constants.RPC_READ_ONLY) && applicationIds && applicationIds.includes(6)){
                    setAMUser(true);
                }else{
                    setAMUser(false);
                }
                //(userRoleName !== "RpcAdmin" || userRoleName !== "RpcReadOnly" && applicationIds.includes(6))
            }
            console.log(response);

            setProgress(true);
            onClickPalletsStore();

        } catch (ex) {
            showSuccessErrorMessage(Constants.ERROR,ex.message);
            setProgress(true);
        }
    }

    let getSummaryData = async(custId,locId)=>{
        try {
            let data = {};
            data.customerId = custId;
            data.locationId = locId;
            let resp = await getAssetDataSummary(data);
            console.log(resp);
            console.log(assetsDataSummary)
            if (resp && resp.error) {
                showErrorMessage(resp.error.message);
            }
        }catch (ex){
            showErrorMessage(ex.message);
        }
    }
    let showErrorMessage = (msg)=>{
        setProgress(false);
        showSuccessErrorMessage(Constants.ERROR,msg);
    }


    let onClickPalletsStore = async () => {
        let soreData = [];
        let response = null;
        let totalPages = 0;
        SELECTED_PANEL = PALLETS_AT_STORE;
        setPalletLost(false);
        setPalletStore(true);

        setTableProgress(true);
        setTableStoreData([]);
        let custId = customerId;
        let locId = locationId;
        try {
            response = await getAssetAtStore(custId, locId, 1);
            console.log(response);
            if (response && response.data && response.data.page && response.data.page.totalPages) {
                totalPages = Number(response.data.page.totalPages);
            }


            if (response && response.data && response.data.stores) {
                if (Array.isArray(response.data.stores)) {
                    soreData = response.data.stores;
                } else {
                    let obj = response.data.stores;
                    if(obj && obj['storeName']) {
                        soreData.push(response.data.stores);
                    }
                }
            }

            for (let idx = 2; idx <= totalPages; idx++) {
                response = await getAssetAtStore(custId, locId, idx);
                let tempArray = [];
                if (response && response.data && response.data.stores) {

                    if (Array.isArray(response.data.stores)) {
                        tempArray = response.data.stores;
                    } else {
                        let obj = response.data.stores;
                        if(obj && obj['storeName']){
                            tempArray.push(response.data.stores);
                        }

                    }
                }
                soreData = soreData.concat(tempArray);
            }

            soreData.forEach((item, idx) => {
                item.id = idx;
            })

            setTableStoreData(soreData);
            setTableProgress(false);
        } catch (e) {
            setTableProgress(false);
            console.log(e.message);
            showSuccessErrorMessage(Constants.ERROR,e.message);
        }
        //console.log(soreData);
    }

    let onClickPalletsLost = async () => {
        SELECTED_PANEL = PALLETS_AT_LOST;
        setPalletLost(true);
        setPalletStore(false);

        let custId = customerId;
        let locId = locationId;

        let soreData = [];
        let response = null;
        let totalPages = 0;

        setTableProgress(true);
        setTableStoreData([]);
        try {
            response = await getAssetAtLost(custId, locId, 1);
            console.log(response);
            if (response && response.data && response.data.page && response.data.page.totalPages) {
                totalPages = Number(response.data.page.totalPages);
            }
            if (response && response.data && response.data.asset) {
                if (Array.isArray(response.data.asset)) {
                    soreData = response.data.asset;
                } else {
                    let obj = response.data.asset;
                    console.log(obj);
                    if(obj && obj['rfid']){
                        soreData.push(response.data.asset);
                    }

                }
            }

            for (let idx = 2; idx <= totalPages; idx++) {
                response = await getAssetAtLost(custId, locId, idx);
                let tempArray = [];
                if (response && response.data && response.data.asset) {

                    if (Array.isArray(response.data.asset)) {
                        tempArray = response.data.asset;
                    } else {
                        let obj = response.data.asset;
                        console.log(obj);
                        if(obj && obj['rfid']) {
                            tempArray.push(response.data.asset);
                        }
                    }
                }
                soreData = soreData.concat(tempArray);
            }

            soreData.forEach((item, idx) => {
                item.id = idx;
            })

            setTableStoreData(soreData);
            setTableProgress(false);
        } catch (e) {
            setTableProgress(false);
            console.log(e.message);
            showSuccessErrorMessage(Constants.ERROR,e.message);
        }


    }

    let onClickSettings = () => {
        console.log("Settings");
        setAletsOpen(true);
        lCustomerId = selectedCustomerId;
        lLocationId = selectedLocationId;
        setLCustomer({customerName:defCName});
        setLCustLocation({locationName:defLName});
        getCustomerConfig();
    }

    let getCustomerConfig = async () => {
        try {
            setGetProgress(true);
            setCustLastDays("");
            setCustomerLastValue(0);
            setLocLastDays("");
            setLocLastValue(0);
            setCustValueDisabled(true);
            setLocValueDisabled(true);
            let obj = {};
            obj.custLastDays = false;
            obj.customer = false;
            obj.custLastValue = false;
            let flag = true;
            setCustErrors(obj);
            let response = await getLostPalletConfig(lCustomerId, lLocationId);
            console.log(response);
            let custflag = false;
            let locflag = false;
            if (response && response.data) {
                setCustLastDays(response.data.lostScrapThresholdUnit);
                setCustomerLastValue(response.data.lostScrapThresholdValue?response.data.lostScrapThresholdValue:0);

                if(response.data.lostScrapThresholdUnit && response.data.lostScrapThresholdValue){
                    custflag = true;
                }

                response = await getLostPalletLocConfig(lLocationId);

                setLocLastDays(response.data.lostScrapThresholdUnit);
                setLocLastValue(response.data.lostScrapThresholdValue?response.data.lostScrapThresholdValue:0);

                if(response.data.lostScrapThresholdUnit && response.data.lostScrapThresholdValue){
                    locflag = true;
                }
                setGetProgress(false);
            }

            setCustValueDisabled(custflag);
            setLocValueDisabled(locflag);
        } catch (ex) {
            setGetProgress(false);
            console.log(ex.message);
            showSuccessErrorMessage(Constants.ERROR,ex.message);
        }

    }

    let handleClose = () => {
        setAletsOpen(false);
        setGetProgress(false);
    }

    let handleCloseAlert = () => {
        setAletsDelete(false);
    }

    const handleCustomLastDays = (event) => {
        setCustLastDays(event.target.value);
    };

    const handleLocLastDays = (event) => {
        setLocLastDays(event.target.value);
    };

    let onClickSaveCustomer = async () => {
        console.log(lCustomerId, custLastValue, custLastDays);
        // customer:false,
        //custLastValue:false,
        //custLastDays:false
        let obj = {};
        obj.custLastDays = false;
        obj.customer = false;
        obj.custLastValue = false;
        let flag = true;
        if(custLastValue == "0"){
            flag = false;
        }
        if (!lCustomerId) {
            obj.customer = true;
            flag = false;
        }
        if (!custLastValue) {
            obj.custLastValue = true;
            flag = false;
        }
        if (!custLastDays) {
            obj.custLastDays = true;
            flag = false;
        }

        setCustErrors(obj);
        if (flag) {
            setCustProgress(true);
            let request = {};
            request.customerName = customerName;
            request.lostScrapThresholdValue = custLastValue;
            request.lostScrapThresholdUnit = custLastDays;
            try {
                let response = await savePallsetLostCustomerConfig(lCustomerId, request);
                console.log(response);
                setState({...state, open: true});
                showSuccessErrorMessage(Constants.SUCCESS,"Pallets Lost Configuration Successfully");
                setCustProgress(false);
                handleClose();
            } catch (ex) {
                console.log(ex.message);
                showSuccessErrorMessage(Constants.ERROR,ex.message);
            }

        }
    }

    let showSuccessErrorMessage = (type,msg)=>{
        setShowMessage(true);
        setMessage(msg);
        setErrorType(type);
    }
    let onClickSaveLocation = async () => {
        console.log(lLocationId, locLastDays, locLastValue);
        let obj = {};
        obj.locLastDays = false;
        obj.location = false;
        obj.locLastValue = false;
        let flag = true;
        if(locLastDays == "0"){
            obj.locLastValue = true;
            flag = false;
        }

        if (!lLocationId) {
            obj.location = true;
            flag = false;
        }
        if (!locLastValue) {
            obj.locLastValue = true;
            flag = false;
        }
        if (!locLastDays) {
            obj.locLastDays = true;
            flag = false;
        }
        setLocErrors(obj);
        if (flag) {
            setLocProgress(true);
            let request = {};
            request.customerId = lCustomerId;
            request.locationName = locationName;
            request.lostScrapThresholdValue = locLastValue;
            request.lostScrapThresholdUnit = locLastDays;
            try {
                let response = await savePallsetLostLocationConfig(lLocationId, request);
                console.log(response);
                setState({...state, open: true});
               // setShowMessage(true);
                showSuccessErrorMessage(Constants.SUCCESS,"Pallets Lost Configuration Successfully");
                setLocProgress(false);
                handleClose();
            } catch (ex) {
                console.log(ex.message);
                showSuccessErrorMessage(Constants.ERROR,ex.message);
            }

        }
    }

    let onClickHandleEvent = (event)=>{
        console.log(event);
        if(event && event.detail){
            let locData = event.detail.locData;
            if(locData && locData.locationId && locData.customerId){
                locationId = locData.locationId;
                customerId = locData.customerId;


                getAssetSummary(customerId,locationId);
               // getSummaryData(customerId,locationId);
                if(SELECTED_PANEL == PALLETS_AT_STORE){
                    onClickPalletsStore();
                }else if(SELECTED_PANEL == PALLETS_AT_LOST){
                    onClickPalletsLost();
                }
            }
        }

    }

    let getAssetSummary = async (custId, locId)=>{
        setProgress(false);
        /*let response = await getAssetSummaryData(custId, locId);
        console.log(response);
        if (response && response.data) {
            setAssetSummary(response.data);
        }*/
        await getSummaryData(custId, locId);
        setProgress(true);
    }
    const handleApply = () => {
        console.log("selectedRoutesDate", selectedRoutesDate, customerId, locationId);
        let obj = {};
        obj.customer = false;
        obj.location = false;
        let flag = true;
        if(!customerId){
            obj.customer = true;
            flag = false;
        }
        if(!locationId){
            obj.location = true;
            flag = false;
        }

        setPalletErrors(obj);

        if(flag){
            if(SELECTED_PANEL == PALLETS_AT_STORE){
                onClickPalletsStore();
            }else if(SELECTED_PANEL == PALLETS_AT_LOST){
                onClickPalletsLost();
            }
        }
    }
    const handleChangeDeliveryDate = (newValue) => {
        setSelectedRoutesDate(newValue.format("YYYY-MM-DD"));
    };


    const handleSnackbarClose = () => {
        setShowMessage(false);

    };

    let onReloadPallets = async ()=>{
        try{
            onClickPalletsLost();
            setProgress(false);
           /* var response = await getAssetSummaryData(customerId, locationId);
            console.log(response);
            if (response && response.data) {
                setAssetSummary(response.data);
            }*/
            await getAssetSummary(customerId, locationId);
            setProgress(true);
        }catch(ex){
            showSuccessErrorMessage(Constants.ERROR,ex.message);
            setProgress(true);
        }

    }

    return (
        <Box sx={{marginTop: "50px"}}>
             <CustomSuccessMessage   message={message} type={errorType} flag={isShowMessage} onHide={()=>{setShowMessage(false)}}/>
            {/*<Snackbar open={isShowMessage} autoHideDuration={2000} onClose={handleSnackbarClose}
                      anchorOrigin={{vertical:"top",horizontal:"center"}}>
                <Alert onClose={handleSnackbarClose}
                       severity="success" variant="filled" sx={{ width: '100%' }} >
                    {"Pallets Lost configuration updated successfully"}
                </Alert>
            </Snackbar>*/}
            {/* <Snackbar sx={{top: "0px"}} ContentProps={{
                sx: {top: "0px", background: "green"}
            }}
                      autoHideDuration={2000}
                      anchorOrigin={{vertical, horizontal}}
                      open={open}

                      onClose={handleSnackbarClose}
                      message="Pallets Lost configuration updated successfully"
                      key={vertical + horizontal}
            />*/}
            {alertsOpen &&
                <React.Fragment>
                    <Dialog
                        open={alertsOpen}
                        onClose={handleClose}
                        sx={{
                            "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                    width: "100%",
                                    maxWidth: "600px",  // Set your width here
                                },
                            },
                        }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title" sx={{fontSize: "24px"}}>
                            Pallets Lost Management

                            <IconButton style={{float: "right"}}>
                                <CloseIcon onClick={() => handleClose()}/>
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {getProgress ? <CircleProgressBar/> : ""}
                                <Box sx={{width: "100%"}}>
                                    <Grid container spacing={2} sx={{justifyContent: 'center', paddingTop: "10px"}}>
                                        <Grid xs={12} lg={12} xl={12}>
                                            <FormControl fullWidth>
                                                <Autocomplete disableClearable={true}
                                                    value={lCustomer}
                                                    onChange={(event, newValue) => {
                                                        if (newValue) {
                                                            // custLocations1 = newValue.locations;
                                                            let locations = newValue.locations;
                                                            setCustlLocations(locations);
                                                            lCustomerId = newValue.customerId;

                                                            customerName = newValue.customerName;
                                                            locationName = locations[0].locationName;
                                                            lLocationId = locations[0].locationId;
                                                            setLCustLocation({locationName:locationName});

                                                            getCustomerConfig();
                                                        } else {
                                                            //custLocations1 = [];
                                                            lCustomerId = "";
                                                            lLocationId = "";
                                                            customerName = "";
                                                            locationName = "";
                                                            setCustlLocations([])
                                                            setLCustLocation(null);
                                                            setLocLastValue(0);
                                                            setLocLastDays("");
                                                            setCustLastDays("");
                                                            setCustomerLastValue(0);
                                                        }
                                                        setLCustomer(newValue);
                                                       // setLCustLocation(null);
                                                        //setCustLocation(null);
                                                    }}
                                                    inputValue={inputValue1}
                                                    onInputChange={(event, newInputValue) => {
                                                        setInputValue1(newInputValue);
                                                    }}

                                                    getOptionLabel={(option) => option.customerName}
                                                    options={customers}

                                                    renderInput={(params) => <TextField {...params}
                                                                                        label="Select Customer"
                                                                                        required={true}
                                                                                        error={custErrors.customer}
                                                                                        variant="standard"
                                                                                        sx={{height: 30}}/>}
                                                />

                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{justifyContent: 'center', paddingTop: "35px"}}>
                                        <Grid xs={6} lg={6} xl={6}>
                                            <FormControl fullWidth>
                                                <TextField id="outlined-basic"
                                                           type={"number"} label="Lost Value" required={true}
                                                           error={custErrors.custLastValue} variant="standard"
                                                           value={custLastValue} disabled={isCustValueDisabled}
                                                           onChange={(e) => {
                                                               if (e.target && e.target.value.length > 5) {
                                                                   return;
                                                               }
                                                               setCustomerLastValue(e.target.value)
                                                           }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid xs={6} lg={6} xl={6}>
                                            <FormControl size="medium" variant="standard" fullWidth>
                                                <InputLabel id="demo-simple-select-label" required={true}
                                                            error={custErrors.custLastDays}>Lost
                                                    Days</InputLabel>
                                                <Select
                                                        error={custErrors.custLastDays}
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={custLastDays} disabled={isCustValueDisabled}
                                                        label="Lost Days"
                                                        onChange={handleCustomLastDays}
                                                >
                                                    <MenuItem value={'hours'}>Hours</MenuItem>
                                                    <MenuItem value={'days'}>Days</MenuItem>
                                                    <MenuItem value={'weeks'}>Weeks</MenuItem>
                                                    <MenuItem value={'months'}>Months</MenuItem>
                                                    <MenuItem value={'quarters'}>Quarters</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{justifyContent: 'end', paddingTop: "10px"}}>
                                        <Grid xs={3} lg={3} xl={3}>
                                            {custProgress ? <CircleProgressBar/> : ""}
                                        </Grid>
                                        <Grid xs={3} lg={3} xl={3}>

                                            <FormControl fullWidth>
                                                <Button variant="contained"
                                                        sx={{
                                                            backgroundColor: "#2776CC",
                                                            color: "#FFF",
                                                            height: "50px",
                                                            marginRight: "0px",
                                                            fontWeight: "500",
                                                            ':hover': {
                                                                bgcolor: '#2776CC', color: '#FFF'
                                                            }
                                                        }} onClick={()=>{
                                                            if(isCustValueDisabled) {
                                                                setCustValueDisabled(false);
                                                            }else{
                                                                onClickSaveCustomer();
                                                            }


                                                        }}>
                                                    {isCustValueDisabled?"Edit":"Save"}

                                                </Button>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{justifyContent: 'end', paddingTop: "5px"}}>
                                        <Grid xs={12} lg={12} xl={12}>
                                            <hr/>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{justifyContent: 'center', paddingTop: "10px"}}>
                                        <Grid xs={12} lg={12} xl={12}>
                                            <FormControl fullWidth>
                                                <Autocomplete disableClearable={true}
                                                    value={custlLocation}
                                                    onChange={(event, newValue) => {
                                                        setLCustLocation(newValue);
                                                        if (newValue) {
                                                            lLocationId = newValue.locationId;
                                                            locationName = newValue.locationName;

                                                            getCustomerConfig();
                                                        } else {
                                                            lLocationId = "";
                                                            locationName = "";
                                                            setLocLastValue(0);
                                                            setLocLastDays("");
                                                        }
                                                    }}
                                                    inputValue={locValue1}
                                                    onInputChange={(event, newInputValue) => {
                                                        console.log(event, newInputValue);
                                                        setLocValue1(newInputValue);
                                                    }}
                                                    id="controllable-states-demo"
                                                    getOptionLabel={(option) => option.locationName}
                                                    options={custlLocations}

                                                    renderInput={(params) => <TextField {...params} variant="standard"
                                                                                        focused={false} required={true}
                                                                                        error={locErrors.location}
                                                                                        label="Select Location"/>}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{justifyContent: 'center', paddingTop: "30px"}}>
                                        <Grid xs={6} lg={6} xl={6}>
                                            <FormControl size="medium" variant="standard" fullWidth>
                                                <TextField type={"number"} id="outlined-basic" label="Lost Value" variant="standard"
                                                           required={true} value={locLastValue} disabled={isLocValueDisabled}
                                                           error={locErrors.locLastValue}
                                                           onChange={(e) => {
                                                               if (e.target && e.target.value && e.target.value.length > 5) {
                                                                   return;
                                                               }
                                                               setLocLastValue(e.target.value)
                                                           }}/>
                                            </FormControl>
                                        </Grid>
                                        <Grid xs={6} lg={6} xl={6}>
                                            <FormControl size="medium" variant="standard" fullWidth>
                                                <InputLabel id="demo-simple-select-label" required={true}
                                                            error={locErrors.locLastDays}>Lost
                                                    Days</InputLabel>
                                                <Select disabled={isLocValueDisabled}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={locLastDays}
                                                    label="Lost Days" error={locErrors.locLastDays}
                                                    onChange={handleLocLastDays}
                                                >
                                                    <MenuItem value={'hours'}>Hours</MenuItem>
                                                    <MenuItem value={'days'}>Days</MenuItem>
                                                    <MenuItem value={'weeks'}>Weeks</MenuItem>
                                                    <MenuItem value={'months'}>Months</MenuItem>
                                                    <MenuItem value={'quarters'}>Quarters</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{justifyContent: 'end', paddingTop: "10px"}}>
                                        <Grid xs={3} lg={3} xl={3}>
                                            {locProgress ? <CircleProgressBar/> : ""}
                                        </Grid>
                                        <Grid xs={3} lg={3} xl={3}>
                                            <FormControl fullWidth>
                                                <Button variant="contained"
                                                        sx={{
                                                            backgroundColor: "#2776CC",
                                                            color: "#FFF",
                                                            height: "50px",
                                                            marginRight: "0px",
                                                            fontWeight: "500",
                                                            ':hover': {
                                                                bgcolor: '#2776CC', color: '#FFF'
                                                            }
                                                        }} onClick={()=> {
                                                                        if(isLocValueDisabled){
                                                                            setLocValueDisabled(false);
                                                                        }else{
                                                                            onClickSaveLocation();
                                                                        }
                                                                    }
                                                        }>
                                                    {isLocValueDisabled?"Edit":"Save"}

                                                </Button>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>


                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                </React.Fragment>
            }
            <Box>
                <TopHeader  setSelectedCheckboxes = {setSelectedCheckboxes} routeData= {routeData} setRouteData={setRouteData}/>
                <Grid container spacing={2} sx={{justifyContent: 'flex-end',display:"none"}}>
                    <Grid xs={3} lg={3} xl={3} sx={{display: (palletStore ? "" : "")}}>
                        <FormControl fullWidth size="medium" variant="standard">
                            <Autocomplete
                                value={customer}
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        // custLocations = newValue.locations;
                                        setCustLocations(newValue.locations);
                                        // selectedCustomerId = newValue.customerId;
                                        customerId = newValue.customerId;

                                    } else {
                                        // custLocations = [];
                                        setCustLocations([]);
                                        customerId = "";
                                        locationId = "";
                                    }
                                    setCustomer(newValue);
                                    setCustLocation(null);

                                }}
                                inputValue={inputValue}
                                onInputChange={(event, newInputValue) => {
                                    setInputValue(newInputValue);
                                }}

                                getOptionLabel={(option) => option.customerName}
                                options={customers}
                                sx={{width: 300}}
                                renderInput={(params) => <TextField {...params} label="Select Customer"
                                                                    variant="standard" required={true} error={palletErrors.customer}
                                                                    sx={{height: 30}}/>}
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs={3} lg={3} xl={3} sx={{display: (palletStore ? "" : "")}}>
                        <FormControl fullWidth size="medium" variant="standard">
                            <Autocomplete
                                value={custLocation}
                                onChange={(event, newValue) => {
                                    setCustLocation(newValue);
                                    if (newValue) {
                                        locationId = newValue.locationId;
                                    } else {
                                        locationId = "";
                                    }

                                    // console.log(newValue);
                                }}
                                inputValue={locValue}
                                onInputChange={(event, newInputValue) => {
                                    console.log(event, newInputValue);
                                    setLocValue(newInputValue);
                                }}
                                id="controllable-states-demo"
                                getOptionLabel={(option) => option.locationName}
                                options={custLocations}
                                sx={{width: 300}}
                                renderInput={(params) => <TextField {...params} variant="standard"
                                                                    label="Select Location" required={true} error={palletErrors.location}/>}
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs={3} lg={3} xl={3}>
                        <FormControl size="medium" variant="standard" fullWidth>
                            <DatePicker
                                defaultValue={dayjs(new Date())}
                                label="Select Date"
                                format="MMMM D, YYYY"
                                onChange={handleChangeDeliveryDate}
                                slotProps={{textField: {size: "small"}}}
                                maxDate={dayjs(new Date())}
                                sx={{
                                    marginTop: "9px",
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            border: "none",
                                            borderBottom: "1px solid",
                                            borderRadius: "0px",
                                        },
                                    }
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs={2} lg={2} xl={2} sx={{marginTop: "10px"}}>
                        <Button fullWidth variant="outlined"
                                onClick={handleApply}
                                disabled={!selectedRoutesDate}>
                            Apply
                        </Button>
                    </Grid>
                    <Grid xs={2} lg={1} xl={0} sx={{marginTop: "10px", paddingRight: "45px", display: "none"}}>
                        <a href="#"> <img src={bellIcon}/> </a>
                    </Grid>
                </Grid>
                <Box sx={{marginTop: "20px"}}>
                    <Grid container spacing={2}>
                        <Grid container spacing={2} xs={12} lg={12} xl={12}>
                            <Grid xs={12} lg={4} xl={4}>
                                <AssetCard>
                                    {!progress ? <CircleProgressBar/> : (
                                        <Box sx={{mb: 1}}>
                                            <Box className="d-flex align-items-center kpi-title w-100">
                                                <Box className="a-icon"><img src={warehouseIcon}/></Box>
                                                <Box className="a-title text-primary">{assetsDataSummary?.atDc || '0'}
                                                    <span className="a-title-sub">Pallets at DC</span></Box>
                                            </Box>
                                        </Box>

                                    )}

                                </AssetCard>
                            </Grid>
                            <Grid xs={12} lg={4} xl={4}>
                                <div onClick={onClickPalletsStore}>
                                    <AssetCardActive selected={palletStore}>
                                        {!progress ? <CircleProgressBar/> : (
                                            <Box sx={{mb: 1}}>
                                                <Box className="d-flex align-items-center kpi-title w-100">
                                                    <Box className="a-icon"><img src={storeIcon}/></Box>
                                                    <Box className="a-title text-primary">{assetsDataSummary?.atStores || '0'}
                                                        <span className="a-title-sub">Pallets on Routes</span></Box>
                                                </Box>
                                            </Box>
                                        )}

                                    </AssetCardActive>
                                </div>
                            </Grid>
                            <Grid xs={12} lg={4} xl={4}>
                                <div onClick={onClickPalletsLost}>
                                    <AssetCardActive selected={palletLost}>
                                        {!progress ? <CircleProgressBar/> : (
                                            <Box sx={{mb: 1}} className="d-flex align-items-center kpi-title w-100">
                                                <Box className="d-flex align-items-center kpi-title w-100">
                                                    <Box className="a-icon"><img src={lostIcon}/></Box>
                                                    <Box className="a-title text-primary">{assetsDataSummary?.lost || '0'}
                                                        <span className="a-title-sub">Pallets Lost</span></Box>
                                                </Box>
                                                {
                                                    isSettingsEnable ? <Box className="a-icon" sx={{marginRight:"0px",cursor:"pointer",marginBottom:"-20px",alignSelf:"end"}}><img src={settings}
                                                                                                    onClick={onClickSettings}/></Box> : ""
                                                }
                                            </Box>
                                        )}
                                    </AssetCardActive>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    sx={{
                        marginTop: "30px",
                    }}
                >
                    <Grid container>
                        <Grid xs={12} lg={12} xl={12}>
                            {
                                palletStore ? <DataTableOne data={tableStoreData} progress={tableProgress} amuser={isAMUser} /> :
                                    <DataTableLostPallets data={tableStoreData} progress={tableProgress}
                                                          onReload={onReloadPallets} onReloadLostPallets={onClickPalletsLost}/>
                            }

                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
}

const mapStateToProps = (state) => {
    return {
        selectedCustomerId: state?.Routes?.selectedCustomer,
        selectedLocationId: state?.Routes?.selectedLocation,
        customerAndLocationsData: state?.Routes?.customerAndLocationsData,
        assetsDataSummary:state?.Assets?.assetsDataSummary,
        userRoleName: state?.Routes?.userRoleName,
        applicationIds: state?.Routes?.applicationIds,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCustomerAndLocations: () => dispatch(getCustomerAndLocations()),
        getAssetDataSummary: (data) => dispatch(getAssetDataSummary(data)),

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AssetDashboard);