import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Box, Grid, FormControl, InputLabel, Select, Button, MenuItem, TextField, CircularProgress } from "@mui/material"
import dayjs from 'dayjs';
import { Constants } from "../components/utils/Constants";
import { getRoutesList, getRoutesSummary, getAssetsSummary, getLiftsSummary, getCustomerAndLocations, customerUpdate, locationUpdate, getLiftsList } from "../redux/Thunk/RoutesThunk";
import { DatePicker } from "@mui/x-date-pickers";
import { useLocation } from "react-router-dom";

function TopHeader({ selectedCustomerListData,
    selectedLocationListData,
    updateSelectedLocation,
    getRouteList,
    getRouteSummary,
    getAssetSummary,
    getLiftSummary,
    updateSelectedCustomer,
    customerAndLocationsData,
    getLiftList,
    customerAndLocationsDataLoad,
    userRoleName,
    setSelectedCheckboxes,
    setRouteData }) {
    let location = useLocation();
    const [selectedRoutesDate, setSelectedRoutesDate] = useState(dayjs(new Date()).format("YYYY-MM-DD"));
    const [selectDate, setSelectDate] = useState(Constants.SELECT_DATE ? dayjs(Constants.SELECT_DATE) : dayjs(new Date()));//.format("YYYY-MM-DD"));

    useEffect(() => {
        if (customerAndLocationsData.length > 0) {
            let sDate = null;
            if (Constants.SELECT_DATE) {
                sDate = Constants.SELECT_DATE.format("YYYY-MM-DD");
                localStorage.setItem("routeDate", sDate)
            } else {
                sDate = selectedRoutesDate;
                localStorage.setItem("routeDate", sDate)
            }
            const customerLocationAndDate = {
                date: sDate,
                locationId: selectedLocationListData.locationId
            }
            const customerLocationdata = {
                customerId: selectedCustomerListData.customerId,
                locationId: selectedLocationListData.locationId
            }
            if (location.pathname === "/dashboard") {
                getRouteList(customerLocationAndDate);
                getRouteSummary(customerLocationAndDate);
                getAssetSummary(customerLocationdata);
                getLiftSummary(customerLocationdata);
            }
            if (location.pathname === "/liftdashboard") {
                getLiftSummary(customerLocationdata);
                getLiftList(customerLocationdata);
            }
            if (location.pathname === "/assetdashboard") {
                console.log(customerLocationdata, customerLocationAndDate);
                Constants.customerId = selectedCustomerListData.customerId;
                Constants.locationId = selectedLocationListData.locationId;
                dispatchHandleEvent(customerLocationdata, customerLocationAndDate);
            }
        }
    }, [customerAndLocationsData]);

    let dispatchHandleEvent = (locData, locDate) => {
        let obj = { locData: locData, locDate: locDate };
        const event = new CustomEvent(Constants.ASSET_HANDLE_CLICK_EVENT, { detail: obj });
        document.dispatchEvent(event);
    }

    //dayjs(new Date());
    const handleApply = () => {
        setSelectedCheckboxes([]);
        setRouteData([]);
        let sDate = null;
        if (Constants.SELECT_DATE) {
            sDate = Constants.SELECT_DATE.format("YYYY-MM-DD");
        } else {
            sDate = selectedRoutesDate;
        }
        const customerLocationAndDate = {
            date: sDate,
            locationId: selectedLocationListData.locationId
        }
        const customerLocationdata = {
            customerId: selectedCustomerListData.customerId,
            locationId: selectedLocationListData.locationId
        }
        if (location.pathname === "/dashboard") {
            getRouteList(customerLocationAndDate);
            getRouteSummary(customerLocationAndDate);
            getAssetSummary(customerLocationdata);
            getLiftSummary(customerLocationdata);
        }
        if (location.pathname === "/liftdashboard") {
            getLiftSummary(customerLocationdata);
            getLiftList(customerLocationdata);
        }
        if (location.pathname === "/assetdashboard") {
            console.log(customerLocationdata, customerLocationAndDate);
            dispatchHandleEvent(customerLocationdata, customerLocationAndDate);
        }
    }

    const handleChangeDeliveryDate = (newValue) => {
        //selectDate = newValue;
        setSelectDate(newValue);
        Constants.SELECT_DATE = newValue;
        setSelectedRoutesDate(newValue?.format("YYYY-MM-DD"));
        localStorage.setItem("routeDate", newValue?.format("YYYY-MM-DD"))
    };

    const handleCustomerChange = (event) => {
        updateSelectedCustomer(event.target.value)
    }

    const handleLocationChange = (event) => {
        updateSelectedLocation(event.target.value)
    }

    return (
        <>
            {customerAndLocationsDataLoad ?
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "40px",
                        marginBottom: "10px",
                    }}
                >
                    <CircularProgress />
                </Box> :
                <Grid container spacing={2}>
                    {customerAndLocationsData && customerAndLocationsData?.length > 1 ?
                        <Grid item xs={3} lg={3} xl={3}>
                            <FormControl
                                size="medium"
                                variant="standard"
                                fullWidth
                            // disabled={distributionCentersData?.length === 0}
                            >
                                <InputLabel id="select-label">Select Customer</InputLabel>
                                <Select
                                    labelId="select-label"
                                    id="select-demo"
                                    value={selectedCustomerListData && selectedCustomerListData || ''}
                                    label="Customer"
                                    onChange={handleCustomerChange}
                                >
                                    {customerAndLocationsData && customerAndLocationsData.map((item) => (
                                        <MenuItem key={item.customerId} value={item}>
                                            {item.customerName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid> :
                        userRoleName === "RpcAdmin" || userRoleName === "RpcReadOnly" ?
                            <Grid item xs={3} lg={3} xl={3}>
                                <TextField
                                    id="standard-helperText"
                                    label="Customer"
                                    value={selectedCustomerListData && selectedCustomerListData?.customerName}
                                    variant="standard"
                                    fullWidth
                                />
                            </Grid> :
                            <Grid item xs={3} lg={3} xl={3}>
                            </Grid>
                    }
                    {selectedCustomerListData && selectedCustomerListData?.locations?.length > 1 ?
                        <Grid item xs={3} lg={3} xl={3}>
                            <FormControl
                                size="medium"
                                variant="standard"
                                fullWidth
                            // disabled={distributionCentersData?.length === 0}
                            >
                                <InputLabel id="select-label">Select Location</InputLabel>
                                <Select
                                    labelId="select-label"
                                    id="select-demo"
                                    value={selectedLocationListData || ''}
                                    label="Customer"
                                    onChange={handleLocationChange}
                                >
                                    {selectedCustomerListData && selectedCustomerListData.locations.map((item) => (
                                        <MenuItem key={item.locationId} value={item}>
                                            {item.locationName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        :
                        userRoleName === "RpcAdmin" || userRoleName === "RpcReadOnly" ?
                            <Grid item xs={3} lg={3} xl={3}>
                                <TextField
                                    id="standard-helperText"
                                    label="Location"
                                    value={selectedCustomerListData && selectedCustomerListData?.locations[0]?.locationName}
                                    variant="standard"
                                    fullWidth
                                />
                            </Grid> :
                            <Grid item xs={3} lg={3} xl={3}></Grid>
                    }
                    {location.pathname === "/liftdashboard" ?
                        <Grid item xs={3} lg={3} xl={3}></Grid> :
                        <Grid item xs={3} lg={3} xl={3}>
                            <FormControl size="medium" variant="standard" fullWidth>
                                <DatePicker
                                    defaultValue={selectDate}
                                    views={['year', 'month', 'day']}
                                    label="Select Date"
                                    format="MMMM D, YYYY"
                                    onChange={handleChangeDeliveryDate}
                                    slotProps={{ textField: { size: "small" } }}
                                    maxDate={dayjs(new Date())}
                                    sx={{
                                        marginTop: "9px",
                                        "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                                border: "none",
                                                borderBottom: "1px solid",
                                                borderRadius: "0px",
                                            },
                                        }
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    }
                    <Grid item xs={3} lg={3} xl={3} sx={{ marginTop: "10px", display: "flex", justifyContent: 'flex-end' }}>
                        <Button fullWidth variant="outlined" sx={{ width: "180px" }}
                            onClick={handleApply}
                            disabled={!selectedRoutesDate}>
                            Apply
                        </Button>
                    </Grid>
                </Grid>
            }
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        selectedCustomerId: state?.Routes?.selectedCustomer,
        selectedLocationId: state?.Routes?.selectedLocation,
        customerAndLocationsDataLoad: state?.Routes?.customerAndLocationsDataLoad,
        customerAndLocationsData: state?.Routes?.customerAndLocationsData,
        loadingCustomersDataFromProps: state?.Routes?.listofRoutesDataLoad,
        customersDataFromProps: state?.Routes?.listofRoutesData,
        loadingRoutesSummary: state?.Routes?.routesSummaryDataLoad,
        routesSummaryData: state?.Routes?.routesSummaryData,
        loadingAssetSummary: state?.Routes?.assetsSummaryDataLoad,
        assetsSummaryData: state?.Routes?.assetsSummaryData,
        loadingLiftSummary: state?.Routes?.liftSummaryDataLoad,
        liftsSummaryData: state?.Routes?.liftSummaryData,
        selectedLocationsList: state?.Routes?.selectedLocationsList,
        selectedCustomerListData: state?.Routes?.selectedCustomerListData,
        selectedLocationListData: state?.Routes?.selectedLocationListData,
        applicationIds: state?.Routes?.applicationIds,
        applicationIdsLoad: state?.Routes?.applicationIdsLoad,
        userRoleName: state?.Routes?.userRoleName
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCustomerAndLocations: () => dispatch(getCustomerAndLocations()),
        getRouteList: (customerLocationAndDate) => dispatch(getRoutesList(customerLocationAndDate)),
        getRouteSummary: (customerLocationAndDate) => dispatch(getRoutesSummary(customerLocationAndDate)),
        getAssetSummary: (customerLocationdata) => dispatch(getAssetsSummary(customerLocationdata)),
        getLiftSummary: (customerLocationdata) => dispatch(getLiftsSummary(customerLocationdata)),
        updateSelectedCustomer: (data) => dispatch(customerUpdate(data)),
        updateSelectedLocation: (data) => dispatch(locationUpdate(data)),
        getLiftList: (customerLocationdata) => dispatch(getLiftsList(customerLocationdata)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopHeader);