import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Auth } from "aws-amplify";
import Cookies from 'js-cookie';
import {
  setListofRoutes,
  setRouteStartTime,
  setRouteDCTime,
  setLoadingListofRoutesData,
  setRoutesSummary,
  setLoadingRoutesSummaryData,
  setLoadingAssetSummaryData,
  setAssetsSummary,
  setLoadingLiftSummaryData,
  setLiftsSummary,
  setLoadingCustomerAndLocationsData,
  setCustomerAndLocations,
  setLoadingLiftListData,
  setLiftsList,
  setUpdateStatus,
  setLoadingRouteAlertsData,
  setRouteAlertsList,
  setLoadingLocationAlertsData,
  setLocationAlertsList,
  setLoadingLiftInfoData,
  setLiftInfoData,
  setLoadingLiftAlertData,
  setLiftAlertData,
  setLiftAlertPageData,
  setLoadingLiftMaintenanceData,
  setLiftMaintenanceData,
  setLoadingLiftTasksList,
  setLiftTasksList,
  setLoadingLiftPartsList,
  setLiftPartsList,
  setAddMaintenanceStatus,
  setSelectedCustomer,
  setSelectedLocation,
  setApplicationIdsLoad,
  setApplicationIds,
  setUserRoleName,
  setUpdatePinStatus,
  setLoadingFirstRouteDetails,
  setLoadingFinalRouteDetails,
  setRouteDetailsData,
  setLoadingHeartBeats,
  setHeartBeatsData
} from "../reducers/Routes/RoutesReducer";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const VOR_API_ENDPOINT = process.env.REACT_APP_CENTRAL_ADMIN_API;


export const getToken = async () => {
  try {
    const authTokenValue = Cookies.get('authToken');
    if(authTokenValue){
      return authTokenValue
    }else{
      const session = await Auth.currentSession();
      return session.accessToken.jwtToken;
    }
   
  } catch {
    return null;
  }
};

export const getCustomerID = async () => {
  try {
    const loggedInUserId = Cookies.get('loggedInUserId');
    if(loggedInUserId){
      return loggedInUserId
    }else{
      const getCurrentUserInfo = await Auth.currentUserInfo();
      return getCurrentUserInfo.attributes[`custom:central_user_id`];
    }
  } catch {
    return null;
  }
};

//api call to fetch customers & locations
export const getCustomerAndLocations = createAsyncThunk(
  "RoutesThunk/getCustomerAndLocations",
  async (data, { dispatch }) => {
    const headers = {
      Authorization: "Bearer " + await getToken()
    };
    try {
      dispatch(setLoadingCustomerAndLocationsData(true));
      let response = await axios.get(`${API_ENDPOINT}/v2/admin/locations`,
        {
          headers: headers
        });
      dispatch(setLoadingCustomerAndLocationsData(false));
      dispatch(setCustomerAndLocations(response.data));
    } catch (error) {
      console.error("API error:", error);
    }
  }
);

//api call to fetch List of Routes
export const getRoutesList = createAsyncThunk(
  "RoutesThunk/getRoutesList",
  async (data, { dispatch }) => {
    const headers = {
      Authorization: "Bearer " + await getToken()
    };
    try {
      dispatch(setLoadingListofRoutesData(true));
      let response = await axios.get(`${API_ENDPOINT}/v2/routes/routesStatus?locationId=${data.locationId}&date=${data.date}`,
        {
          headers: headers
        });
      dispatch(setLoadingListofRoutesData(false));
      dispatch(setListofRoutes(response.data));
      dispatch(setRouteStartTime(response.data))
      dispatch(setRouteDCTime(response.data))
    } catch (error) {
      console.error("API error:", error);
    }
  }
);

//api call to fetch Routes & Cases Summary
export const getRoutesSummary = createAsyncThunk(
  "RoutesThunk/getRoutesSummary",
  async (data, { dispatch }) => {
    const headers = {
      Authorization: "Bearer " + await getToken()
    };
    try {
      dispatch(setLoadingRoutesSummaryData(true));
      let response = await axios.get(`${API_ENDPOINT}/v2/routes/summary?locationId=${data.locationId}&date=${data.date}`,
        {
          headers: headers
        });
      dispatch(setLoadingRoutesSummaryData(false));
      dispatch(setRoutesSummary(response.data));
    } catch (error) {
      console.error("API error:", error);
    }
  }
);

//api call to fetch asset Summary
export const getAssetsSummary = createAsyncThunk(
  "RoutesThunk/getAssetsSummary",
  async (data, { dispatch }) => {
    const headers = {
      Authorization: "Bearer " + await getToken()
    };
    try {
      dispatch(setLoadingAssetSummaryData(true));
      let response = await axios.get(`${API_ENDPOINT}/v2/assets/summary?locationId=${data.locationId}&customerId=${data.customerId}`,
        {
          headers: headers
        });
      dispatch(setLoadingAssetSummaryData(false));
      dispatch(setAssetsSummary(response.data));
    } catch (error) {
      console.error("API error:", error);
    }
  }
);

//api call to fetch Lift Summary
export const getLiftsSummary = createAsyncThunk(
  "RoutesThunk/getLiftsSummary",
  async (data, { dispatch }) => {
    const headers = {
      Authorization: "Bearer " + await getToken()
    };
    try {
      dispatch(setLoadingLiftSummaryData(true));
      let response = await axios.get(`${API_ENDPOINT}/v2/lifts/summary?locationId=${data.locationId}&customerId=${data.customerId}`,
        {
          headers: headers
        });
      dispatch(setLoadingLiftSummaryData(false));
      dispatch(setLiftsSummary(response.data));
    } catch (error) {
      console.error("API error:", error);
    }
  }
);

//api call to fetch Lift list
export const getLiftsList = createAsyncThunk(
  "RoutesThunk/getLiftsList",
  async (data, { dispatch }) => {
    const headers = {
      Authorization: "Bearer " + await getToken()
    };
    try {
      dispatch(setLoadingLiftListData(true));
      let response = await axios.get(`${API_ENDPOINT}/v2/lifts?locationId=${data.locationId}&customerId=${data.customerId}`,
        {
          headers: headers
        });
      dispatch(setLoadingLiftListData(false));
      dispatch(setLiftsList(response.data.lifts));
    } catch (error) {
      console.error("API error:", error);
    }
  }
);

export const updateLiftStatus = createAsyncThunk(
  "RoutesThunk/updateLiftStatus",
  async (data, { dispatch }) => {
    const headers = {
      Authorization: "Bearer " + await getToken(),
      'Content-Type': 'application/json',
    };
    try {
      dispatch(setUpdateStatus(false));
      let response = await fetch(`${API_ENDPOINT}/v2/lifts/${data.liftId}`, {
        method: "PUT",
        headers: headers,
        body: JSON.stringify({ "status": data.status }),
      });
      await response.json();
      dispatch(setUpdateStatus(true));
    } catch (error) {
      console.error("API error:", error);
    }
  }
);


//api call to fetch alerts for route
export const getAlertsForRoute = createAsyncThunk(
  "RoutesThunk/getAlertsForRoute",
  async (data, { dispatch }) => {
    const headers = {
      Authorization: "Bearer " + await getToken()
    };
    try {
      dispatch(setLoadingRouteAlertsData(true));
      let response = await axios.get(`${API_ENDPOINT}/v2/alerts/route/${data}`,
        {
          headers: headers
        });
      dispatch(setLoadingRouteAlertsData(false));
      dispatch(setRouteAlertsList(response.data));
    } catch (error) {
      console.error("API error:", error);
    }
  }
);


//api call to fetch location alerts
export const getAlertsForLocation = createAsyncThunk(
  "RoutesThunk/getAlertsForLocation",
  async (data, { dispatch }) => {
    const headers = {
      Authorization: "Bearer " + await getToken()
    };
    try {
      dispatch(setLoadingLocationAlertsData(true));
      let response = await axios.get(`${API_ENDPOINT}/v2/alerts/location?locationId=${data.locationId}&customerId=${data.customerId}&date=${data.date}`,
        {
          headers: headers
        });
      dispatch(setLoadingLocationAlertsData(false));
      if(response.data && response.data.alerts){
          dispatch(setLocationAlertsList(response.data.alerts));
      }else{
          dispatch(setLocationAlertsList([]));
      }

    } catch (error) {
      console.error("API error:", error);
    }
  }
);

//api call to fetch lift info
export const getLiftInfo = createAsyncThunk(
  "RoutesThunk/getLiftInfo",
  async (data, { dispatch }) => {
    const headers = {
      Authorization: "Bearer " + await getToken()
    };
    try {
      dispatch(setLoadingLiftInfoData(true));
      let response = await axios.get(`${API_ENDPOINT}/v2/lifts/${data}`,
        {
          headers: headers
        });
      dispatch(setLoadingLiftInfoData(false));
      dispatch(setLiftInfoData(response.data));
    } catch (error) {
      console.error("API error:", error);
    }
  }
);

//api call to fetch lift alert info
export const getLiftAlertInfo = createAsyncThunk(
  "RoutesThunk/getLiftAlertInfo",
  async (data, { dispatch }) => {
    const headers = {
      Authorization: "Bearer " + await getToken()
    };
    try {
      dispatch(setLoadingLiftAlertData(true));
        dispatch(setLiftAlertData([]));
        dispatch(setLiftAlertPageData(""))
      let response = await axios.get(`${API_ENDPOINT}/v2/liftAlerts/lift/${data.liftId}?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`,
        {
          headers: headers
        });

      let records = [];
      let tempArray = [];
      if(response && response.data && response.data.alerts){
          if(Array.isArray(response.data.alerts)){
              records = response.data.alerts;
          }else{
              records.push(response.data.alerts);
          }
          if(response.data.page && response.data.page.totalPages){
              let pages = Number(response.data.page.totalPages);
              for(let idx=2;idx<=pages;idx++){
                  tempArray = [];
                  response = await axios.get(`${API_ENDPOINT}/v2/liftAlerts/lift/${data.liftId}?pageNumber=${idx}&pageSize=${data.pageSize}`,
                      {
                          headers: headers
                      });

                  if(response && response.data && response.data.alerts) {
                      if (Array.isArray(response.data.alerts)) {
                          tempArray = response.data.alerts;
                      } else {
                          tempArray.push(response.data.alerts);
                      }
                  }

                  records = records.concat(tempArray);
              }
          }
      }
     // console.log(records);
        records.forEach((item,idx)=>{
            item.idx = idx;
        });
      dispatch(setLoadingLiftAlertData(false));
      dispatch(setLiftAlertData(records));
      dispatch(setLiftAlertPageData(response.data.page))
    } catch (error) {
      console.error("API error:", error);
    }
  }
);


//api call to fetch lift maintenance info
export const getLiftMaintenanceInfo = createAsyncThunk(
  "RoutesThunk/getLiftMaintenanceInfo",
  async (data, { dispatch }) => {
    const headers = {
      Authorization: "Bearer " + await getToken()
    };
    try {
      dispatch(setLoadingLiftMaintenanceData(true));
      let response = await axios.get(`${API_ENDPOINT}/v2/maintenance/lift/${data}/history`,
        {
          headers: headers
        });
      dispatch(setLoadingLiftMaintenanceData(false));
      dispatch(setLiftMaintenanceData(response.data.history));
    } catch (error) {
      console.error("API error:", error);
    }
  }
);

//api call to fetch Maintenance Types list
export const getMaintenanceType = createAsyncThunk(
  "RoutesThunk/getMaintenanceType",
  async (data, { dispatch }) => {
    const headers = {
      Authorization: "Bearer " + await getToken()
    };
    try {
      dispatch(setLoadingLiftTasksList(true));
      let response = await axios.get(`${API_ENDPOINT}/v2/maintenance/tasks`,
        {
          headers: headers
        });
      dispatch(setLoadingLiftTasksList(false));
      dispatch(setLiftTasksList(response.data.tasks));
    } catch (error) {
      console.error("API error:", error);
    }
  }
);

//api call to fetch maintenance Replaceable Items
export const getReplaceableItems = createAsyncThunk(
  "RoutesThunk/getReplaceableItems",
  async (data, { dispatch }) => {
    const headers = {
      Authorization: "Bearer " + await getToken()
    };
    try {
      dispatch(setLoadingLiftPartsList(true));
      let response = await axios.get(`${API_ENDPOINT}/v2/maintenance/parts`,
        {
          headers: headers
        });
      dispatch(setLoadingLiftPartsList(false));
      dispatch(setLiftPartsList(response.data.parts));
    } catch (error) {
      console.error("API error:", error);
    }
  }
);

//api call to submit maintenance record
export const postMaintenanceData = createAsyncThunk(
  "RoutesThunk/postMaintenanceData",
  async (data, { dispatch }) => {
    const config = {
      method: 'post',
      url: `${API_ENDPOINT}/v2/maintenance/lift/${data.liftId}`,
      headers: {
        'Authorization': "Bearer " + await getToken(),
        'Content-Type': 'application/json'
      },
      data: data
    };
    try {
      dispatch(setAddMaintenanceStatus(false));
      const response = await axios(config)
        .then(function (response) {
          dispatch(setAddMaintenanceStatus(true));
          console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log(error);
        });
      return response
    } catch (error) {
      console.error("API error:", error);
    }
  }
);

export const updateAddMaintenanceStatus = createAsyncThunk(
  "RoutesThunk/updateAddMaintenanceStatus",
  async (data, { dispatch }) => {
    dispatch(setAddMaintenanceStatus(false));
  }
);

//update selected Customer
export const customerUpdate = createAsyncThunk(
  "RoutesThunk/customerUpdate",
  async (data, { dispatch }) => {
    dispatch(setSelectedCustomer(data));
  }
);

//update selected location
export const locationUpdate = createAsyncThunk(
  "RoutesThunk/locationUpdate",
  async (data, { dispatch }) => {
    dispatch(setSelectedLocation(data));
  }
);

//to fetch user role from central admin
export const getUserRole = createAsyncThunk(
  "RoutesThunk/getUserRole",
  async (data, { dispatch }) => {
    const headers = {
      Authorization: await getToken()
    };
    const userID = await getCustomerID()
    try {
      dispatch(setApplicationIdsLoad(false))
      let response = await axios.get(`${VOR_API_ENDPOINT}Central/user/${userID}`,
        {
          headers: headers
        });
      dispatch(setApplicationIdsLoad(true))
      dispatch(setUserRoleName(response.data.Data.role_name))
      dispatch(setApplicationIds(response.data.Data.applications));
    } catch (error) {
      console.error("API error:", error);
    }
  }
);


//update pin location
export const updatePinLocation = createAsyncThunk(
  "RoutesThunk/updatePinLocation",
  async (data, { dispatch }) => {
    console.log("data......",data)
    const headers = {
      Authorization: "Bearer " + await getToken(),
      'Content-Type': 'application/json',
    };
    try {
      dispatch(setUpdatePinStatus(false));
      let response = await fetch(`${API_ENDPOINT}/v2/routes/updateStop`, {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(data),
      });
      await response.json();
      dispatch(setUpdatePinStatus(true));
    } catch (error) {
      console.error("API error:", error);
    }
  }
);

//api call to fetch route details
export const getRouteDetailsData = createAsyncThunk(
  "RoutesThunk/getRouteDetailsData",
  async (data, { dispatch }) => {
    const headers = {
      Authorization: "Bearer " + await getToken()
    };
    try {
      dispatch(setLoadingFirstRouteDetails(true));
      dispatch(setLoadingFinalRouteDetails(true));
      const apiPromises = data.map(route =>
        axios.get(`${API_ENDPOINT}/v2/routes/${route.routeId}`,
        {
          headers: headers
        })
      );

      const firstCompleted = await Promise.race(apiPromises);
      const firstCompletedData = firstCompleted.data; 
      dispatch(setLoadingFirstRouteDetails(false));
      dispatch(setRouteDetailsData(firstCompletedData));  
      const finalResponce = await Promise.all(apiPromises);
      dispatch(setRouteDetailsData(finalResponce));  
      if(finalResponce){
        dispatch(setLoadingFinalRouteDetails(false));
      }
    } 
    catch (error) {
      console.error("API error:", error);
    } 
  }
);

//api call to fetch heart beats
export const getHeartBeatsData = createAsyncThunk(
  "RoutesThunk/getHeartBeatsData",
  async (data, { dispatch }) => {
    const headers = {
      Authorization: "Bearer " + await getToken()
    };
    try {
      dispatch(setLoadingHeartBeats(true));
      const apiPromises = data.map(route =>
        axios.get(`${API_ENDPOINT}/v2/routes/${route.routeId}/heartbeats`,
        {
          headers: headers
        })
      );
      const finalResponce = await Promise.all(apiPromises);
      dispatch(setHeartBeatsData(finalResponce));  
      if(finalResponce){
        dispatch(setLoadingHeartBeats(false));
      }
    } 
    catch (error) {
      console.error("API error:", error);
    } 
  }
);