export class Constants {
    static ROUTE_SELECTION_EVENT = "ROUTE_SELECTION_EVENT";
    static ROUTE_DETAILS_BACK_EVENT = "ROUTE_DETAILS_BACK_EVENT";
    static ASSET_HANDLE_CLICK_EVENT = "ASSET_HANDLE_CLICK_EVENT";
    static SELECT_DATE = null;
    static ROUTE_IN_PROGRESS = "In progress";
    static ROUTE_IN_COMPLETED = "Completed";
    static RPC_ADMIN = "RpcAdmin";
    static RPC_READ_ONLY = "RpcReadOnly";
    static CUSTOMER_ADMIN = "CustomerAdmin";
    static LOCATION_ADMIN = "LocationAdmin";
    static LIFT_MAINTENANCE_EVENT = "LIFT_MAINTENANCE_EVENT";
    static LIFT_ADD_MAINTENANCE_EVENT = "LIFT_ADD_MAINTENANCE_EVENT";

    static SUCCESS = "success";
    static ERROR = "error";

    static locationId = ""
    static customerId = "";
}
