import { home, homeActive, lift, liftActive, asset, assetActive, cph, cphActive } from "../../../assets";

export const navItemsForBothApps = [
  {
    id: 1,
    parentText: "Home",
    text: "Home",
    name: "Home",
    icon:home,
    iconActive:homeActive,
    path: "/dashboard",
  },
  {
    id: 2,
    parentText: "Lifts",
    text: "Lifts",
    name: "Lifts",
    path: "/liftdashboard",
    icon:lift,
    iconActive:liftActive
  },
  {
    id: 3,
    parentText: "Assets",
    text: "Assets",
    name: "Assets",
    path: "/assetdashboard",
    icon:asset,
    iconActive:assetActive
  },
  {
    id: 4,
    parentText: "CPH",
    text: "CPH",
    name: "CPH",
    path: "/cphdashboard",
    icon:cph,
    iconActive:cphActive
  }
];
export const navItemsForDeliveryIOT = [
  {
    id: 1,
    parentText: "Home",
    text: "Home",
    name: "Home",
    icon:home,
    iconActive:homeActive,
    path: "/dashboard",
  },
  {
    id: 2,
    parentText: "Lifts",
    text: "Lifts",
    name: "Lifts",
    path: "/liftdashboard",
    icon:lift,
    iconActive:liftActive
  },
  {
    id: 4,
    parentText: "CPH",
    text: "CPH",
    name: "CPH",
    path: "/cphdashboard",
    icon:cph,
    iconActive:cphActive
  }
];

export const navItemsForAMI = [
  {
    id: 3,
    parentText: "Assets",
    text: "Assets",
    name: "Assets",
    path: "/assetdashboard",
    icon:asset,
    iconActive:assetActive
  }
];