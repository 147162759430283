import React, {useEffect, useState} from "react";
import { Box } from "@mui/material"
import {getEmbeddedURL} from "../components/API";
import CircleProgressBar from "../components/DataTable/CircleProgressBar";
import CustomSuccessMessage from "../components/DataTable/CustomSuccessMessage";
import {Constants} from "../components/utils/Constants";
import {connect} from "react-redux";
import {getCustomerAndLocations} from "../redux/Thunk/RoutesThunk";
import {getEmbeddedCphDBUrl} from "../redux/Thunk/AssetsThunk";

 function CPHDashboard({getEmbeddedCphDBUrl,cphEmbeddedUrl}) {

    const [embeddedUrl,setEmbeddedUrl] = useState("");
    const [isShowMessage,setShowMessage] = useState(false);
    const [errorType,setErrorType] = useState("success");
    const [message,setMessage] = useState("");
    const [progress,setProgress] = useState(true);

    useEffect(()=>{
        console.log("CPH Dashboard:"+cphEmbeddedUrl);
       // cphEmbeddedUrl = "";

       getDBEmbeddedUrl();
    },[]);


    let getDBEmbeddedUrl = async ()=>{
        try {
            let data = {};
            data.appName = 'AMI';
            data.dbId = 'CPH';
            data.db = 'dashboard';
            let res = await getEmbeddedCphDBUrl(data);
            console.log(res);
            console.log(cphEmbeddedUrl);

            if(res && res.error){

                showErrorMessage(res.error.message);
                //setProgress(false);
                //console.log(res.error.message);
                //showSuccessErrorMessage(Constants.ERROR,res.error.message);
            }

            //setEmbeddedUrl(cphEmbeddedUrl);
            setTimeout(()=>{
                setProgress(false);
                //console.log(document.querySelector("div[data-automation-id]"));
                //document.getElementsByClassName("flex-grow")[6].style.transform = "none";
            },2000);
           /* let response = await getEmbeddedURL('AMI', 'CPH', 'dashboard');
            if(response && response.data && response.data.embedUrl && response.data.embedUrl.length>0){
                let cphDBUrl = response.data.embedUrl[0]['embed-url'];
                if(cphDBUrl){
                    console.log(cphDBUrl);
                    setEmbeddedUrl(cphDBUrl);
                    setTimeout(()=>{
                        setProgress(false);
                        console.log(document.querySelector("div[data-automation-id]"));
                        //document.getElementsByClassName("flex-grow")[6].style.transform = "none";
                    },1500);
                }

            }*/
            //console.log(response.data.embedUrl[0]['embed-url']);
        }catch (ex){
            console.log(ex.message);
            showErrorMessage(ex.message);
        }
    }

    let showErrorMessage = (msg)=>{
        setProgress(false);
        cphEmbeddedUrl = "";
        showSuccessErrorMessage(Constants.ERROR,msg);
    }

    let showSuccessErrorMessage = (type,msg)=>{
        setShowMessage(true);
        setMessage(msg);
        setErrorType(type);
    }

  return (
    <>
      <Box sx={{ marginTop: "50px",overflow:"hidden" }}>
          <CustomSuccessMessage   message={message} type={errorType} flag={isShowMessage} onHide={()=>{setShowMessage(false)}}/>
          <div style={{width:"100%",paddingTop:"5px",border:"1px solid #ede6e6",overflow:"hidden",height:(window.innerHeight-100)}}>
              {progress?<CircleProgressBar />:
                  <iframe id={"iFrame"} style={{border: "0px"}} src={cphEmbeddedUrl} width="138%" height="100%"
                          onLoadedData={() => {
                              console.log('onload')
                          }} frameBorder="0" allowFullScreen="true" webkitallowfullscreen="true"
                          mozallowfullscreen="true"></iframe>}


          </div>

      </Box>
    </>
  );
}

const mapStateToProps = (state) => {
    return {
        selectedCustomerId: state?.Routes?.selectedCustomer,
        selectedLocationId: state?.Routes?.selectedLocation,
        customerAndLocationsData: state?.Routes?.customerAndLocationsData,
        cphEmbeddedUrl: state?.Assets?.cphEmbeddedUrl,

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getEmbeddedCphDBUrl: (data) => dispatch(getEmbeddedCphDBUrl(data)),

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CPHDashboard);
