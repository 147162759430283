import React, { useState } from "react";
import {
  DataGrid,
  GridToolbar,
  getGridStringOperators,
} from "@mui/x-data-grid";
import {Button, Typography, Box, Grid, Snackbar, Alert} from "@mui/material";

import CustomPaper from "../Common/CustomPaper";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {selectIcon} from "../../assets";
import FormControl from "@mui/material/FormControl";
import CustomLinkRender from "./CustomLinkRender";
import CustomCellNumRender from "./CustomCellNumRender";
import {deleteRFIDTags} from "../API";
import CircleProgressBar from "./CircleProgressBar";
import moment from "moment";
import CustomSuccessMessage from "./CustomSuccessMessage";
import { useNavigate } from "react-router-dom";
import {Constants} from "../utils/Constants";

export default function DataTableLostPallets({data,progress,onReload,onReloadLostPallets}) {
  const navigate = useNavigate();
  const filterOperators = getGridStringOperators().filter(({ value }) =>
    ["contains" /* add more over time */].includes(value)
  );


  // const [pageSize, setPageSize] = useState(10);
  const [filterModel, setFilterModel] = useState({
    items: [
      {
        field: "rating",
        operator: ">",
        value: "2.5",
      },
    ],
  });

    const [state, setState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });

    const { vertical, horizontal, open } = state;



    const handleClose = () => {
        setState({ ...state, open: false });
    };


  const columns = [
    { field: "rfid", headerName: "RFID",width:"250",renderHeader: (params) => (
      <span className={"grid-column-header"} style={{width:"250px"}}>
          RFID
      </span>

  ), filterOperators },

    { field: "lastScanTime", headerName: "Last Scan Date & Time",width:"250",renderHeader: (params) => (
            <span className={"grid-column-header"} style={{width:"250px"}}>
                Last Scan Date & Time
            </span>

        ), renderCell: (params) => {

            return(
                <>
                    <span style={{paddingLeft:"45px"}}>{params.row.lastScanTime ? moment(params.row.lastScanTime).format('MMM D, YYYY h:mm a'): ""}</span>
                </>
            )},
            valueGetter: (params) => (params.row.lastScanTime ? moment(params.row.lastScanTime).format('MMM D, YYYY h:mm a'): ""),
            filterOperators },

    { field: "lastKnownLocation", headerName: "Store Name",renderHeader: (params) => (
            <span className={"ltAllignLtPaddedDigits-xl"} style={{width:"400px"}}>
                Store Name
            </span>

        ),width:"300",renderCell: (params) => {
        return(
            <>
                <span>{params.row.lastKnownLocation}</span>
            </>
        )}, filterOperators },

    { field: "storeAddress", headerName: "Store Address",renderHeader: (params) => (
          <span className={"ltAllignLtPaddedDigits-xl"} style={{width:"400px"}}>
              Store Address
          </span>

      ),width:"300",renderCell: (params) => {
      return(
          <>
              <span>{params.row.storeAddress}</span>
          </>
    )}, filterOperators },

    { field: "liftID", headerName: "Lift ID", minWidth: 150,  align: 'center',renderHeader: (params) => (
      <span className={"centeredPaddedRtDigits"} style={{width:"150px"}}>
          Lift ID
      </span>

    ), renderCell: (params) => {
      return (
        <span>
          {params.row.liftId ? params.row.liftId : ' - '}
        </span>
      );
    },
    valueGetter: (params) => (params.row.liftId ? params.row.liftId : ' - '), 
    filterOperators: filterOperators 
  },


    { field: "avgDwellTime", headerName: "Dwell Time", minWidth: 150,  align: 'right', renderHeader: (params) => (
            <span className={"grid-column-header-no"} style={{width:"150px"}}>
                Dwell Time
            </span>

        ),renderCell: (params) => {
        return(
            <>
              <CustomCellNumRender field={params.row.avgDwellTime} />
            </>
        )},filterOperators },

  ];
  const subColumns = [

    { field: "col1", headerName: "Date ", width: 150, filterOperators },
    { field: "col2", headerName: "Total Pallets", width: 150, filterOperators },


  ];

  const liftMaintenancePage = (liftId) => {
    const event = new CustomEvent(Constants.LIFT_MAINTENANCE_EVENT, { detail: {} });
    document.dispatchEvent(event);
    navigate('/liftMaintenance', { state: { liftId: liftId } })
  }

  const [selectedRows,setSelectedRows] = useState([]);
  const [alertsDelete,setAletsDelete] = useState(false);
  const [deleteClick,setDeleteClick] = useState(false);

    const [isShowMessage,setShowMessage] = useState(false);
    const [errorType,setErrorType] = useState("success");
    const [message,setMessage] = useState("");


    let handleCloseAlert = ()=>{
    setAletsDelete(false);
  }

  let onClickDelete = ()=>{
    if(selectedRows.length>0){
      setAletsDelete(true);
    }
  }

  const handleSelectionChange = (selectionModel) => {
    setSelectedRows(selectionModel);
  };

  let onClickCancel = ()=>{
    setSelectedRows([]); 
    // onReloadLostPallets();
  }
  let handleDeleteRFIDTags = async()=>{
    const datafinal = data.filter((row) => selectedRows.includes(row.id));
    let arr = [];
    datafinal.forEach((item,idx)=>{
      arr.push(item.rfid);
    });
    let req = {};
    req.rfid = arr;
    try{
        setDeleteClick (true);
      let response = await deleteRFIDTags(arr);
      console.log(response);
      setAletsDelete(false);
        setDeleteClick(false);
        showSuccessErrorMessage(Constants.SUCCESS,"Lost Pallet(s) are deleted successfully");
       // setShowMessage(true);
        onReload();
    }catch (ex){
      console.log(ex.message);
        showSuccessErrorMessage(Constants.ERROR,ex.message);
    }
  }

 let handleSnackbarClose = ()=>{
     setShowMessage(false);
 }
let showSuccessErrorMessage = (type,msg)=>{
    setShowMessage(true);
    setMessage(msg);
    setErrorType(type);
}

  return (
      <Box>
          <CustomSuccessMessage   message={message} type={errorType} flag={isShowMessage} onHide={()=>{setShowMessage(false)}}/>
          {/*<Snackbar open={isShowMessage} autoHideDuration={2000} onClose={handleSnackbarClose}
                    anchorOrigin={{vertical:"top",horizontal:"center"}}>
              <Alert onClose={handleSnackbarClose}
                     severity="success" variant="filled" sx={{ width: '100%' }} >
                  {"Lost Pallet(s) are deleted successfully "}
              </Alert>
          </Snackbar>*/}
        {alertsDelete &&
            <React.Fragment>
              <Dialog
                  open={alertsDelete}
                  onClose={handleCloseAlert}
                  sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "600px",  // Set your width here
                      },
                    },
                  }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title" sx={{ fontSize: "20px" }}>
                  Delete Last Pallet(s)
                  <IconButton style={{ float: "right" }}>
                    <CloseIcon onClick={() => handleCloseAlert()} />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <Box sx={{ width: "100%" }}>
                      <Grid container spacing={2} sx={{ justifyContent: 'center',paddingTop:"20px" }}>
                        <Grid xs={12} lg={12} xl={12} sx={{ textAlign:"center"}}>
                          <img src={selectIcon} />

                        </Grid>
                        <Grid xs={12} lg={12} xl={12} sx={{ textAlign:"center"}}>
                          <span>Are you sure delete selected lost pallet(s) ?</span>
                        </Grid>

                        <Grid xs={3} lg={3} xl={3} sx={{ justifyContent: 'center',paddingTop:"20px" }}>
                          <FormControl fullWidth>
                            <Button variant="contained"
                                    sx={{backgroundColor:"#2027A5",color:"#FFF",height:"50px",marginRight:"0px",fontWeight:"500",':hover': {
                                        bgcolor: '#2027A5', color: '#FFF'}}} onClick={() => handleDeleteRFIDTags()}>
                              Yes

                            </Button>
                          </FormControl>
                        </Grid>
                        &nbsp;&nbsp;
                        <Grid xs={3} lg={3} xl={3} sx={{ justifyContent: 'center',paddingTop:"20px" }}>
                          <FormControl fullWidth>
                            <Button variant="contained"
                                    sx={{backgroundColor:"#FFF",color:"#000",height:"50px",marginRight:"0px",fontWeight:"500",':hover': {
                                        bgcolor: '#FFF', color: '#000'}}} onClick={() => handleCloseAlert()} >
                              No

                            </Button>
                          </FormControl>
                            <Grid xs={3} lg={3} xl={3} sx={{ justifyContent: 'center',paddingTop:"20px" }}>
                                <FormControl fullWidth>
                                    {deleteClick?<CircleProgressBar />:""}
                                </FormControl>
                            </Grid>
                        </Grid>
                      </Grid>





                    </Box>
                  </DialogContentText>
                </DialogContent>
              </Dialog>
            </React.Fragment>
        }
    <CustomPaper height="100%" padding="20px">
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "0.5rem" }}>
          <Grid container>
          <Grid xs={6} lg={6} xl={6}>
            <Box> <Typography variant="h3" sx={{padding:"0rem 0rem 0rem 0.8rem", margin:"0.3rem 0"}}>Pallets Lost</Typography></Box>
      </Grid>

     <Grid xs={6} lg={6} xl={6}>
    <Box style={{display:selectedRows?.length > 0 ? "":"none"}}>
      <Button className="btn btn-outline-danger me-3" onClick={onClickDelete}>Delete</Button>
      <Button className="btn btn-link" onClick={onClickCancel}>Cancel</Button>
    </Box>
    </Grid>
    </Grid>

    </Box>
    <div style={{display:"flex",height:500,width:"100%"}}>
        <DataGrid sx={{borderColor:"#FFF !important"}} 
        loading={progress}
          rows={data} 
          columns={columns} 
          checkboxSelection={true}
          disableRowSelectionOnClick 
          onRowSelectionModelChange={(newSelection) => handleSelectionChange(newSelection)}
          rowSelectionModel={selectedRows}
          //onSelectionModelChange={itm => console.log(itm)}
          // onRowSelectionModelChange={(ids) => {
          //   const selectedRowsData = ids.map((id) => data.find((row) => row.id === id));
          //   console.log(selectedRowsData);
          //   setSelectedRows(selectedRowsData);
          // }}

          slots={{
            toolbar: GridToolbar,
              noResultsOverlay: () => (
                  <Box
                      sx={{
                          display: "flex", height: "100%", justifyContent: "center",
                          alignItems: "center", flexDirection: "column",
                      }}>
                      <Typography style={{fontWeight:"400", fontSize:"16px"}}>
                          No Results Found
                      </Typography>

                  </Box>
              ),
              noRowsOverlay:() => (
                  <Box
                      sx={{
                          display: "flex", height: "100%", justifyContent: "center",
                          alignItems: "center", flexDirection: "column",
                      }}>
                      <Typography style={{fontWeight:"400", fontSize:"16px"}}>
                          No Rows
                      </Typography>
                  </Box>
              ),
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          filterModel={filterModel}
          onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          initialState={{
            pagination: { paginationModel: { pageSize: 25 } },
          }}
        />

      </div>
    </CustomPaper>
      </Box>
  );
}
