import React, {useEffect, useState} from "react";
import {
    DataGrid,
    GridToolbar,
    getGridStringOperators,
} from "@mui/x-data-grid";
import {Button, Typography, Box, CircularProgress, Stack} from "@mui/material";

import CustomPaper from "../Common/CustomPaper";

export default function CustomCellRender({field}) {


    return (
        <>
            <span className={"cellTextAlign"}>{field}</span>

        </>
    );
}
