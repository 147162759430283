import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  DataGrid,
  GridToolbar,
  getGridStringOperators,
} from "@mui/x-data-grid";
import { Button, Typography, Box, Grid, CircularProgress } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CustomPaper from "../Common/CustomPaper";
import CustomCellNumRender from "./CustomCellNumRender";
import { useNavigate } from "react-router-dom";
import { getLiftInfo, getLiftAlertInfo, getLiftMaintenanceInfo } from "../../redux/Thunk/RoutesThunk";
import { batteryIconGreen, batteryIconRed, lift } from "../../assets";
import moment from 'moment';
import { Constants } from "../utils/Constants";

function DataMain({ liftId, getLiftInfo, loadingLiftInfoData, liftInfoData, getLiftAlertInfo, liftDataLoad, loadingLiftAlertData, liftAlertData, getLiftMaintenanceInfo, loadingLiftMaintenanceData, liftMaintenanceData, liftAlertPageData }) {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });

  const navigate = useNavigate();
  const AddMaintenanceClick = () => {
    const event = new CustomEvent(Constants.LIFT_ADD_MAINTENANCE_EVENT, { detail: {} });
    document.dispatchEvent(event);
    navigate('/addMaintenance', { state: {liftId: liftId} })
  }
  useEffect(() => {
    if (liftId)
    {
      const data = {
        liftId: liftId,
        pageNumber: "1",
        pageSize: "1000"
      }
      getLiftInfo(liftId);
      getLiftAlertInfo(data);
      getLiftMaintenanceInfo(liftId)
    }
  }, [liftId]);



  const filterOperators = getGridStringOperators().filter(({ value }) =>
    ["contains" /* add more over time */].includes(value)
  );
  // const [pageSize, setPageSize] = useState(10);
  const [currentTabIndex, setCurrentTabIndex] = useState(0)
  const [filterModel, setFilterModel] = useState({
    items: [
      {
        field: "rating",
        operator: ">",
        value: "2.5",
      },
    ],
  });
  const handleChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex)
  }

  const NumberWithCommas = ( number) =>  {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const columnsForMaintenance = [
    {
      field: "date", headerName: "Date & Time", flex: 1.5, renderCell: (params) => {
        return (
          <>
            {moment(params.row.date).format('MMM D, YYYY hh:mm A')}
          </>
        )
      }, filterOperators
    },
    {
      field: "actionPerformed", headerName: "Action Taken", flex: 1, renderCell: (params) => {
        return (
          <span style={{ color: params.row.actionPerformed === "Out of Service" ? "#D30000" : "#018015" }}>
            {params.row.actionPerformed}
          </span>
        )
      }, filterOperators
    },
    {
      field: "tasks", headerName: "Maintenance Type", flex: 2, renderHeader: (params) => (
        <span className={"grid-column-header"}>
          Maintenance <br />Type
        </span>
      ), renderCell: (params) => {
        return (
          <>
            <div>
              {params.row.tasks.map((item) => (
                <span>{item}<br></br></span>
              ))}
            </div>
          </>
        )
      }, filterOperators
    },
    {
      field: "parts", headerName: "Replaceable Items", flex: 1, renderHeader: (params) => (
        <span className={"grid-column-header"}>
          Replaceable <br />Items
        </span>
      ), filterOperators
    },
    // {
    //   field: "powerOnHours", headerName: "Total Power on hours", width: 100, 
    //   renderHeader: (params) => (
    //     <span className={"grid-column-header"}>
    //       Total Power <br />on hours
    //     </span>
    //   ), renderCell: (params) => {
    //     return (
    //       <>
    //       <span className="rtPaddedDigits">{NumberWithCommas(params.row.powerOnHours)} </span>
    //       </>
    //     )
    //   },filterOperators
    // },
    {
      field: "workingHours", headerName: "Total Working hours", width: 100,  renderHeader: (params) => (
        <span className={"grid-column-header"}>
          Total Working <br />hours
        </span>
      ), renderCell: (params) => {
        return (
          <>
            <span className="rtPaddedDigits">{NumberWithCommas(params.row.workingHours)} </span>
          </>
        )
      },filterOperators
    },
    {
      field: "travelDistance", headerName: "Total Travel Dist (km)", width: 100,  renderHeader: (params) => (
        <span className={"grid-column-header"}>
          Total Travel <br /> Dist (km)
        </span>
      ), renderCell: (params) => {
        const formattedValue = typeof params.row.travelDistance === 'number'   ? NumberWithCommas(params.row.travelDistance.toFixed(1)) : '';
        return (
          <>
          <span className="rtPaddedDigits">{formattedValue} </span>
          </>
        )
      },filterOperators
    },
    {
      field: "doneBy", headerName: "Maintenance Done By", flex: 1, renderHeader: (params) => (
        <span className={"grid-column-header"}>
          Maintenance <br />Done By
        </span>
      ), filterOperators
    }
  ];

  const columnsForAlerts = [
    { field: "id", headerName: "Alert Id", minWidth: 80,renderCell: (params) => {
      return (
        <>
        <span className={"rtPaddedDigits-xl"}>{params.row.id}</span>
        </>
      )
    }, filterOperators },
    { field: "description", headerName: "Alert Description",  minWidth: 300,filterOperators },
    {
      field: "time", headerName: "Alert Date & Time", minWidth: 300, renderCell: (params) => {
        return (
          <>
            {moment(params.row.time).format('MMM D, YYYY hh:mm A')}
          </>
        )
      }, filterOperators
    },
    {
      field: "totalWorkingHours", headerName: "Total Working hours", minWidth: 125, renderHeader: (params) => (
        <span className={"grid-column-header"}>
          Total Working <br />hours
        </span>
      ), renderCell: (params) => {
        return (
          <>
            <span class={"rtPaddedDigits-xl"}> {NumberWithCommas(params.row.totalWorkingHours)}</span>
          </>
        )
      },filterOperators
    },
    {
      field: "totalTravelDistance", headerName: "Total Travel Dist (km)", minWidth: 125,  resizable: false, renderHeader: (params) => (
        <span className={"grid-column-header"}>
          Total Travel <br /> Dist (km)
        </span>
      ), renderCell: (params) => {
      const formattedValue = typeof params.row.totalTravelDistance === 'number'   ? NumberWithCommas(params.row.totalTravelDistance.toFixed(1)) : '';
        return (
          <>
          <span class={"rtPaddedDigits-xl"}> {formattedValue}</span>
          </>
        )
      },filterOperators
    }
  ];

  const formatData = (value) => {
    const dateObj = new Date(value);
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    };
    const formattedDate = dateObj.toLocaleDateString('en-US', options);
    const start = new Date().setHours(0, 0, 0, 0);
    const end = new Date(value).setHours(0, 0, 0, 0);
    const differenceInMilliseconds = end - start;
    const differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24);
    if (differenceInDays > 0 && differenceInDays < 8)
      return differenceInDays === 1 ? differenceInDays + " Day" : differenceInDays + " Days";
    return formattedDate;
  }

  useEffect(() => {
    const fetcher = async () => {
      const data = {
          liftId: liftId,
          pageNumber: paginationModel.page + 1,
          pageSize: paginationModel.pageSize,
        };
      getLiftAlertInfo(data)
    };
   // fetcher();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel]);

  return (
    <CustomPaper height="100%" padding="10px">
      <Box>
        <Tabs value={currentTabIndex} onChange={handleChange}>
          <Tab label="Alerts" style={{ textTransform: "none" }} />
          <Tab label="Maintenance" style={{ textTransform: "none" }} />
        </Tabs>
      </Box>
      {currentTabIndex === 1 && (
        <div style={{ height: "auto", padding: "0px 10px" }}>
          <Grid container spacing={2} style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}>
            <Grid item xs={12} md={10} lg={10} xl={10} >
              {loadingLiftInfoData ?
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "40px",
                    marginBottom: "10px",
                  }}
                >
                  <CircularProgress />
                </Box> :
                <Box className="tbl-main ">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <Box className="tbl-title">Lift Name</Box>
                          <Box className="tbl-dis">{liftInfoData?.name}</Box>
                        </td>
                        <td>
                          <Box className="tbl-title">Tracker Id</Box>
                          <Box className="tbl-dis">{liftInfoData?.trackerId}</Box>
                        </td>
                        <td>
                          <Box className="tbl-title">Last Maintenance</Box>
                          <Box className="tbl-dis">{liftInfoData?.lastMaintenanceDate ? liftInfoData?.lastMaintenanceDate : "NA"}</Box>
                        </td>
                        <td>
                          <Box className="tbl-title">Maintenance Due Date</Box>
                          <Box className="tbl-dis">
                            {liftInfoData?.maintenanceDueDate ? formatData(liftInfoData?.maintenanceDueDate) : "NA"}</Box>
                        </td>
                        <td>
                          <Box className="tbl-title">Battery Status</Box>
                          <Box className="tbl-dis">
                            {(liftInfoData?.batteryPercentage || liftInfoData?.batteryPercentage === 0) ?
                              liftInfoData?.batteryPercentage <= 30 ? <img src={batteryIconRed} /> : <img src={batteryIconGreen} />
                              : "NA"}
                            {(liftInfoData?.batteryPercentage || liftInfoData?.batteryPercentage ===0) &&
                              liftInfoData?.batteryPercentage + "%"
                            }
                          </Box>
                        </td>
                        <td>
                          <Box className="tbl-title"># of Alerts</Box>
                          <Box className="tbl-dis">{liftInfoData?.alertCount}</Box>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Box>
              }
            </Grid>
            <Grid item xs={12} md={2} lg={2} xl={2} style={{ textAlign: "right" }}>
              <Button className="btn-primary normalbtn" variant="outlined" onClick={AddMaintenanceClick}>
                Add Maintenance Record <AddCircleOutlineOutlinedIcon className="fs-14 ms-1" />
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}>
            <Grid item xs={12} lg={12} xl={12} >
            <div style={{height: 600, width: "100%"}}>
              <DataGrid
                getRowHeight={() => 'auto'}
                loading={loadingLiftMaintenanceData}
                autoWidth
                rows={loadingLiftMaintenanceData ? [] : liftMaintenanceData}
                columns={columnsForMaintenance}
                getRowId={(row) => row.id}
                sx={{
                  borderColor: "#FFF !important",
                  display: "grid",
                  gridTemplateRows: "auto 1f auto",
                  '& .MuiDataGrid-columnHeader': {
                    fontSize: "12px"
                  },
                  '& .MuiDataGrid-cell': {
                    fontSize: "13px"
                  },
                  '& .MuiDataGrid-cell:focus': {
                    outline: ' none'
                  },'& .MuiDataGrid-row': {
                    minHeight: '52px !important',
                },
                }}
                slots={{
                  toolbar: GridToolbar,
                  noResultsOverlay: () => (
                    <Box
                      sx={{
                        display: "flex", height: "100%", justifyContent: "center",
                        alignItems: "center", flexDirection: "column",
                      }}>
                      <Typography style={{fontWeight:"400", fontSize:"16px"}}>
                        No Results Found
                      </Typography>
                      {/* <Typography variant="body1">
                        Try updating your filter choices to see results.
                      </Typography> */}
                    </Box>
                  ),
                  noRowsOverlay:() => (
                    <Box
                    sx={{
                      display: "flex", height: "100%", justifyContent: "center",
                      alignItems: "center", flexDirection: "column",
                    }}>
                    <Typography style={{fontWeight:"400", fontSize:"16px"}}>
                      No Rows
                    </Typography>
                  </Box>
                  ),
                }}
                slotProps={{ toolbar: { showQuickFilter: true}}}
                filterModel={filterModel}
                onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                pageSizeOptions={[5, 10, 25, 50, 100]}
                initialState={{
                  pagination: { paginationModel: { pageSize: 25 } },
                }}
                hideScrollbar={true}
                disableRowSelectionOnClick
              />
              </div>
            </Grid>
          </Grid>
        </div>
      )}
      {currentTabIndex === 0 && (
        <div style={{ height: "auto", padding: "0px 10px" }}>
          <Box className="liftname">Lift Name : <span className="bold"> {liftInfoData?.name}</span> </Box>
          <Grid container spacing={2} style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}>
            <Grid item xs={12} lg={12} xl={12} >
            <div style={{height: 600, width: "100%"}}>
              <DataGrid
                loading={loadingLiftAlertData}
                
                rows={loadingLiftAlertData ? [] : liftAlertData}
                columns={columnsForAlerts}
                getRowId={(row) => row.idx}
                rowCount={liftAlertPageData?.totalElements && Math.ceil(liftAlertPageData?.totalElements)}
                pagination

                initialState={{
                  pagination: { paginationModel: { pageSize: 25 } },
                }}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                sx={{
                  borderColor: "#FFF !important",
                  display: "grid",
                  gridTemplateRows: "auto 1f auto",
                  '& .MuiDataGrid-columnHeader': {
                    fontSize: "12px"
                  },
                  '& .MuiDataGrid-cell': {
                    fontSize: "13px"
                  },
                  '& .MuiDataGrid-cell:focus': {
                    outline: ' none'
                  },
                  '& .hot': {
                    "textWrap": "wrap !important",
                    "textAlign": "left"
                  },
                }}
                slots={{
                  toolbar: GridToolbar,
                  noResultsOverlay: () => (
                    <Box
                      sx={{
                        display: "flex", height: "100%", justifyContent: "center",
                        alignItems: "center", flexDirection: "column",
                      }}>
                      <Typography style={{fontWeight:"400", fontSize:"16px"}}>
                        No Results Found
                      </Typography>
                      {/* <Typography variant="body1">
                        Try updating your filter choices to see results.
                      </Typography> */}
                    </Box>
                  ),
                  noRowsOverlay:() => (
                    <Box
                    sx={{
                      display: "flex", height: "100%", justifyContent: "center",
                      alignItems: "center", flexDirection: "column",
                    }}>
                    <Typography style={{fontWeight:"400", fontSize:"16px"}}>
                      No Rows
                    </Typography>
                  </Box>
                  ),
                }}
                getCellClassName={(params) => {
                  if (params.field === 'lastReportedTime' || params.field === 'maintenanceDueDate') {
                    return 'hot';
                  }
                }}
                slotProps={{ toolbar: { showQuickFilter: true, }, }}
                filterModel={filterModel}
                onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                pageSizeOptions={[5, 10, 25, 50, 100]}
                hideScrollbar={true}

                disableRowSelectionOnClick
              />
              </div>
            </Grid>
          </Grid>
        </div>
      )
      }
    </CustomPaper>
  );
}

const mapStateToProps = (state) => {
  return {
    loadingLiftInfoData: state?.Routes?.loadingLiftInfoData,
    liftInfoData: state?.Routes?.liftInfoData,
    loadingLiftAlertData: state?.Routes?.loadingLiftAlertData,
    liftAlertData: state?.Routes?.liftAlertData,
    liftAlertPageData: state?.Routes?.liftAlertPageData,
    loadingLiftMaintenanceData: state?.Routes?.loadingLiftMaintenanceData,
    liftMaintenanceData: state?.Routes?.liftMaintenanceData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLiftInfo: (liftId) => dispatch(getLiftInfo(liftId)),
    getLiftAlertInfo: (data) => dispatch(getLiftAlertInfo(data)),
    getLiftMaintenanceInfo: (liftId) => dispatch(getLiftMaintenanceInfo(liftId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataMain);
