import React, {useEffect, useState} from "react";
import {
    DataGrid,
    GridToolbar,
    getGridStringOperators,
} from "@mui/x-data-grid";
import {Button, Typography, Box, CircularProgress, Stack, Alert, Snackbar} from "@mui/material";

import CustomPaper from "../Common/CustomPaper";

export default function CustomSuccessMessage({flag,type,message,onHide}) {

    const [open, setOpen] = useState(false);
    const [page,setPage] = useState(0);

    useEffect(()=>{
        setOpen(flag);
    },[flag])
    const handleClick = () => {
        setOpen(false);
    };

    const handleClose = (event, reason) => {
        flag = false;
        setOpen(false);
        onHide();
    };

    return (
        <>
            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}
                      anchorOrigin={{vertical:"top",horizontal:"center"}}>
                <Alert onClose={handleClose}
                    severity={type} variant="filled" sx={{ width: '100%' }} >
                    {message}
                </Alert>
            </Snackbar>

        </>
    );
}
