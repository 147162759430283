import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
//import GoogleMapReact from 'google-map-react';
import { loadMapApi } from '../components/gMap/GoogleMapsUtils'
import moment from "moment";
import {
  Box,
  Button, Checkbox,
  Chip, CircularProgress, Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItem,
  Stack,
  Switch, TextField, Typography
} from "@mui/material";
import { CompletedPin, PendingPin, InactivePin } from "../assets";
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import RouteCard from "../components/RouteCard";
import KPICard from "../components/KPICard";
import DataTable from "../components/DataTable/DataTable";
import { bellIcon, caseDetailsIcon, liftHealthIcon, Route, routeDistanceIcon } from "../assets";
import axios from "axios";
import {
  getLiftCurrentLocation,
  getRouteDetails,
  getRouteMapDeltaHeartbeats,
  getRouteMapHeartbeats,
  getUser
} from "../components/API";
import Paper from "@mui/material/Paper";
import Draggable from 'react-draggable';
import { DataGrid, getGridStringOperators, GridToolbar } from "@mui/x-data-grid";
import { useLocation, useNavigate } from "react-router-dom";
import CircleProgressBar from "../components/DataTable/CircleProgressBar";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Constants } from "../components/utils/Constants";
import CustomSuccessMessage from "../components/DataTable/CustomSuccessMessage";
import { connect } from "react-redux";
import { getAlertsForLocation, getCustomerAndLocations, getUserRole, updatePinLocation, getRouteDetailsData, getHeartBeatsData } from "../redux/Thunk/RoutesThunk";
import { setUpdatePinStatus } from "../redux/reducers/Routes/RoutesReducer";
//import {navigate} from "react-big-calendar/lib/utils/constants";


//var flag = false;
//const SignUpPage = () => {
var markers = [];
var dcMarkers = [];
var liftMarkers = [];
var flightPath = null;
var gMap = null;
var latlngbounds = null;//new google.maps.LatLngBounds();
var heartBeats = [];
var routeStops = [];
var dcLocations = [];
var liftLocations = [];
var routeLoaded = false;
var startTimer = null;
var mapRefreesh = false;
var liftIds = [];
var availRoutes = [];
function RouteDetails({ selectedLocationListData, selectedCustomerListData, customerAndLocationsData, userRoleName, updatePinLocation, updatePinStatus, getRouteDetailsData, getHeartBeatsData, routeDetailsData, loadingFirstRouteDetails, loadingFinalRouteDetails, heartBeatsData, loadingHeartBeats }) {
  const location = useLocation();
  let navigate = useNavigate();
  const routeData = location?.state?.routeData;


  const [routData, selRouteData] = useState("");
  const [routeName, setRouteName] = useState("");
  const [driverName, setDriverName] = useState("");
  const [caseCount, setCaseCount] = useState("");
  const [palletsCount, setPalletsCount] = useState("");
  const [cph, setCPH] = useState("");
  const [totServiceTime, setTotServiceTime] = useState("");
  const [totDriveTime, setTotDriveTime] = useState("");
  const [totRouteTime, setTotRouteTime] = useState("");
  const [totDistTime, setTotDistTime] = useState("");
  const [liftBattery, setLiftBattery] = useState("");
  const [navButtons, setButtons] = useState([]);

  const [stopList, setStopList] = useState("");

  const [active, setActive] = useState(true);
  const mapRef = useRef(null);
  const [dataRows, setDataRows] = useState([]);
  const [page, setPage] = useState(0);

  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(false);

  const [minutes, setMinutes] = useState(2);
  const [chkDisable, setChkDisable] = useState(false)

  const [isShowMessage, setShowMessage] = useState(false);
  const [errorType, setErrorType] = useState("success");
  const [message, setMessage] = useState("");
  const [windowResize, setWindowResize] = useState(false);

  var startIcon = null;
  var currlocicon = null;
  var endMicon = null;

  var selectedRoueId = "";
  var selectedRoueName = "";

  var selectedRoutes = routeData;

  let getDeltaHeartBeats = async (routeId) => {
    try {
      const response = await getRouteMapDeltaHeartbeats(routeId);
      //console.log(response.data);
      let heartBeats = [];
      if (response && response.data && response.data[0] && response.data[0].heartbeats) {
        if (response.data[0].heartbeats) {
          if (Array.isArray(response.data[0].heartbeats)) {
            heartBeats = response.data[0].heartbeats;
          } else {
            heartBeats.push(response.data[0].heartbeats);
          }
          heartBeats.map((item, idx) => {
            item.routeId = routeId;
          });
        }
      }
      return heartBeats;
    } catch (ex) {
      showSuccessErrorMessage(Constants.ERROR, ex.message);
    }
  }
  let getHeartBeats = async (routeId, routeName) => {
    try {
      const response = heartBeatsData && heartBeatsData.find(item => item.data.routeName === routeName);
      // const response = await getRouteMapHeartbeats(routeId);
      let heartBeats = [];
      if (response && response.data && response.data.heartbeats) {
        if (response.data.heartbeats) {
          if (Array.isArray(response.data.heartbeats)) {
            heartBeats = response.data.heartbeats;
          } else {
            heartBeats.push(response.data.heartbeats);
          }
          heartBeats = heartBeats.map((item, idx) => {
            let modifiableItem = { ...item }; // Create a shallow clone of the object
            modifiableItem.routeId = routeId;
            return modifiableItem; // Return the modified object
          });
        }
      }
      return heartBeats;
    } catch (ex) {

      showSuccessErrorMessage(Constants.ERROR, ex.message);
      let tempArray = [];
      return tempArray;
    }
  }

  let removeAllMarkers = () => {
    for (let i = 0; i < markers.length; i++) {
      markers[i].setMap(null);
    }
    if (flightPath) {
      flightPath.setMap(null);
    }

    markers = [];
  }

  let removeLiftMarkers = () => {
    //console.log("Lift Markers:"+liftMarkers.length);
    for (let i = 0; i < liftMarkers.length; i++) {
      if (liftMarkers[i]) {
        liftMarkers[i].setMap(null);
      }
    }

    liftMarkers = [];
  }

  let getSelectRouteDetails = async (routeId, routeName) => {
    try {
      setProgress(true);
      setDataRows([]);
      const response = routeDetailsData.length > 0 && routeDetailsData.find(route => route.data.routeName === routeName);
      // const response = await getRouteDetails(routeId);
      if (response && response.data) {
        selRouteData(response.data);
        if (response.data.stops) {
          let stops = [];
          if (Array.isArray(response.data.stops)) {
            stops = response.data.stops;
          } else {
            stops.push(response.data.stops);
          }
          stops = stops.map((item, idx) => {
            const newItem = { ...item };
            newItem.id = idx;
            newItem.routeId = routeId;
            newItem.storeAddCopy = `${newItem.storeName}, ${newItem.storeAddress}`;
            return newItem;
          });
          if (!routeLoaded) {
            let point = { routeId: routeId, latitude: response.data.dcLattitude, longitude: response.data.dcLongitute };
            dcLocations.push(point);
            if ((response.data.routeStatus && response.data.routeStatus.toLowerCase()) == Constants.ROUTE_IN_PROGRESS.toLowerCase()) {
              if (response.data.liftId) {
                liftIds.push({ routeId: routeId, liftId: response.data.liftId });
                point = { routeId: routeId, latitude: response.data.liftLattitude, longitude: response.data.liftLongitude };
                liftLocations.push(point);
              }

            }
            routeStops = routeStops.concat(stops);
          }
          setDataRows(stops);
        }
      }
      // setProgress(false);
      if (!routeLoaded) {
        populateMarkersOnMap(routeId, routeName);
      } else {
        removeAllMarkers();
        displayMarkers();
        setProgress(false);
      }
      routeLoaded = true;
    } catch (ex) {
      setProgress(false);
      console.error('Error fetching user:', ex);
      showSuccessErrorMessage(Constants.ERROR, ex.message);
    }
  }

  let showSuccessErrorMessage = (type, msg) => {
    setShowMessage(true);
    setMessage(msg);
    setErrorType(type);
  }
  let populateMarkersOnMap = async (routeId, routeName) => {
    let response = null;
    let rId = "";
    let rName = "";
    let hBeats = [];
    hBeats = await getHeartBeats(routeId, routeName);
    heartBeats = heartBeats.concat(hBeats);
    for (let i = 1; i < routeData.length; i++) {
      rId = routeData[i].routeId;
      rName = routeData[i].routeName;
      response = routeDetailsData.length > 0 && routeDetailsData.find(route => route.data.routeName === rName);
      // response = await getRouteDetails(rId);
      if (response && response.data && response.data.stops) {
        let stops = [];
        if (Array.isArray(response.data.stops)) {
          stops = response.data.stops;
        } else {
          stops.push(response.data.stops);
        }
        const updatedStops = stops.map(item => {
          return { ...item, routeId: rId };
        });
        stops = updatedStops;
        let point = { routeId: routeId, latitude: response.data.dcLattitude, longitude: response.data.dcLongitute };
        dcLocations.push(point);
        if ((response.data.routeStatus && response.data.routeStatus.toLowerCase()) == Constants.ROUTE_IN_PROGRESS.toLowerCase()) {
          if (response.data.liftId) {
            liftIds.push({ routeId: rId, liftId: response.data.liftId });
            point = { routeId: routeId, latitude: response.data.liftLatitude, longitude: response.data.liftLongitude };
          }
        }
        routeStops = routeStops.concat(stops);
      }
      hBeats = await getHeartBeats(rId, rName);
      heartBeats = heartBeats.concat(hBeats);
    }
    displayMarkers();
  }

  let displayDCLocation = (lat, lng) => {
    let google = window.google;
    let point = new google.maps.LatLng(Number(lat), Number(lng));
    startIcon = {
      url: "../../assets/img/StartPoint.png", // url
      scaledSize: new google.maps.Size(40, 40), // scaled size
      origin: new google.maps.Point(0, 0), // origin
      anchor: new google.maps.Point(0, 30) // anchor
    };
    let marker = new google.maps.Marker({ position: point, map: gMap, icon: startIcon, optimized: true });
    dcMarkers.push(marker);
  }
  let displayLiftLocation = (lat, lng) => {
    let google = window.google;
    let point = new google.maps.LatLng(Number(lat), Number(lng));
    startIcon = {
      url: "../../assets/img/CurrLocation.png", // url
      scaledSize: new google.maps.Size(40, 40), // scaled size
      origin: new google.maps.Point(0, 0), // origin
      anchor: new google.maps.Point(0, 30) // anchor
    };
    let marker = new google.maps.Marker({ position: point, map: gMap, icon: startIcon, optimized: true });
    markers.push(marker);
    liftMarkers.push(marker);
  }

  let displayMarkers = () => {
    mapRefreesh = true;
    let gIcon = null;
    let point = null;
    let marker = null;
    let tip = "";
    let mItem = {};
    let google = window.google;
    latlngbounds = new google.maps.LatLngBounds();

    let flag = false;
    let copyArray = selectedRoutes.concat();

    dcLocations.map((dItem, idx) => {
      if (checkRouteId(dItem.routeId)) {
        point = new google.maps.LatLng(Number(dItem.latitude), Number(dItem.longitude));
        latlngbounds.extend(point);
        displayDCLocation(dItem.latitude, dItem.longitude);
      }
    })

    liftLocations.map((lItem, idx) => {
      if (checkRouteId(lItem.routeId)) {
        // displayLiftLocation(lItem.latitude,lItem.longitude);
      }
    })

    heartBeats.map((hitem, idx) => {
      // gIcon = showStopIcon(item.seqNumber);
      if (checkRouteId(hitem.routeId)) {
        point = new google.maps.LatLng(Number(hitem.latitude), Number(hitem.longitude));
        if (idx == 0) {
          //  gIcon = startIcon;
        } else if (idx == heartBeats.length - 1) {
          //gIcon = endMicon;
        }
        if (hitem.routeId == selectedRoueId) {
          gIcon = heartBeatIcon();
        } else {
          gIcon = heartBeatInActiveIcon();
        }
        tip = "Lift Name: " + hitem.liftId + "\n" + "Received At: " + hitem.time;
        //console.log(hitem.seqNumber,hitem.latitude,hitem.longitude);
        marker = new google.maps.Marker({ position: point, map: gMap, icon: gIcon, title: tip, optimized: true });
        /* marker.addListener("mouseover", () => {
           marker.setTitle("Test")
         });*/
        latlngbounds.extend(point);
        //points.push(point);

        markers.push(marker);
      }

    });

    let isMarkerActive = false;
    let activeCircle = null;
    let previousItem = null;
    routeStops.forEach((item, idx) => {
      if (checkRouteId(item.routeId)) {
        let gIcon;
        if (item.routeId == selectedRoueId) {
          gIcon = showStopActiveIcon(item.stopNumber, item.serviceTimeStatus);
        } else {
          gIcon = showStopInActiveIcon(item.stopNumber);
        }

        const point = new google.maps.LatLng(Number(item.latitude), Number(item.longitude));
        const tip = "Store Name: " + item.storeName + "\n" + "Store Address: " + item.storeAddress;

        const marker = new google.maps.Marker({
          position: point,
          map: gMap,
          icon: gIcon,
          zIndex: 9999999,
          title: tip,
          optimized: true
        });

        latlngbounds.extend(point);

        const contentString = `
      <div>
        <p><b>Store Name:</b> ${item.storeName}</p>
        <p><b>Store Address:</b> ${item.storeAddress}</p>
        <button id="confirm-${idx}">Edit Pin Location</button>
        <button style="margin-left:40px" id="close-${idx}">Close</button>
      </div>
    `;

        const infowindow = new google.maps.InfoWindow({
          content: contentString
        });

        google.maps.event.addListener(infowindow, 'closeclick', function () {
          isMarkerActive = false;
        });

        document.body.addEventListener("keyup", (e) => {
          if (e.key === "Escape") {
            {
              if (activeCircle) {
                activeCircle.setMap(null);
                if (previousItem?.marker) {
                  previousItem.marker.setIcon(showStopActiveIcon(previousItem.stopNumber, previousItem.serviceTimeStatus));
                  previousItem.marker.setDraggable(false);
                  gMap.setZoom(12);
                  previousItem = null;
                }
              }
            }
          }
        });

        marker.addListener('click', function () {
          infowindow.close();
          if (activeCircle) {
            activeCircle.setMap(null);
            if (previousItem?.marker) {
              previousItem.marker.setIcon(showStopActiveIcon(previousItem.stopNumber, previousItem.serviceTimeStatus));
              previousItem.marker.setDraggable(false);
              gMap.setZoom(12);
            }
          }
          if (userRoleName === "RpcAdmin" || userRoleName === "LocationAdmin" || userRoleName === "CustomerAdmin") {
            isMarkerActive = true;
            if (previousItem?.stopNumber !== item.stopNumber)
              infowindow.open(gMap, marker);
            previousItem = null;
          }
        });

        google.maps.event.addListener(infowindow, 'domready', function () {
          document.getElementById(`confirm-${idx}`).addEventListener('click', function () {
            marker.setDraggable(true);
            if (activeCircle) {
              activeCircle.setMap(null);
            }
            previousItem = { lat: item.latitude, lng: item.longitude, stopNumber: item.stopNumber, serviceTimeStatus: item.serviceTimeStatus, marker: marker }
            gMap.setZoom(18);
            gMap.setCenter({ lat: item.latitude, lng: item.longitude });
            marker.setIcon(showStopActiveEditPinIcon(item.stopNumber, item.serviceTimeStatus));
            infowindow.close();
            activeCircle = new window.google.maps.Circle({
              strokeColor: '#FF0000',
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: '#FF0000',
              fillOpacity: 0.35,
              map: gMap,
              center: { lat: item.latitude, lng: item.longitude },
              radius: 100, // Radius in meters
            });
          });
          document.getElementById(`close-${idx}`).addEventListener('click', function () {
            infowindow.close();
            isMarkerActive = false;
          });
        });

        let initialLatLng;

        marker.addListener('dragstart', function () {
          initialLatLng = marker.getPosition();
        });

        marker.addListener('dragend', function (event) {
          const newLatLng = event.latLng;
          const geocoder = new google.maps.Geocoder();

          geocoder.geocode({ 'location': newLatLng }, function (results, status) {
            if (status === 'OK') {
              if (results[0]) {
                const newAddress = results[0].formatted_address;
                const confirmContentString = `
              <div>
                <p><b>New Location</b></p>
                <p><b>Store Name:</b> ${item.storeName}</p>
                <p><b>Latitude:</b> ${newLatLng.lat()}</p>
                <p><b>Longitude: </b>${newLatLng.lng()}</p>
                <p><b>Address:</b> ${newAddress}</p>
                <button id="final-confirm-${idx}">Update Pin Location</button>
                <button style="margin-left:40px" id="final-close-${idx}">Close</button>
              </div>
            `;

                marker.setDraggable(false);

                const confirmInfoWindow = new google.maps.InfoWindow({
                  content: confirmContentString,
                  position: newLatLng
                });

                confirmInfoWindow.open(gMap, marker);

                google.maps.event.addListener(confirmInfoWindow, 'domready', function () {
                  document.getElementById(`final-confirm-${idx}`).addEventListener('click', function () {
                    confirmInfoWindow.close();

                    const finalConfirmContentString = `
                  <div>
                    <p><b>Store Name:</b> ${item.storeName}</p>
                    <p><b>Latitude: </b>${newLatLng.lat()}</p>
                    <p><b>Longitude: </b>${newLatLng.lng()}</p>
                    <p><b>Address: </b>${newAddress}</p>
                    <p><b>Are you sure you want to update the pin location?</b></p>
                    <button id="final-confirm-yes-${idx}">Yes</button>
                    <button style="margin-left:40px" id="final-confirm-no-${idx}"}>No</button>
                  </div>
                `;

                    const finalConfirmInfoWindow = new google.maps.InfoWindow({
                      content: finalConfirmContentString,
                      position: newLatLng
                    });

                    finalConfirmInfoWindow.open(gMap, marker);

                    google.maps.event.addListener(finalConfirmInfoWindow, 'domready', function () {
                      document.getElementById(`final-confirm-yes-${idx}`).addEventListener('click', function () {
                        marker.setDraggable(false);
                        marker.setIcon(showStopActiveIcon(item.stopNumber, item.serviceTimeStatus));
                        activeCircle.setMap(null);
                        finalConfirmInfoWindow.close();
                        isMarkerActive = false;
                        gMap.setZoom(12);
                        gMap.setCenter({ lat: selectedLocationListData.locationLatitude, lng: selectedLocationListData.locationLongitude });
                        const updatedLatLong = {
                          oldLatitude: item.latitude,
                          oldLongitude: item.longitude,
                          newLatitude: newLatLng.lat(),
                          newLongitude: newLatLng.lng(),
                          stopNumber: item.stopNumber,
                          storeName: item.storeName,
                          storeAddress: newAddress,
                          storeNumber: item.storeNumber,
                          customerId: selectedCustomerListData.customerId,
                          locationId: selectedLocationListData.locationId,
                          routeStopsID: item.routeStopsID
                        };
                        updatePinLocation(updatedLatLong);
                        item.latitude = newLatLng.lat();
                        item.longitude = newLatLng.lng();
                      });

                      document.getElementById(`final-confirm-no-${idx}`).addEventListener('click', function () {
                        marker.setPosition(initialLatLng);
                        marker.setDraggable(false);
                        marker.setIcon(showStopActiveIcon(item.stopNumber, item.serviceTimeStatus));
                        activeCircle.setMap(null);
                        gMap.setZoom(12);
                        gMap.setCenter({ lat: selectedLocationListData.locationLatitude, lng: selectedLocationListData.locationLongitude });
                        finalConfirmInfoWindow.close();
                        isMarkerActive = false;
                      });
                    });

                    google.maps.event.addListener(finalConfirmInfoWindow, 'closeclick', function () {
                      marker.setPosition(initialLatLng);
                      marker.setDraggable(false);
                      marker.setIcon(showStopActiveIcon(item.stopNumber, item.serviceTimeStatus));
                      activeCircle.setMap(null);
                      gMap.setZoom(12);
                      gMap.setCenter({ lat: selectedLocationListData.locationLatitude, lng: selectedLocationListData.locationLongitude });
                      isMarkerActive = false;
                    });
                  });
                  document.getElementById(`final-close-${idx}`).addEventListener('click', function () {
                    marker.setPosition(initialLatLng);
                    marker.setDraggable(false);
                    activeCircle.setMap(null);
                    gMap.setZoom(12);
                    gMap.setCenter({ lat: selectedLocationListData.locationLatitude, lng: selectedLocationListData.locationLongitude });
                    marker.setIcon(showStopActiveIcon(item.stopNumber, item.serviceTimeStatus));
                    isMarkerActive = false;
                    confirmInfoWindow.close();
                  });
                });

                google.maps.event.addListener(confirmInfoWindow, 'closeclick', function () {
                  marker.setPosition(initialLatLng);
                  marker.setDraggable(false);
                  marker.setIcon(showStopActiveIcon(item.stopNumber, item.serviceTimeStatus));
                  activeCircle.setMap(null);
                  gMap.setZoom(12);
                  gMap.setCenter({ lat: selectedLocationListData.locationLatitude, lng: selectedLocationListData.locationLongitude });
                  isMarkerActive = false;
                });
              } else {
                window.alert('No results found');
              }
            } else {
              window.alert('Geocoder failed due to: ' + status);
            }
          });
        });

        markers.push(marker);
      }
    });

    startAutoRefresh();

    //startAutoPolling();
    if (latlngbounds) {
      if (gMap && markers.length > 0) {
        gMap.fitBounds(latlngbounds);
        gMap.setCenter(latlngbounds.getCenter());
      }
    }
    setProgress(false);

    //startAutoRefresh();
  }

  let stopAutoPolling = () => {
    if (startTimer) {
      clearTimeout(startTimer);
    }
    startTimer = null;
  }

  let startAutoRefresh = () => {
    if (mapRefreesh) {
      let timer = 60 * 1000;
      startTimer = setTimeout(() => {
        startAutoPolling();
      }, timer);
    }
  }

  // const result = ages.filter(checkAdult);

  let checkRouteId = (routeId) => {
    for (let i = 0; i < availRoutes.length; i++) {
      if (availRoutes[i].routeId == routeId) {
        return true;
      }
    }
    return false;
  }

  const startAutoPolling = async () => {
    let rId = "";
    let response = null;
    let hBeats = [];
    let totHBeats = [];
    let point = {};
    let gIcon = null;
    let tip = "";
    let marker = null;
    let google = window.google;
    let mapFlag = false;
    for (let i = 0; i < availRoutes.length; i++) {
      if (availRoutes[i].routeStatus == Constants.ROUTE_IN_PROGRESS) {
        rId = availRoutes[i].routeId;
        if (mapRefreesh) {
          hBeats = await getDeltaHeartBeats(rId);
          totHBeats = totHBeats.concat(hBeats);
          mapFlag = true;
        }
      }
    }
    heartBeats = heartBeats.concat(totHBeats);
    removeLiftMarkers();
    let liftLocs = [];
    for (let j = 0; j < liftIds.length; j++) {
      try {
        let liftId = liftIds[j].liftId;
        let routeId = liftIds[j].routeId;
        liftId && liftId.split(',').map(async (id) => {
          if (id && mapRefreesh && checkRouteId(routeId)) {
            response = await getLiftCurrentLocation(id);
            let tempArray = [];
            if (response && response.data) {
              //point = {routeId:routeId,latitude:response.data.liftLattitude,longitude:response.data.liftLongitude};
              if (Array.isArray(response.data)) {
                tempArray = response.data;
              } else {
                tempArray.push(response.data);
              }
            }
            tempArray.forEach((lItem, idx) => {
              point = { routeId: routeId, latitude: lItem.liftLatitude, longitude: lItem.liftLongitude };
              displayLiftLocation(lItem.liftLatitude, lItem.liftLongitude);
              liftLocations.push(point);
            });
          }
        })
      } catch (ex) {
        showSuccessErrorMessage(Constants.ERROR, ex.message);
        console.log(ex);
      }
    }


    totHBeats.map((hitem, idx) => {
      // gIcon = showStopIcon(item.seqNumber);
      if (checkRouteId(hitem.routeId)) {
        point = new google.maps.LatLng(Number(hitem.latitude), Number(hitem.longitude));
        if (hitem.routeId == selectedRoueId) {
          gIcon = heartBeatIcon();
        } else {
          gIcon = heartBeatInActiveIcon();
        }
        tip = "Seq Number: " + hitem.seqNumber;//+"\n"+"Store Address: "+item.storeAddress;
        // console.log(hitem.seqNumber,hitem.latitude,hitem.longitude);
        marker = new google.maps.Marker({ position: point, map: gMap, icon: gIcon, title: tip, optimized: true });
        markers.push(marker);
      }

    });

    if (mapFlag) {
      if (latlngbounds) {
        if (gMap) {
          gMap.fitBounds(latlngbounds);
          gMap.setCenter(latlngbounds.getCenter());
        }
      }
    }
    startAutoRefresh();
  }
  useEffect(() => {
    //if(!flag){
    //console.log(selectedLocationListData);
    if (availRoutes.length == 0) {
      availRoutes = selectedRoutes;
    }
    selectedRoutes.forEach((item, idx) => {
      if (idx == 0) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    })


    setTimeout(() => {
      setButtons(selectedRoutes);
    }, 1000)

    //  if(selectedRoutes.)
    // if(selectedRoutes.length>0 && routeDetailsData.length > 0 && heartBeatsData.length > 0 ){
    //   routeStops = [];
    //   heartBeats = [];
    //   dcLocations = [];
    //   liftLocations = [];
    //   liftIds = [];
    //   routeLoaded = false;
    //   removeAllMarkers();
    //   removeLiftMarkers();
    //   selectedRoueId = selectedRoutes[0].routeId;
    //   selectedRoueName = selectedRoutes[0].routeName;
    //   getSelectRouteDetails(selectedRoueId, selectedRoueName);
    // }

    if (window.google) {
      loadGoogleMap();
    } else {
      const googleMapScript = loadMapApi();
      if (googleMapScript) {
        googleMapScript.addEventListener('load', function () {
          loadGoogleMap();
        });
      }
    }
    // }

    document.addEventListener(Constants.ROUTE_DETAILS_BACK_EVENT, onBackRouteDetails);
    window.addEventListener("resize", onWindowResize);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener(Constants.ROUTE_DETAILS_BACK_EVENT, onBackRouteDetails);
      window.removeEventListener("resize", onWindowResize);
    }

  }, []);
  useEffect(() => {
    if (selectedRoutes.length > 0 && routeDetailsData.length > 0 && heartBeatsData.length > 0) {
      routeStops = [];
      heartBeats = [];
      dcLocations = [];
      liftLocations = [];
      liftIds = [];
      routeLoaded = false;
      removeAllMarkers();
      removeLiftMarkers();
      selectedRoueId = selectedRoutes[0].routeId;
      selectedRoueName = selectedRoutes[0].routeName;
      getSelectRouteDetails(selectedRoueId, selectedRoueName);
    }
  }, [routeDetailsData, heartBeatsData])

  useEffect(() => {
    const customerLocationdata = {
      customerId: selectedCustomerListData.customerId,
      locationId: selectedLocationListData.locationId
    }

  }, [customerAndLocationsData]);

  useEffect(() => {
    if (routeData.length > 0) {
      getRouteDetailsData(routeData);
      getHeartBeatsData(routeData);
    }
  }, [routeData])

  let onWindowResize = () => {
    if (window.innerWidth < 1200) {
      setWindowResize(true);
    } else {
      setWindowResize(false);
    }
  }

  let onBackRouteDetails = () => {
    if (startTimer) {
      clearTimeout(startTimer);
    }
    availRoutes = [];
    mapRefreesh = false;
    startTimer = null;
  }
  // 17.456370657727955, 78.37335993430587
  //40.72494833102668, -74.05955796396846

  var styles = null;
  const setMapStyle = () => {
    styles = {
      default: [],
      minimum: [

        {
          featureType: "poi", elementType: "labels.text",
          stylers: [{ visibility: "off" }]
        },
        {
          featureType: "poi.business",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "road",
          elementType: "labels.icon",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "transit",
          stylers: [{ visibility: "off" }],
        },
      ],
    };
  }
  let createCenterControl = (map) => {
    const controlButton = document.createElement('button');

    // Set CSS for the control.
    controlButton.style.backgroundColor = '#fff';
    controlButton.style.border = '2px solid #fff';
    controlButton.style.borderRadius = '3px';
    controlButton.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    controlButton.style.color = 'rgb(25,25,25)';
    controlButton.style.cursor = 'pointer';
    controlButton.style.fontFamily = 'Roboto,Arial,sans-serif';
    controlButton.style.fontSize = '16px';
    controlButton.style.lineHeight = '38px';
    controlButton.style.margin = '8px 0 22px';
    controlButton.style.padding = '0 5px';
    controlButton.style.textAlign = 'center';

    controlButton.textContent = 'Center Map';
    //controlButton.title = 'Click to recenter the map';
    //controlButton.type = 'button';

    // Setup the click event listeners: simply set the map to Chicago.
    controlButton.addEventListener('click', () => {
      //map.setCenter(chicago);
      console.log("Button")
    });

    return controlButton;
  }
  const loadGoogleMap = () => {
    let defLat = 40.72494833102668;
    let defLng = -74.05955796396846;

    if (selectedLocationListData && selectedLocationListData.locationLatitude && selectedLocationListData.locationLongitude) {
      defLat = Number(selectedLocationListData.locationLatitude);
      defLng = Number(selectedLocationListData.locationLongitude);
    }

    if (mapRef.current) {
      var google = window.google;
      var point = new google.maps.LatLng(defLat, defLng);
      gMap = new google.maps.Map(mapRef.current, {
        zoom: 10, center: point,
        gestureHandling: 'greedy', draggableCursor: 'pointer',
        streetViewControl: true, zoomControl: true, fullscreenControl: true,
        fullscreenControlOptions: { position: google.maps.ControlPosition.TOP_RIGHT },
        mapTypeControlOptions: { position: google.maps.ControlPosition.BOTTOM_RIGHT },

      });

      if (gMap) {
        const centerControlDiv = document.createElement('div');
        // Create the control.
        const centerControl = createCenterControl(gMap);
        // Append the control to the DIV.
        centerControlDiv.appendChild(centerControl);
        setTimeout(() => {
          let control = document.getElementById("legends");
          if (control) {
            control.style.display = "block";
            gMap.controls[google.maps.ControlPosition.TOP_RIGHT].push(control);
          }
          // let control1 = document.getElementById("divControls");
          // if(control1){
          //   control1.style.display = "block";
          //   gMap.controls[google.maps.ControlPosition.TOP_RIGHT].push(control1);
          // }
        }, 1000);


        setMapStyle();
        gMap.setOptions({ styles: styles["minimum"] });
        gMap.addListener("idle", () => {
          startIcon = {
            url: "../../assets/img/StartPoint.png", // url
            scaledSize: new google.maps.Size(40, 40), // scaled size
            origin: new google.maps.Point(0, 0), // origin
            anchor: new google.maps.Point(0, 30) // anchor
          };
          currlocicon = {
            url: "../../assets/img/CurrLocation.png", // url
            scaledSize: new google.maps.Size(40, 40), // scaled size
            origin: new google.maps.Point(0, 0), // origin
            anchor: new google.maps.Point(20, 20) // anchor
          };

          endMicon = {
            url: "../../assets/img/MIcon.png", // url
            scaledSize: new google.maps.Size(40, 40), // scaled size
            origin: new google.maps.Point(0, 0), // origin
            anchor: new google.maps.Point(20, 20) // anchor
          };
        });

      }
    }
  }


  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
  };
  //fill: #09A782;
  let showStopActiveIcon = (cnt, stopStatus) => {//"#09A782"

    var google = window.google;
    var svgIcon = {};
    //var template = [
    if (stopStatus === "Pending" || stopStatus === "In progress") {
      var strSVG = '<?xml version="1.0"?>' +
        '<svg width="30" height="37" viewBox="0 0 30 37" fill="none" xmlns="http://www.w3.org/2000/svg">' +
        '<g filter="url(#filter0_d_2164_5905)">' +
        '<mask id="path-1-inside-1_3240_6403" fill="white">' +
        '<path fill-rule="evenodd" clip-rule="evenodd" d="M19.1655 24.1839C23.1753 22.5421 26 18.6009 26 14C26 7.92487 21.0751 3 15 3C8.92487 3 4 7.92487 4 14C4 18.6009 6.82471 22.5421 10.8345 24.1839L15 32L19.1655 24.1839Z"/>' +
        '</mask>' +
        '<path fill-rule="evenodd" clip-rule="evenodd" d="M19.1655 24.1839C23.1753 22.5421 26 18.6009 26 14C26 7.92487 21.0751 3 15 3C8.92487 3 4 7.92487 4 14C4 18.6009 6.82471 22.5421 10.8345 24.1839L15 32L19.1655 24.1839Z" fill="#2776cc"/>' +

        '<path d="M19.1655 24.1839L18.7866 23.2585L18.4527 23.3952L18.283 23.7136L19.1655 24.1839ZM10.8345 24.1839L11.717 23.7136L11.5473 23.3952L11.2134 23.2585L10.8345 24.1839ZM15 32L14.1175 32.4703L15 34.1262L15.8825 32.4703L15 32ZM25 14C25 18.1811 22.4337 21.7652 18.7866 23.2585L19.5444 25.1094C23.9169 23.319 27 19.0208 27 14H25ZM15 4C20.5228 4 25 8.47715 25 14H27C27 7.37258 21.6274 2 15 2V4ZM5 14C5 8.47715 9.47715 4 15 4V2C8.37258 2 3 7.37258 3 14H5ZM11.2134 23.2585C7.56627 21.7652 5 18.1811 5 14H3C3 19.0208 6.08314 23.319 10.4556 25.1094L11.2134 23.2585ZM15.8825 31.5297L11.717 23.7136L9.95202 24.6542L14.1175 32.4703L15.8825 31.5297ZM18.283 23.7136L14.1175 31.5297L15.8825 32.4703L20.048 24.6542L18.283 23.7136Z" fill="white" mask="url(#path-1-inside-1_3240_6403)"/>' +
        '</g>' +
        '<defs>' +
        '<filter id="filter0_d_3240_6403" x="0" y="0" width="30" height="37" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">' +
        '<feFlood flood-opacity="0" result="BackgroundImageFix"/>' +
        '<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>' +
        '<feOffset dy="1"/>' +
        '<feGaussianBlur stdDeviation="2"/>' +
        '<feComposite in2="hardAlpha" operator="out"/>' +
        '<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>' +
        '<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3240_6403"/>' +
        '<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3240_6403" result="shape"/></filter>' +
        '</defs>';
    } else {
      var strSVG = '<?xml version="1.0"?>' +
        '<svg width="30" height="37" viewBox="0 0 30 37" fill="none" xmlns="http://www.w3.org/2000/svg">' +
        '<g filter="url(#filter0_d_2164_5905)">' +
        '<mask id="path-1-inside-1_3240_6403" fill="white">' +
        '<path fill-rule="evenodd" clip-rule="evenodd" d="M19.1655 24.1839C23.1753 22.5421 26 18.6009 26 14C26 7.92487 21.0751 3 15 3C8.92487 3 4 7.92487 4 14C4 18.6009 6.82471 22.5421 10.8345 24.1839L15 32L19.1655 24.1839Z"/>' +
        '</mask>' +
        '<path fill-rule="evenodd" clip-rule="evenodd" d="M19.1655 24.1839C23.1753 22.5421 26 18.6009 26 14C26 7.92487 21.0751 3 15 3C8.92487 3 4 7.92487 4 14C4 18.6009 6.82471 22.5421 10.8345 24.1839L15 32L19.1655 24.1839Z" fill="#4DC1E1"/>' +

        '<path d="M19.1655 24.1839L18.7866 23.2585L18.4527 23.3952L18.283 23.7136L19.1655 24.1839ZM10.8345 24.1839L11.717 23.7136L11.5473 23.3952L11.2134 23.2585L10.8345 24.1839ZM15 32L14.1175 32.4703L15 34.1262L15.8825 32.4703L15 32ZM25 14C25 18.1811 22.4337 21.7652 18.7866 23.2585L19.5444 25.1094C23.9169 23.319 27 19.0208 27 14H25ZM15 4C20.5228 4 25 8.47715 25 14H27C27 7.37258 21.6274 2 15 2V4ZM5 14C5 8.47715 9.47715 4 15 4V2C8.37258 2 3 7.37258 3 14H5ZM11.2134 23.2585C7.56627 21.7652 5 18.1811 5 14H3C3 19.0208 6.08314 23.319 10.4556 25.1094L11.2134 23.2585ZM15.8825 31.5297L11.717 23.7136L9.95202 24.6542L14.1175 32.4703L15.8825 31.5297ZM18.283 23.7136L14.1175 31.5297L15.8825 32.4703L20.048 24.6542L18.283 23.7136Z" fill="white" mask="url(#path-1-inside-1_3240_6403)"/>' +
        '</g>' +
        '<defs>' +
        '<filter id="filter0_d_3240_6403" x="0" y="0" width="30" height="37" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">' +
        '<feFlood flood-opacity="0" result="BackgroundImageFix"/>' +
        '<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>' +
        '<feOffset dy="1"/>' +
        '<feGaussianBlur stdDeviation="2"/>' +
        '<feComposite in2="hardAlpha" operator="out"/>' +
        '<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>' +
        '<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3240_6403"/>' +
        '<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3240_6403" result="shape"/></filter>' +
        '</defs>';
    }


    if (cnt && cnt.toString().length == 1) {
      strSVG = strSVG + '<text x="12" y="18" fill="white" font-weight="normal" font-size="10">' + (cnt ? cnt : "") + '</text></svg>';
    } else {
      strSVG = strSVG + '<text x="9" y="18" fill="white" font-weight="normal" font-size="10">' + (cnt ? cnt : "") + '</text></svg>';
    }
    svgIcon.url = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(strSVG);
    svgIcon.scaledSize = new google.maps.Size(40, 40);
    svgIcon.origin = new google.maps.Point(0, 0);
    svgIcon.anchor = new google.maps.Point(10, 10);
    return svgIcon;
  }

  let showStopInActiveIcon = (cnt) => {//"#09A782"
    var google = window.google;
    var svgIcon = {};
    var strSVG = '<?xml version="1.0"?>' +
      '<svg width="30" height="37" viewBox="0 0 30 37" fill="none" xmlns="http://www.w3.org/2000/svg">' +
      '<g filter="url(#filter0_d_2164_5905)">' +
      '<mask id="path-1-inside-1_3240_6403" fill="white">' +
      '<path fill-rule="evenodd" clip-rule="evenodd" d="M19.1655 24.1839C23.1753 22.5421 26 18.6009 26 14C26 7.92487 21.0751 3 15 3C8.92487 3 4 7.92487 4 14C4 18.6009 6.82471 22.5421 10.8345 24.1839L15 32L19.1655 24.1839Z"/>' +
      '</mask>' +
      '<path fill-rule="evenodd" clip-rule="evenodd" d="M19.1655 24.1839C23.1753 22.5421 26 18.6009 26 14C26 7.92487 21.0751 3 15 3C8.92487 3 4 7.92487 4 14C4 18.6009 6.82471 22.5421 10.8345 24.1839L15 32L19.1655 24.1839Z" fill="#808080"/>' +

      '<path d="M19.1655 24.1839L18.7866 23.2585L18.4527 23.3952L18.283 23.7136L19.1655 24.1839ZM10.8345 24.1839L11.717 23.7136L11.5473 23.3952L11.2134 23.2585L10.8345 24.1839ZM15 32L14.1175 32.4703L15 34.1262L15.8825 32.4703L15 32ZM25 14C25 18.1811 22.4337 21.7652 18.7866 23.2585L19.5444 25.1094C23.9169 23.319 27 19.0208 27 14H25ZM15 4C20.5228 4 25 8.47715 25 14H27C27 7.37258 21.6274 2 15 2V4ZM5 14C5 8.47715 9.47715 4 15 4V2C8.37258 2 3 7.37258 3 14H5ZM11.2134 23.2585C7.56627 21.7652 5 18.1811 5 14H3C3 19.0208 6.08314 23.319 10.4556 25.1094L11.2134 23.2585ZM15.8825 31.5297L11.717 23.7136L9.95202 24.6542L14.1175 32.4703L15.8825 31.5297ZM18.283 23.7136L14.1175 31.5297L15.8825 32.4703L20.048 24.6542L18.283 23.7136Z" fill="white" mask="url(#path-1-inside-1_3240_6403)"/>' +
      '</g>' +
      '<defs>' +
      '<filter id="filter0_d_3240_6403" x="0" y="0" width="30" height="37" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">' +
      '<feFlood flood-opacity="0" result="BackgroundImageFix"/>' +
      '<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>' +
      '<feOffset dy="1"/>' +
      '<feGaussianBlur stdDeviation="2"/>' +
      '<feComposite in2="hardAlpha" operator="out"/>' +
      '<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>' +
      '<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3240_6403"/>' +
      '<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3240_6403" result="shape"/></filter>' +
      '</defs>';


    if (cnt && cnt.toString().length == 1) {
      strSVG = strSVG + '<text x="12" y="18" fill="white" font-weight="normal" font-size="10">' + (cnt ? cnt : "") + '</text></svg>';
    } else {
      strSVG = strSVG + '<text x="9" y="18" fill="white" font-weight="normal" font-size="10">' + (cnt ? cnt : "") + '</text></svg>';
    }

    svgIcon.url = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(strSVG);
    svgIcon.scaledSize = new google.maps.Size(40, 40);
    svgIcon.origin = new google.maps.Point(0, 0);
    svgIcon.anchor = new google.maps.Point(10, 10);
    return svgIcon;
  }

  let showStopActiveEditPinIcon = (cnt) => {//"#09A782"


    var google = window.google;
    var svgIcon = {};

    var strSVG = '<?xml version="1.0"?>' +
      '<svg width="30" height="37" viewBox="0 0 30 37" fill="none" xmlns="http://www.w3.org/2000/svg">' +
      '<g filter="url(#filter0_d_2164_5905)">' +
      '<mask id="path-1-inside-1_3240_6403" fill="white">' +
      '<path fill-rule="evenodd" clip-rule="evenodd" d="M19.1655 24.1839C23.1753 22.5421 26 18.6009 26 14C26 7.92487 21.0751 3 15 3C8.92487 3 4 7.92487 4 14C4 18.6009 6.82471 22.5421 10.8345 24.1839L15 32L19.1655 24.1839Z"/>' +
      '</mask>' +
      '<path fill-rule="evenodd" clip-rule="evenodd" d="M19.1655 24.1839C23.1753 22.5421 26 18.6009 26 14C26 7.92487 21.0751 3 15 3C8.92487 3 4 7.92487 4 14C4 18.6009 6.82471 22.5421 10.8345 24.1839L15 32L19.1655 24.1839Z" fill="red"/>' +

      '<path d="M19.1655 24.1839L18.7866 23.2585L18.4527 23.3952L18.283 23.7136L19.1655 24.1839ZM10.8345 24.1839L11.717 23.7136L11.5473 23.3952L11.2134 23.2585L10.8345 24.1839ZM15 32L14.1175 32.4703L15 34.1262L15.8825 32.4703L15 32ZM25 14C25 18.1811 22.4337 21.7652 18.7866 23.2585L19.5444 25.1094C23.9169 23.319 27 19.0208 27 14H25ZM15 4C20.5228 4 25 8.47715 25 14H27C27 7.37258 21.6274 2 15 2V4ZM5 14C5 8.47715 9.47715 4 15 4V2C8.37258 2 3 7.37258 3 14H5ZM11.2134 23.2585C7.56627 21.7652 5 18.1811 5 14H3C3 19.0208 6.08314 23.319 10.4556 25.1094L11.2134 23.2585ZM15.8825 31.5297L11.717 23.7136L9.95202 24.6542L14.1175 32.4703L15.8825 31.5297ZM18.283 23.7136L14.1175 31.5297L15.8825 32.4703L20.048 24.6542L18.283 23.7136Z" fill="white" mask="url(#path-1-inside-1_3240_6403)"/>' +
      '</g>' +
      '<defs>' +
      '<filter id="filter0_d_3240_6403" x="0" y="0" width="30" height="37" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">' +
      '<feFlood flood-opacity="0" result="BackgroundImageFix"/>' +
      '<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>' +
      '<feOffset dy="1"/>' +
      '<feGaussianBlur stdDeviation="2"/>' +
      '<feComposite in2="hardAlpha" operator="out"/>' +
      '<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>' +
      '<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3240_6403"/>' +
      '<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3240_6403" result="shape"/></filter>' +
      '</defs>';




    if (cnt && cnt.toString().length == 1) {
      strSVG = strSVG + '<text x="12" y="18" fill="white" font-weight="normal" font-size="10">' + (cnt ? cnt : "") + '</text></svg>';
    } else {
      strSVG = strSVG + '<text x="9" y="18" fill="white" font-weight="normal" font-size="10">' + (cnt ? cnt : "") + '</text></svg>';
    }

    svgIcon.url = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(strSVG);
    svgIcon.scaledSize = new google.maps.Size(40, 40);
    svgIcon.origin = new google.maps.Point(0, 0);
    svgIcon.anchor = new google.maps.Point(10, 10);
    return svgIcon;
  }



  let heartBeatIcon = () => {
    var google = window.google;
    var svgIcon = {};
    var template = [
      '<?xml version="1.0"?>',
      '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="63" height="70">' +
      '<defs><filter id="a" width="100%" height="100%" x="10" y="10" filterUnits="objectBoundingBox">' +
      '<feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3.5"/>' +
      '<feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.215686275 0 0 0 0 0.223529412 0 0 0 0 0.207843137 0 0 0 0.3 0"/></filter>' +
      '</defs>' +
      '<g fill="none" fill-rule="evenodd" transform="translate(6.5 5.5)"><ellipse cx="25" cy="59.066" fill="#000" opacity="0" rx="10.264" ry="5.434"/>' +
      '<g fill-rule="nonzero"><use fill="#000" filter="url(#a)" xlink:href="#b"/><use fill="#FFF" xlink:href="#b"/></g>' +
      '<g transform="translate(5.225 4.08)"><circle cx="19.924" cy="20.835" r="20.327" fill="#2776cc" fill-rule="nonzero" stroke="#FFF" stroke-width="2"/>' +
      '<text x="15" y="25" fill="white" font-weight="normal"></text></g></g>' +
      '</svg>'
    ].join('\n');



    svgIcon.url = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(template);
    svgIcon.scaledSize = new google.maps.Size(10, 10);
    svgIcon.origin = new google.maps.Point(0, 0);
    svgIcon.anchor = new google.maps.Point(10, 10);

    //origin: new google.maps.Point(0,0), // origin
    // anchor: new google.maps.Point(20, 20)
    return svgIcon;
  }

  let heartBeatInActiveIcon = () => {
    var google = window.google;
    var svgIcon = {};
    var template = [
      '<?xml version="1.0"?>',
      '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="63" height="70">' +
      '<defs><filter id="a" width="100%" height="100%" x="10" y="10" filterUnits="objectBoundingBox">' +
      '<feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3.5"/>' +
      '<feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.215686275 0 0 0 0 0.223529412 0 0 0 0 0.207843137 0 0 0 0.3 0"/></filter>' +
      '</defs>' +
      '<g fill="none" fill-rule="evenodd" transform="translate(6.5 5.5)"><ellipse cx="25" cy="59.066" fill="#000" opacity="0" rx="10.264" ry="5.434"/>' +
      '<g fill-rule="nonzero"><use fill="#000" filter="url(#a)" xlink:href="#b"/><use fill="#FFF" xlink:href="#b"/></g>' +
      '<g transform="translate(5.225 4.08)"><circle cx="19.924" cy="20.835" r="20.327" fill="#808080" fill-rule="nonzero" stroke="#FFF" stroke-width="2"/>' +
      '<text x="15" y="25" fill="white" font-weight="normal"></text></g></g>' +
      '</svg>'
    ].join('\n');



    svgIcon.url = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(template);
    svgIcon.scaledSize = new google.maps.Size(10, 10);
    svgIcon.origin = new google.maps.Point(0, 0);
    svgIcon.anchor = new google.maps.Point(10, 10);

    //origin: new google.maps.Point(0,0), // origin
    // anchor: new google.maps.Point(20, 20)
    return svgIcon;
  }

  let onRouteSelect = (routeName, evt) => {
    // evt.stopPropagation();
    stopAutoPolling();
    selectedRoueId = "";
    selectedRoueName = "";
    var tempRoutes = navButtons;
    tempRoutes.map((item, idx) => {
      item.selected = false;
      if (routeName == item.routeName) {
        item.selected = true;
        selectedRoueId = item.routeId;
        selectedRoueName = item.routeName;
      }
    });
    if (selectedRoueId) {
      getSelectRouteDetails(selectedRoueId, selectedRoueName);
    }
    setButtons(tempRoutes);
  }

  let onRouteDelete = (routeName, evt) => {
    try {
      evt.stopPropagation();
      stopAutoPolling();
      selectedRoueId = "";
      selectedRoueName = "";
      let flag = false;
      var tempRoutes = [];
      availRoutes = [];
      //setButtons([]);
      //setPage(Math.random()*1000000000);
      selectedRoutes = navButtons;
      selectedRoutes.map((item, idx) => {
        if (routeName == item.routeName) {
          if (item.selected) {
            selectedRoueId = item.routeId;
            selectedRoueName = item.routeName;
            flag = true;
            if (idx == tempRoutes.length && tempRoutes && tempRoutes.length > 0) {
              tempRoutes[0].selected = true;
              selectedRoueId = tempRoutes[0].routeId;
              selectedRoueName =  tempRoutes[0].routeName;
              flag = false;
            }
          }
        } else {
          if (item.selected) {
            selectedRoueId = item.routeId;
            selectedRoueName = item.routeName;
          }
          if (flag) {
            item.selected = true;
            selectedRoueId = item.routeId;
            selectedRoueName = item.routeName;
          }
          flag = false;
          tempRoutes.push(item);
          availRoutes.push(item);
        }
      });
      // availRoutes = tempRoutes;
      selectedRoutes = tempRoutes;

      setButtons(selectedRoutes);
      if (selectedRoueId) {
        getSelectRouteDetails(selectedRoueId, selectedRoueName)
      }
    } catch (ex) {
      console.log(ex.message);
    }

  }


  const handleClickOpen = () => {
    // setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let onClickLiftName = (name) => {
    if (name) {
      const event = new CustomEvent(Constants.LIFT_MAINTENANCE_EVENT, { detail: {} });
      document.dispatchEvent(event);
      navigate('/liftMaintenance', { state: { liftId: name } });
    }
  }

  function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

  return (
    <div id={"divRoute"}>
      <CustomSuccessMessage message={message} type={errorType} flag={isShowMessage} onHide={() => { setShowMessage(false) }} />
      <Box sx={{ marginTop: "40px" }}><Box>
        <div id={"legends"} style={{ marginTop: "10px", display: "none" }}>
          <List sx={{ bgcolor: 'background.paper', padding: "0px" }}>
            <ListItem sx={{ padding: "0px" }}>
              <ListItemButton>
                <ListItemIcon>
                  <img src={CompletedPin} />
                </ListItemIcon>
                <ListItemText primary="Completed" />
              </ListItemButton>
            </ListItem>
            <ListItem sx={{ padding: "0px" }}>
              <ListItemButton>
                <ListItemIcon>
                  <img src={PendingPin} />
                </ListItemIcon>
                <ListItemText primary="Pending" />
              </ListItemButton>
            </ListItem>
            <ListItem sx={{ padding: "0px" }}>
              <ListItemButton>
                <ListItemIcon>
                  <img src={InactivePin} />
                </ListItemIcon>
                <ListItemText primary="Unselected route" />
              </ListItemButton>
            </ListItem>
          </List>
        </div>
        <div id={"divControls"} style={{ top: "1px", display: "none" }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between"
            sx={{ px: 0, py: 1, bgcolor: 'background.default', marginTop: "5px" }} >
            <Box className="k-title text-primary" sx={{ fontSize: "20px" }}></Box>
            <Box>
              &nbsp;&nbsp;&nbsp;
              <FormControlLabel control={<Checkbox defaultChecked onChange={() => {
                (chkDisable ? setChkDisable(false) : setChkDisable(true))
                // startTimer();
              }} />} style={{ marginTop: "-20px" }}
                label="Auto Refresh" labelPlacement={"end"}
              />
              &nbsp;
              <TextField inputProps={{ type: 'number', min: "1", max: "10", style: { height: "20px", } }} label="Minutes" value={minutes} focused size={"small"}
                style={{ width: "100px", height: "20px" }} onChange={(event) => {
                  if (event.currentTarget && event.currentTarget.value) {
                    setMinutes(event.currentTarget.value);
                  } else {
                    setMinutes(2);
                  }
                }} disabled={chkDisable} />
              <a href="#"> <img src={bellIcon} onClick={handleClickOpen} style={{ visibility: "hidden" }} /> </a>
            </Box>
          </Stack>
        </div>
        <div style={{ marginTop: "1px" }}>
          <div className="responsive-map" style={{ height: "60vh", overflow: "none" }}>
            <div className="map-container">
              <div ref={mapRef} className="map-container__map" style={{ height: "60vh" }}>
              </div>
            </div>
          </div>
        </div>
        <Box sx={{ marginTop: "20px", }}>
          {
            navButtons.length > 1 && navButtons.map((item, idx) => {
              var list = null;
              if (item && item.selected) {
                list = (
                  <Button variant="contained" endIcon={<DisabledByDefaultIcon onClick={(event) => { onRouteDelete(item.routeName, event) }} />}
                    sx={{
                      backgroundColor: "#2776CC", height: "50px", marginRight: "10px", fontWeight: "500", ':hover': {
                        bgcolor: '#2776CC', color: 'white'
                      }
                    }} onClick={(event) => { onRouteSelect(item.routeName, event) }}>
                    {item.routeName} &nbsp;&nbsp;
                  </Button>
                )
              } else {
                list = (
                  <Button variant="contained" endIcon={<DisabledByDefaultIcon sx={{ color: "grey" }} onClick={(event) => { onRouteDelete(item.routeName, event) }} />}
                    sx={{
                      backgroundColor: "#FFFFFF", color: "#000", height: "50px", marginRight: "10px", fontWeight: "500", ':hover': {
                        bgcolor: '#FFFFFF', color: '#000'
                      }
                    }} onClick={(event) => { onRouteSelect(item.routeName, event) }}>
                    {item.routeName}
                  </Button>
                )
              }
              return list
            })}
        </Box>
        <Box>
        </Box>
        <Box sx={{ marginTop: "20px", }}>
          <Grid container spacing={2}>
            <Grid container spacing={2} xs={12} lg={10} xl={10}>
              <Grid xs={12} lg={4} xl={4}>
                <KPICard >
                  <Box sx={{ mb: 1 }}>
                    <Box className="d-flex justify-content-between align-items-center kpi-title w-100" >
                      <Box className="k-title text-primary">Route Details</Box>
                      <Box className="k-icon"><img src={Route} /></Box>
                    </Box>
                  </Box>
                  {loadingFinalRouteDetails ? <CircleProgressBar /> : (<Box>
                    <Grid container spacing={1}>
                      <Grid xs={6}>
                        <ListItem className="grid-head">Route Name</ListItem>
                        <ListItem className="grid-value">{routData?.routeName || ''}</ListItem>
                      </Grid>
                      <Grid xs={6}>
                        <ListItem className="grid-head">Driver Name</ListItem>
                        <ListItem className="grid-value">{routData?.driverName || '-'}</ListItem>
                      </Grid>
                      <Grid xs={6}>
                        <ListItem className="grid-head">Departure Time </ListItem>
                        <ListItem className="grid-value">{routData?.startTime ? moment(routData?.startTime).format('hh:mm A') : "-"}</ListItem>
                      </Grid>
                      <Grid xs={6}>
                        <ListItem className="grid-head">Return Time</ListItem>
                        <ListItem className="grid-value">{routData?.endTime ? moment(routData?.endTime).format('hh:mm A') : "-"}</ListItem>
                      </Grid>
                    </Grid>
                  </Box>)}
                </KPICard>
              </Grid>
              <Grid xs={12} lg={4} xl={4}>
                <KPICard >
                  <Box sx={{ mb: 1 }}>
                    <Box className="d-flex justify-content-between align-items-center kpi-title w-100" >
                      <Box className="k-title text-primary">Case Details</Box>
                      <Box className="k-icon"><img src={caseDetailsIcon} /></Box>
                    </Box>
                  </Box>
                  {loadingFinalRouteDetails ? <CircleProgressBar /> : (
                    <Box>
                      <Grid container spacing={1}>
                        <Grid xs={6}>
                          <ListItem className="grid-head">How many Cases</ListItem>
                          <ListItem className="grid-value">{routData?.actualCaseCount || '0'}</ListItem>
                        </Grid>
                        <Grid xs={6}>
                          <ListItem className="grid-head">No. of Pallets</ListItem>
                          <ListItem className="grid-value">{routData?.actualPalletCount || '0'}</ListItem>
                        </Grid>
                        <Grid xs={6}>
                          <ListItem className="grid-head">Current CPH for Route</ListItem>
                          <ListItem className="grid-value">{routData?.cph || '0'}</ListItem>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </KPICard>
              </Grid>
              <Grid xs={12} lg={4} xl={4}>
                <KPICard >
                  <Box sx={{ mb: 1 }}>
                    <Box className="d-flex justify-content-between align-items-center kpi-title w-100" >
                      <Box className="k-title text-primary">Route Distance</Box>
                      <Box className="k-icon"><img src={routeDistanceIcon} /></Box>
                    </Box>
                  </Box>
                  {loadingFinalRouteDetails ? <CircleProgressBar /> : (
                    <Box>
                      <Grid container spacing={1}>
                        <Grid xs={6}>
                          <ListItem className="grid-head">Total Service Time</ListItem>
                          <ListItem className="grid-value">{(routData?.totalServiceTime || '0') + " Hrs" || '0'}</ListItem>
                        </Grid>
                        <Grid xs={6}>
                          <ListItem className="grid-head">Total Drive Time</ListItem>
                          <ListItem className="grid-value">{((routData?.totalDriveTime ? parseFloat(routData?.totalDriveTime).toFixed(1) : '0') || '0') + " Hrs" || '0'}</ListItem>
                        </Grid>
                        <Grid xs={6}>
                          <ListItem className="grid-head">Total Route Time</ListItem>
                          <ListItem className="grid-value">{(((routData?.totalRouteTime ? parseFloat(routData?.totalRouteTime).toFixed(1) : '0') || '0') + " Hrs") || '0'}</ListItem>
                        </Grid>
                        <Grid xs={6}>
                          <ListItem className="grid-head">Total Route dist</ListItem>
                          <ListItem className="grid-value">{((routData?.totalDistance || '0') + " Miles") || '0'}</ListItem>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </KPICard>
              </Grid>
            </Grid>
            <Grid xs={windowResize ? 12 : 4} lg={windowResize ? 12 : 2} xl={windowResize ? 12 : 2}>
              <Grid xs={windowResize ? 12 : 6} lg={windowResize ? 12 : 6} xl={windowResize ? 12 : 6}>
                <KPICard >
                  <Box sx={{ mb: 1 }}>
                    <Box className="d-flex justify-content-between align-items-center kpi-title w-100" >
                      <Box className="k-title text-primary">Assigned Lift Health</Box>
                      <Box className="k-icon"><img src={liftHealthIcon} /></Box>
                    </Box>
                  </Box>
                  {loadingFinalRouteDetails ? <CircleProgressBar /> : (
                    <Box>
                      <Grid container spacing={1}>
                        <Grid xs={12} lg={12} xl={6}>
                          <ListItem className="grid-head">Battery Life</ListItem>
                          <ListItem className="grid-value">{routData?.liftBattery || '0'}%</ListItem>
                        </Grid>
                        <Grid xs={12} lg={12} xl={6}>
                          <ListItem className="grid-head">Lift Name</ListItem>
                          {routData?.assignedLift ? (
                            <ListItem className="grid-value">
                              <a style={{ cursor: "pointer", color: "#0000FF" }} onClick={() => { onClickLiftName(routData.assignedLift) }}>
                                {routData.assignedLift}
                              </a>
                            </ListItem>
                          ) : (
                            <ListItem className="grid-value"> - </ListItem>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </KPICard>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ marginTop: "30px" }}>
          <Grid container>
            <Grid xs={12} lg={12} xl={12}>
              <DataTable dataRows={dataRows} loading={loadingFinalRouteDetails} selectedCustomerListData={selectedCustomerListData} selectedLocationListData={selectedLocationListData} />
            </Grid>
          </Grid>
        </Box>
      </Box>
      </Box>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    selectedLocationListData: state?.Routes?.selectedLocationListData,
    selectedCustomerListData: state?.Routes?.selectedCustomerListData,
    userRoleName: state?.Routes?.userRoleName,
    updatePinStatus: state?.Routes?.updatePinStatus,
    loadingFirstRouteDetails: state?.Routes?.loadingFirstRouteDetails,
    loadingFinalRouteDetails: state?.Routes?.loadingFinalRouteDetails,
    routeDetailsData: state?.Routes?.routeDetailsData,
    loadingHeartBeats: state?.Routes?.loadingHeartBeats,
    heartBeatsData: state?.Routes?.heartBeatsData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerAndLocations: () => dispatch(getCustomerAndLocations()),
    updatePinLocation: (data) => dispatch(updatePinLocation(data)),
    getRouteDetailsData: (routeId) => dispatch(getRouteDetailsData(routeId)),
    getHeartBeatsData: (routeId) => dispatch(getHeartBeatsData(routeId))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RouteDetails);